import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { ApiConnectorStationsService } from '../../core/io/stations/api-connector-stations.service';
import { Router } from '@angular/router';
import { NavigationService } from '../../core/navigation/navigation.service';
import { fetchDreamElevenPersons } from '../../core/store/content/content.actions';
import { StationBaseComponent } from '../base/station-base/station-base.component';
import { TranslateService } from '@ngx-translate/core';
import { DreamElevenPositions } from './positions';
import { DreamElevenFormations } from './formation';
import { environment } from '../../../environments/environment';
import { ApiConnectorBackendSaveService } from '../../core/io/content/api-connector-backend-save.service';
import { Content } from 'src/app/core/store/content/content.reducer';
import { GetContent } from 'src/app/core/store/content/content.selector';

export enum DreamElevenState {
  INIT,
  SELECT_FORMATION,
  CHOOSE_PLAYERS,
  SET_NAME,
  FINISH,
  SET_EMAIL,
  EMAIL_SUCCESS,
  EMAIL_ERROR,
  EMAIL_LOADING,
}

@Component({
  selector: 'app-dream-eleven',
  templateUrl: './dream-eleven.component.html',
  styleUrls: ['./dream-eleven.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DreamElevenComponent
  extends StationBaseComponent
  implements OnInit
{
  public dreamElevenStates = DreamElevenState;
  public status: DreamElevenState = DreamElevenState.SELECT_FORMATION;
  public formations = DreamElevenFormations;
  public formation = this.formations[0];
  public currentPositionEdit;
  public setPositions;
  public userName = '';
  public email;
  public canvasImage;
  public errorMsg;

  public coach = {
    position: DreamElevenPositions.coach,
    x: 50,
    y: 95,
    id: 11,
  };

  public selectedPlayers = {};
  stationStatus;

  constructor(
    protected _store: Store,
    protected _translateService: TranslateService,
    private _apiConnectorStations: ApiConnectorStationsService,
    private _router: Router,
    private _navigationService: NavigationService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _apiBackendSaveService: ApiConnectorBackendSaveService
  ) {
    super(_store, _translateService);
  }
  ngOnInit(): void {
    console.log('init wunschelf');

    this.formations.map((formation) => {
      formation.positions.push(this.coach);
    });

    this._store.dispatch(fetchDreamElevenPersons());

    this.AddSubscription(
      this._store.select(GetContent).subscribe((content: Content) => {
        if (this.status === DreamElevenState.FINISH) {
          this.DrawField();
        }
        this.content = content;
      })
    );
  }

  public ProgressState(toState) {
    this.status = toState;

    switch (this.status) {
      case DreamElevenState.CHOOSE_PLAYERS:
        break;
      case DreamElevenState.SET_NAME:
        break;
      case DreamElevenState.FINISH:
        this.DrawField();
        break;
      default:
        break;
    }
  }

  public SetFormation(formation) {
    this.formation = formation;
    this.ProgressState(DreamElevenState.CHOOSE_PLAYERS);
  }

  public ChooseForPosition(position) {
    this.currentPositionEdit = position;
  }

  public BackToFormationChooser() {
    this.userName = '';
    this.currentPositionEdit = undefined;
    this.selectedPlayers = {};
    this.setPositions = 0;
    this.ProgressState(DreamElevenState.SELECT_FORMATION);
  }

  public ToPositionChooser() {
    this.ProgressState(DreamElevenState.CHOOSE_PLAYERS);
  }

  public TypeEmail() {
    this.ProgressState(DreamElevenState.SET_EMAIL);
  }

  public Close(open) {
    if (!open) {
      this.currentPositionEdit = undefined;
      this._changeDetectorRef.markForCheck();
    }
  }

  public PlayerSelect(player) {
    this.selectedPlayers[this.currentPositionEdit.id] = player;
    this.setPositions = Object.keys(this.selectedPlayers).length;
  }

  public SetPlayers() {
    if (this.setPositions !== 12 && environment.deployment !== 'development')
      return;
    this.ProgressState(DreamElevenState.SET_NAME);
  }

  public OnCloseModalName() {
    this.ProgressState(DreamElevenState.CHOOSE_PLAYERS);
  }

  public SetName(name) {
    this.userName = name;
    this.ProgressState(DreamElevenState.FINISH);
  }

  public OnCloseModalEmail() {
    this.ProgressState(DreamElevenState.FINISH);
  }

  public SetEmail(email) {
    this.email = email;
    this.ProgressState(DreamElevenState.EMAIL_LOADING);

    this._apiBackendSaveService
      .MailDreamElevenImage(
        {
          image: this.canvasImage,
          name: this.userName,
          email: this.email,
        },
        this.content.language
      )
      .subscribe(
        (result) => {
          let positions = {};

          for (let key in this.selectedPlayers) {
            let value = this.selectedPlayers[key];

            let position = this.formation.positions.find((position) => {
              return position.id == parseInt(key);
            });

            if (!positions[position.position]) {
              positions[position.position] = [parseInt(value.id)];
            } else {
              positions[position.position].push(parseInt(value.id));
            }
          }

          this._apiBackendSaveService.SavePlayerCount(positions).subscribe(
            (result) => {
              this.ProgressState(DreamElevenState.EMAIL_SUCCESS);
              this._changeDetectorRef.markForCheck();
            },
            (error) => {
              this.errorMsg = error.statusText;
              this.ProgressState(DreamElevenState.EMAIL_ERROR);
              this._changeDetectorRef.markForCheck();
            }
          );
        },
        (error) => {
          this.errorMsg = error.statusText;
          this.ProgressState(DreamElevenState.EMAIL_ERROR);
          this._changeDetectorRef.markForCheck();
        }
      );
  }

  private DrawField() {
    const width = 2060;
    const height = 1243;

    const playerWidth = 291;
    const playerHeight = 126;

    const c = document.getElementById('canvas') as HTMLCanvasElement;
    const ctx = c.getContext('2d');
    ctx.canvas.width = 2060;
    ctx.canvas.height = 1243;
    const img = document.getElementById('field_print') as HTMLImageElement;
    ctx.drawImage(img, 0, 0);

    const playerImg = document.getElementById(
      'field_player_print'
    ) as HTMLImageElement;

    const coachImg = document.getElementById(
      'field_coach_print'
    ) as HTMLImageElement;

    const headerBar = document.getElementById('headerBar');

    const positions = this.formation.positions;
    for (let key in this.selectedPlayers) {
      let value = this.selectedPlayers[key];
      let position = positions.find((position) => {
        return position.id == parseInt(key);
      });

      position = position ? position : this.coach;

      const x = (position.x / 100) * width - playerWidth / 2;
      const y = (position.y / 100) * height - playerHeight / 2;
      ctx.drawImage(
        position.position === DreamElevenPositions.coach ? coachImg : playerImg,
        x,
        y
      );

      ctx.font = '36px DIN';
      ctx.textAlign = 'center';
      ctx.fillText(
        value.last_name
          .replace(/&amp;/g, '&')
          .replace(/&lt;/g, '<')
          .replace(/&gt;/g, '>')
          .replace(/&quot;/g, '"')
          .replace(/&#039;/g, "'"),
        (position.x / 100) * width,
        (position.y / 100) * height + 10
      );
    }

    const headerName = document.getElementById(
      'header_name'
    ) as HTMLImageElement;

    const headerBorusseum = document.getElementById(
      'header_borusseum'
    ) as HTMLImageElement;

    ctx.textAlign = 'left';

    // move header image up/down and right/left depending on name of image
    const nameLength = this.userName.length;

    if (this.content.language === 'en') {
      ctx.drawImage(
        headerName,
        -1200 + nameLength * 39 < 0 ? -1200 + nameLength * 39 : 0,
        45 - nameLength * 0.6,
        1927,
        185
      );
    } else {
      ctx.drawImage(
        headerName,
        -1200 + nameLength * 39 < 0 ? -1200 + nameLength * 39 : 0,
        45 - nameLength * 0.6,
        1927,
        185
      );
    }

    ctx.drawImage(headerBorusseum, 0, 1070, 567, 133);

    ctx.fillStyle = '#FFE600';
    ctx.font = '72px DIN';
    ctx.rotate(-(Math.PI * 2) / 360);

    if (this.content.language === 'en') {
      ctx.fillText(('Dreamteam by ' + this.userName).toUpperCase(), 50, 155);
    } else {
      ctx.fillText(('Wunschelf von ' + this.userName).toUpperCase(), 50, 155);
    }

    ctx.fillText('BORUSSEUM', 40, 1165);

    this.canvasImage = c.toDataURL('image/jpeg');

    this._changeDetectorRef.markForCheck();
  }

  protected OnConfigChange() {
    if (this._changeDetectorRef) {
      this._changeDetectorRef.markForCheck();
    }
  }
}
