import {
  animate,
  keyframes,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const pinModalAnimation = trigger('declined', [
  transition('* => declined', [
    style({
      transform: 'translateX(0px)',
    }),
    animate(
      '600ms',
      keyframes([
        style({ transform: 'translateX(0px)' }),
        style({ transform: 'translateX(-10px)' }),
        style({ transform: 'translateX(10px)' }),
        style({ transform: 'translateX(-10px)' }),
        style({ transform: 'translateX(10px)' }),
        style({ transform: 'translateX(-10px)' }),
        style({ transform: 'translateX(10px)' }),
        style({ transform: 'translateX(0px)' }),
      ])
    ),
  ]),
]);
