<app-input-validation-error
  [errorMsgs]="errorMsgs"
></app-input-validation-error>

<ng-container *ngIf="label">
  <label class="input-text-label">
    {{ label | translate }}
  </label>
</ng-container>

<!-- Default -->
<input
  #inputValue
  #ngModel="ngModel"
  (blur)="Validate()"
  (focus)="ResetErrors(); ShowKeyboard()"
  [attr.disabled]="disabled ? true : null"
  [class]="'input-text ' + camouflageSize"
  [maxlength]="maxlength"
  [ngClass]="{
    'has-error':
      (form.submitted && ngModel.invalid) ||
      (ngModel.touched && ngModel.invalid),
    'input-camouflage': camouflage,
    white: noForm
  }"
  autocapitalize="none"
  id="{{ id }}"
  name="{{ name }}"
  ngModel="{{ initValue }}"
  ngModelGroup="{{ ngGroupName }}"
  pattern="{{ pattern }}"
  placeholder="{{ placeholder | translate }}"
  required="{{ required || false }}"
  type="{{ type }}"
  (ngModelChange)="Change($event)"
/>

<app-keyboard
  (finish)="HideKeyboard()"
  (valueChanged)="ValueChanged($event, inputValue)"
  *ngIf="keyboardOpen || alwaysShowKeyboard"
  [initialValue]="initValue"
  [maxlength]="maxlength"
  [title]="label"
  [type]="keyboardType"
></app-keyboard>
