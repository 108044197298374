import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ClientConfig } from './config.reducer';
import { LoadStatus } from '../definitions/load-states';

export const GetConfig = createFeatureSelector('config');

export const GetClientConfig = () =>
  createSelector(GetConfig, (config: ClientConfig) => {
    return config;
  });

export const IsClientConfigSet = () =>
  createSelector(GetConfig, (config: ClientConfig) => {
    return (
      config.station_type !== undefined &&
      config.statuses.store !== LoadStatus.init
    );
  });

export const GetClientStation = () => {
  createSelector(GetConfig, (config: ClientConfig) => {
    return config.station_type;
  });
};

export const GetConfigStatus = (s) =>
  createSelector(GetConfig, (config: ClientConfig) => {
    return config.statuses[s];
  });
