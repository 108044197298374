<app-standby-header [titles]="['PAGES.CONFIG.BIG_TITLE' | translate]">
</app-standby-header>

<div class="spacer spacer-40"></div>

<!--select station-->
<div class="row">
  <div class="col-xsmall-8 col-xsmall-offset-2">
    <ng-container *ngIf="stations.length > 0">
      <form
        #selectForm="ngForm"
        (ngSubmit)="SetStation(selectForm)"
        class="select-station-form"
      >
        <app-input-text
          [form]="selectForm"
          [initValue]="client_name"
          [label]="'PAGES.CONFIG.CLIENT_NAME.LABEL' | translate"
          [placeholder]="'PAGES.CONFIG.CLIENT_NAME.PLACEHOLDER' | translate"
          [required]="false"
          name="clientName"
        ></app-input-text>
        <div class="spacer-8"></div>
        <app-input-number
          [form]="selectForm"
          [initValue]="standbyTime"
          [label]="'PAGES.CONFIG.STANDBYTIME.LABEL' | translate"
          [max]="180"
          [min]="10"
          [placeholder]="'PAGES.CONFIG.STANDBYTIME.PLACEHOLDER' | translate"
          [required]="true"
          [toggle]="true"
          name="standbyTime"
        ></app-input-number>
        <div class="spacer-8"></div>
        <app-dropdown
          (valueChanges)="TemporarilySaveStation($event)"
          [activeItemInList]="false"
          [direction]="'down'"
          [elements]="stations"
          [form]="selectForm"
          [label]="'PAGES.CONFIG.SELECT.STATION.LABEL' | translate"
          [placeholder]="'PAGES.CONFIG.SELECT.STATION.PLACEHOLDER' | translate"
          name="field"
          size="big"
        >
        </app-dropdown>
        <div class="spacer-4"></div>

        <app-dropdown
          (valueChanges)="TemporarilySaveSubItem($event)"
          *ngIf="subItems.length > 0"
          [activeItemInList]="false"
          [direction]="'down'"
          [elements]="subItems"
          [form]="selectForm"
          [label]="'PAGES.CONFIG.SELECT.SUBTYPE' | translate"
          [placeholder]="'PAGES.CONFIG.SELECT.SUBTYPE_PLACEHOLDER' | translate"
          name="field"
          size="big"
        >
        </app-dropdown>
        <app-loading *ngIf="loading === true"></app-loading>

        <div class="spacer-4"></div>

        <div class="row">
          <div class="col-small-4 col-small-offset-4">
            <button
              [disabled]="!isSaveable()"
              [ngClass]="{
                loading: saveStatus === 'loading',
                success: saveStatus === 'loaded'
              }"
              class="button button-max"
              type="submit"
            >
              {{ "PAGES.CONFIG.SAVE" | translate }}
            </button>
            <div class="spacer-4"></div>
            <button
              (click)="BackToStation()"
              *ngIf="!this.firstLoad"
              class="button button-max button--grey"
            >
              {{ "PAGES.CONFIG.BACK_TO_STATION" | translate }}
            </button>
          </div>
        </div>

        <div class="spacer-4"></div>
        <hr />
        <div class="spacer-4"></div>
        <div class="row">
          <div class="col-small-4 col-small-offset-4">
            <p>{{ "PAGES.CONFIG.RELOAD_HELP" | translate }}</p>
            <div (click)="Reload()" class="button">
              {{ "PAGES.CONFIG.RELOAD" | translate }}
            </div>
          </div>
        </div>
        <div class="spacer-8"></div>
      </form>
    </ng-container>

    <ng-container *ngIf="stations.length === 0 && loading === true">
      <app-loading></app-loading>
    </ng-container>
  </div>
</div>
