import { Component, OnInit } from '@angular/core';
import { AppStore } from '../../../core/store/appStore';
import { Store } from '@ngrx/store';
import { GetClientConfig } from '../../../core/store/config/config.selector';
import { GetContent } from '../../../core/store/content/content.selector';
import { Content } from '../../../core/store/content/content.reducer';
import { StationStatus } from '../../../core/store/definitions/station-states';
import { SubscriptionsHelperComponent } from '../../../core/helper/subscriptions-helper/subscriptions-helper.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-station-base',
  templateUrl: './station-base.component.html',
  styleUrls: ['./station-base.component.scss'],
})
export class StationBaseComponent
  extends SubscriptionsHelperComponent
  implements OnInit
{
  public config;
  public content;
  public currentLanguage;

  public StationStati = StationStatus;

  constructor(
    protected store: Store<AppStore>,
    protected _translateService: TranslateService
  ) {
    super();

    this.currentLanguage = this._translateService.currentLang;

    this.AddSubscription(
      this._translateService.onLangChange.subscribe((lang) => {
        this.currentLanguage = lang.lang;
      })
    );

    this.AddSubscription(
      this.store.select(GetClientConfig()).subscribe((c) => {
        this.config = c;
        this.OnConfigChange();
      })
    );

    this.AddSubscription(
      this.store.select(GetContent).subscribe((c: Content) => {
        this.content = c;
        this.OnContentChange();
      })
    );
  }

  ngOnInit(): void {}

  protected OnConfigChange() {}

  protected OnContentChange() {
    if (this.content.loadStatus === 'loaded') {
      this.ParseContent();
    }
  }

  protected ParseContent() {}
}
