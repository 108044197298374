import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { BaseModalComponent } from '../base-modal/base-modal.component';
import { AppStore } from '../../../store/appStore';
import { Store } from '@ngrx/store';
import { GetContent } from '../../../store/content/content.selector';
import { LoadStatus } from '../../../store/definitions/load-states';
import { DreamElevenPositions } from '../../../../stations/dream-eleven/positions';
import { NgForm } from '@angular/forms';

import { cloneDeep } from 'lodash';
@Component({
  selector: 'app-dream-eleven-choose-player',
  templateUrl: './dream-eleven-choose-player.component.html',
  styleUrls: ['./dream-eleven-choose-player.component.scss'],
})
export class DreamElevenChoosePlayerComponent
  extends BaseModalComponent
  implements OnInit, OnChanges
{
  @Input() position;
  @Input() selectedPlayerList;
  public content;
  public LoadStati = LoadStatus;
  public personList;
  public filteredPersonList;
  @Output() playerSelect: EventEmitter<any> = new EventEmitter<any>();
  public search = false;
  public searchValue = '';
  @ViewChild('nameForm', { static: true }) ngForm: NgForm;

  constructor(
    private _store: Store<AppStore>,
    private _changeDetectorRef: ChangeDetectorRef
  ) {
    super();
    this.AddSubscription(
      this._store.select(GetContent).subscribe((content) => {
        this.content = content;
        this._changeDetectorRef.markForCheck();
      })
    );
  }

  ngOnInit(): void {
    this.Filter();
  }

  ngOnChanges(changes: SimpleChanges) {
    const position = this.position.position;

    switch (position) {
      case DreamElevenPositions.coach:
        this.personList = this.content.dreamEleven.trainer;
        break;
      default:
        this.personList = this.content.dreamEleven.player[position];
        break;
    }
    this.Filter();
  }

  public ToggleSearch() {
    this.search = !this.search;
    this.Filter();
  }

  public SelectPlayer(player) {
    this.playerSelect.emit(player);
    this.Close();
  }

  public Filter() {
    if (!this.search) {
      this.filteredPersonList = cloneDeep(this.personList);
    } else {
      const searchArray = this.searchValue.split(' ');
      this.filteredPersonList = cloneDeep(this.personList).filter((person) => {
        return (
          (person.first_name + ' ' + person.last_name)
            .toLowerCase()
            .indexOf(this.searchValue.toLowerCase()) > -1
        );
      });
    }

    this._changeDetectorRef.markForCheck();
  }
}
