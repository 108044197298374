import { Injectable } from '@angular/core';
import { ApiConnectorService } from '../api-connector.service';
import { ConfigService } from '../../config/config.service';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { AppStore } from '../../store/appStore';

@Injectable({
  providedIn: 'root',
})
export class ApiConnectorBackendSaveService extends ApiConnectorService {
  constructor(
    _configService: ConfigService,
    _http: HttpClient,
    _store: Store<AppStore>
  ) {
    super(_configService, _http, _store);
  }

  public SaveQuizHighscore(name: string, points: number) {
    return this._http.post(
      this._config.api.baseUrl +
        'rest/export/borusseum/station/quiz/highscore/add',
      {
        user_name: name,
        score: points,
      }
    );
  }

  public MailDreamElevenImage(data, language) {
    if (language === 'en') {
      return this._http.post(
        this._config.api.baseUrl + 'en/api/station/dream_eleven/result/mail',
        data
      );
    } else {
      return this._http.post(
        this._config.api.baseUrl + 'api/station/dream_eleven/result/mail',
        data
      );
    }
  }

  public MailPottstarsImage(data, language) {
    if (language === 'en') {
      return this._http.post(
        this._config.api.baseUrl + 'en/api/station/pottstars/result/mail',
        data
      );
    } else {
      return this._http.post(
        this._config.api.baseUrl + 'api/station/pottstars/result/mail',
        data
      );
    }
  }

  public SavePlayerCount(data) {
    return this._http.post(
      this._config.api.baseUrl +
        'rest/export/borusseum/station/dream_eleven/submit',
      data
    );
  }

  public SaveDigitalGuestbookPage(data) {
    return this._http.post(
      this._config.api.baseUrl + 'api/station/digital_guestbook/entry/add',
      data
    );
  }
}
