export enum DreamElevenPositions {
  keeper = 'field_top_goalkeeper_counter',
  defender_left = 'field_top_left_defender_counter',
  defender_right = 'field_top_right_defender_counter',
  defender_center = 'field_top_center_back_counter',
  midfield_center = 'field_top_cm_counter',
  midfield_left = 'field_top_left_mf_counter',
  midfield_right = 'field_top_right_mf_counter',
  attack_left = 'field_top_left_winger_counter',
  attack_center = 'field_top_center_forward_counter',
  attack_right = 'field_top_right_winger_counter',
  coach = 'field_top_trainer_counter',
}
