<div class="quiz-progress">
  <div
    *ngFor="let question of questions; let i = index"
    [ngClass]="{ 'quiz-progress__item--yellow': i <= currentQuestion }"
    class="quiz-progress__item"
  >
    <ng-container *ngIf="question.questionType !== QuestionTypes.STANDARD">
      <div class="sparkle sparkle__1"></div>
      <div class="sparkle sparkle__2"></div>
      <div class="sparkle sparkle__3"></div>
      <div class="sparkle sparkle__4"></div>
      <div class="sparkle sparkle__5"></div>
      <div class="sparkle sparkle__6"></div>
    </ng-container>
  </div>
</div>
