import { DoublePage } from './../../../stations/guestbook/guestbook.component';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppStore } from '../appStore';
import {
  CONTENT_ACTIONS,
  fetchContentError,
  fetchContentSuccess,
  fetchDreamElevenPersonsError,
  fetchDreamElevenPersonsSuccess,
  fetchGuestbookPagesError,
  fetchGuestbookPagesSuccess,
  fetchQuizQuestionsError,
  fetchQuizQuestionsSuccess,
} from './content.actions';
import { ApiConnectorContentService } from '../../io/content/api-connector-content.service';
import { ClientConfig } from '../config/config.reducer';
import { TranslationService } from '../../i18n/translation.service';
import { Page } from 'src/app/stations/guestbook/guestbook.component';

@Injectable()
export class ContentEffects {
  fetchContent$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CONTENT_ACTIONS.CONTENT_FETCH_CONTENT),
      mergeMap((payload: { config: ClientConfig }) => {
        return this._apiConnectorContentService
          .FetchContent(payload.config)
          .pipe(
            map((data: any) => {
              this._translationService.AddTranslationsFromInitCall(data);
              try {
                return fetchContentSuccess({ payload: data });
              } catch (e) {
                throw e;
              }
            }),
            catchError((error) =>
              of(
                fetchContentError({
                  payload: error,
                })
              )
            )
          );
      })
    )
  );

  fetchQuiz$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CONTENT_ACTIONS.CONTENT_FETCH_QUIZ_QUESTIONS),
      mergeMap(() => {
        return this._apiConnectorContentService.FetchQuizQuestions().pipe(
          map((data: any) => {
            try {
              return fetchQuizQuestionsSuccess({ payload: data['rows'] });
            } catch (e) {
              throw e;
            }
          }),
          catchError((error) =>
            of(
              fetchQuizQuestionsError({
                payload: error,
              })
            )
          )
        );
      })
    )
  );

  fetchDreamElevenPersons$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CONTENT_ACTIONS.CONTENT_FETCH_DREAM_ELEVEN_PERSONS),
      mergeMap(() => {
        return this._apiConnectorContentService.FetchDreamElevenPlayers().pipe(
          map((data: any) => {
            try {
              return fetchDreamElevenPersonsSuccess({
                player: data[0]['rows'],
                trainer: data[1]['rows'],
              });
            } catch (e) {
              throw e;
            }
          }),
          catchError((error) =>
            of(
              fetchDreamElevenPersonsError({
                payload: error,
              })
            )
          )
        );
      })
    )
  );

  fetchGuestbookPages$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CONTENT_ACTIONS.CONTENT_FETCH_GUESTBOOK_PAGES),

      mergeMap(() => {
        return this._apiConnectorContentService.fetchGuestbookPages().pipe(
          map((data: any) => {
            try {
              const pages: Page[] = [];
              for (let i = 0; i < data.rows.length; i++) {
                pages.unshift({
                  url: data.rows[i].field_image_serialized.field_media_image
                    ._url.absolute,
                  pageNr: i,
                });
              }

              if (data.rows.length === 0) {
                pages.unshift(
                  {
                    url: undefined,
                    pageNr: 0,
                  },
                  {
                    url: undefined,
                    pageNr: 1,
                  }
                );
              }

              const doublePages: DoublePage[] = [];
              let pageCounter = 0;
              for (let i = 0; i < pages.length; i += 2) {
                doublePages.push({
                  pageOne: pages[i],
                  pageTwo: pages[i + 1]
                    ? pages[i + 1]
                    : { url: undefined, pageNr: pages[i].pageNr + 1 },
                  index: pageCounter,
                });
                pageCounter++;
              }

              return fetchGuestbookPagesSuccess({
                pages: doublePages,
                pageCount: pageCounter - 1,
              });
            } catch (e) {
              throw e;
            }
          }),
          catchError((error) =>
            of(
              fetchGuestbookPagesError({
                payload: error,
              })
            )
          )
        );
      })
    )
  );

  constructor(
    private _actions$: Actions,
    private _store: Store<AppStore>,
    private _apiConnectorContentService: ApiConnectorContentService,
    private _translationService: TranslationService
  ) {}
}
