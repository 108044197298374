<div *ngIf="label" class="dropdown-label">{{ label | translate }}</div>

<div
  (focusout)="focusout()"
  (keydown)="checkKeyPressDropdown($event)"
  class="dropdown js-dropdown {{ directionState }} {{ size }} color-{{
    color
  }} {{ disabled ? 'disabled' : '' }} {{ isFormType ? 'dropdown-form' : '' }}"
  ngClass="{{
    (form.submitted && ngModel.invalid) || (ngModel.touched && ngModel.invalid)
      ? 'has-error'
      : ''
  }}"
  tabindex="{{ disabled ? -1 : 0 }}"
>
  <div
    (click)="toggleDropdown($event)"
    class="dropdown-title js-dropdown-clickable"
    ngClass="{{ dropdownState }}"
  >
    <span
      [ngClass]="{ 'with-image': defaultGraphic || defaultImage }"
      class="dropdown-val js-dropdown-val"
      style="font-family: {{fontStyle}}; letter-spacing: 0px"
    >
      <app-svg-icon
        *ngIf="defaultGraphic"
        [module]="defaultGraphic.graphic_module"
        [src]="defaultGraphic.graphic"
        [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"
      ></app-svg-icon>
      <img
        *ngIf="defaultImage"
        [src]="config.api.assetUrl + defaultImage.image"
      />
      <span *ngIf="preText !== ''" class="pretext"
        >{{ preText | translate }}
      </span>
      {{ checkedText | translate }}
    </span>
    <div class="dropdown-arrow" ngClass="{{ dropdownState }}"></div>
  </div>
  <div
    class="dropdown-container js-dropdown-container {{ checkElements() }}"
    ngClass="{{ dropdownState }}"
  >
    <div
      (click)="gotSelection(element.id, $event, element.disabled)"
      *ngFor="let element of FilterElements()"
      [attr.data-name]="element.id"
      class="dropdown-element js-dropdown-element{{
        element.checkedState ? ' selected' : ''
      }}{{ element.disabled ? ' disabled-element' : '' }}"
      tabindex="-1"
      style="font-family: {{element.font}}; letter-spacing: 0px"
    >
      <span *ngIf="preText !== ''" class="pretext"
        >{{ preText | translate }}
      </span>
      {{ element.text | translate }}
    </div>
  </div>
</div>

<input
  #ngModel="ngModel"
  name="{{ name }}"
  ngModel="{{ checkedValue }}"
  ngModelGroup="{{ ngGroupName }}"
  required="{{ required || false }}"
  type="hidden"
/>
