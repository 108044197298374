<div (click)="Close()" class="modal__background"></div>
<div
  (@declined.done)="ResetDeclinedAnimation()"
  [@declined]="declined"
  class="modal__box"
>
  <div (click)="Close()" class="modal__box__close"></div>

  <h4 class="text--center">Enter PIN</h4>

  <div class="spacer-2"></div>
  <app-pin-number-dots
    [currentPinCount]="numOfEnteredPins"
    [maxPins]="pin.length"
  ></app-pin-number-dots>
  <div class="spacer"></div>

  <app-keyboard
    (valueChanged)="CheckPin($event)"
    *ngIf="open"
    [initialValue]="initPin"
    [noInputAllowed]="locked"
    [overrideValue]="inputPin"
    [type]="'pin'"
  ></app-keyboard>
  <div class="spacer-4"></div>
  <div class="button-wrapper button-wrapper--center">
    <a
      (click)="
        Action({ action: MODAL_ACTIONS.select, value: 'close' }); Close()
      "
      class="button button--secondary button--no-margin-bottom"
      >Abbrechen</a
    >
  </div>
</div>
