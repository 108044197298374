<div class="video-container">
  <video
    class="webcam"
    #video
    id="video"
    [width]="WIDTH"
    [height]="HEIGHT"
    autoplay
  ></video>
</div>
<div class="singing" *ngIf="status == KaraokeStates.SINGING">
  <div>
    <video class="lyric-video" id="lyrics" #lyrics autoplay>
      <source
        [src]="
          currentSong.field_video_file._serialized.field_media_video_file._url
            .absolute
        "
      />
    </video>
  </div>

  <div
    class="button button__handwriting button__back"
    (click)="BackToSongChooser()"
    [innerHTML]="'POTTSTARS.BACK_TO_OVERVIEW' | translate"
  ></div>

  <div
    class="button button__handwriting button__continue"
    (click)="SkipSinging()"
    [innerHTML]="'POTTSTARS.SKIP_AND_TAKE_PICTURE' | translate"
  ></div>
</div>

<!-- POST SINGING STATE -->
<div class="post-singing" *ngIf="status == KaraokeStates.POST_SINGING">
  <span class="post-singing__message transparent-background"
    >{{ "field_thanks_text" | translate }}
  </span>
  <div class="post-singing__clr"></div>
  <div
    class="button button__handwriting button__photo"
    (click)="StartCountdown()"
  >
    {{ "field_souvenir_photo_text" | translate }}
  </div>

  <div
    class="button button__handwriting button__back"
    (click)="BackToSongChooser()"
    [innerHTML]="'field_sing_again_text' | translate"
  ></div>
</div>
