import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { ConfigService } from '../../config/config.service';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class DropdownComponent implements OnInit, OnChanges {
  @Input() elements: any;
  @Input() placeholder: string = 'UI.DROPDOWN.STANDBY';
  @Input() name: string;
  @Input() required: boolean;
  @Input() form: any;
  @Input() ngGroupName: string = 'data';
  @Input() direction: string;
  @Input() label: string;
  @Input() size: string = 'STANDBY-size';
  @Input() disabled: boolean = false;
  @Input() isFormType: boolean = false;
  @Input() initialValue: string;
  @Input() resetValue: string;
  @Input() color: string = 'STANDBY-color';
  @Input() activeItemInList = true;
  @Input() preText = '';
  public directionState: string;
  public checkedValue: string;
  public checkedText: string;
  public fontStyle: string;
  public dropdownState: string = 'close';
  @Input() public defaultGraphic: { graphic: string; graphic_module?: string };
  @Input() public defaultImage: { image: string; image_module?: string };
  @Output() valueChanges: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('ngModel') dropdownModel: any;
  public config;
  private elementsHeight: number;

  constructor(
    private _elementRef: ElementRef,
    private _changeDetectorRef: ChangeDetectorRef,
    private _configService: ConfigService
  ) {
    this.config = this._configService.config;
  }

  @HostListener('document:click', ['$event.target'])
  clickout(target) {
    if (
      this._elementRef.nativeElement.querySelector('.js-dropdown') !==
        this.findParent(target, 'js-dropdown') &&
      this.dropdownState !== 'close'
    ) {
      this.closeDropdown();
    }
  }

  ngOnInit() {
    this.directionState = this.direction;

    if (this.initialValue) {
      this.checkedValue = this.initialValue; //this.getCheckedValue(true);

      const el = this.elements.find(
        (element) => element.id == this.initialValue
      );

      this.checkedText = el['text']; //this.getCheckedValue();

      this.fontStyle = el['font'] ? el['font'] : '';
    }

    if (this.size === 'big') {
      this.elementsHeight = 32;
    } else {
      this.elementsHeight = 24;
    }

    this.checkExpandDirection();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['elements'] &&
      JSON.stringify(changes['elements'].previousValue) !==
        JSON.stringify(changes['elements'].currentValue)
    ) {
      this.checkedValue = this.getCheckedValue(true);
      this.checkedText = this.getCheckedValue();
    }

    if (
      changes &&
      changes['resetValue'] &&
      changes['resetValue'].previousValue !== changes['resetValue'].currentValue
    ) {
      this.elements.forEach((element) => {
        element.checkedState = false;
      });

      this.checkedValue = this.getCheckedValue(true);
      this.checkedText = this.getCheckedValue();
      this.defaultGraphic = undefined;
    }

    this._changeDetectorRef.markForCheck();
  }

  checkElements(): string {
    if (!this.elements) return;

    switch (this.FilterElements().length) {
      case 1:
        return 'one-element';
      case 2:
        return 'two-elements';
      case 3:
        return 'three-elements';
      case 4:
        return 'four-elements';
      default:
        return 'full-height';
    }
  }

  checkExpandDirection(): void {
    if (this.direction == 'up') {
      this.directionState = 'reverse-direction';
    } else if (this.direction == 'down') {
      this.directionState = 'STANDBY-direction';
    } else {
      const thisDropdownRect = this._elementRef.nativeElement
        .querySelector('.js-dropdown-clickable')
        .getBoundingClientRect();
      const distanceToBottom = window.innerHeight - thisDropdownRect.bottom;

      let containerHeight;

      if (!this.elements) {
        this.directionState = 'STANDBY-direction';
      }

      if (this.elements.length > 4) {
        containerHeight = 5 * this.elementsHeight;
      } else {
        containerHeight = this.elements.length * this.elementsHeight;
      }

      if (distanceToBottom - containerHeight > 50) {
        this.directionState = 'STANDBY-direction';
      } else {
        this.directionState = 'reverse-direction';
      }
    }
  }

  toggleDropdown(event): void {
    const dropdown = this.checkElClick(event.target);

    if (dropdown) {
      if (dropdown.classList.contains('open')) {
        this.closeDropdown();
      } else {
        this.openDropdown();
      }

      if (
        document.getElementsByClassName('js-dropdown-clickable open').length > 1
      ) {
        Array.from(
          document.getElementsByClassName('js-dropdown-clickable open')
        ).forEach((item) => {
          if (item !== dropdown) {
            this.closeDropdown();
          }
        });
      }

      setTimeout(() => this.checkScrollPosition(dropdown), 375);
    }
  }

  openDropdown(): void {
    this.checkExpandDirection();
    this.dropdownState = 'open';
  }

  closeDropdown(): void {
    this.dropdownState = 'close';
    this.dropdownModel.control.markAsTouched();
  }

  checkElClick(el): any {
    if (!el.classList.contains('js-dropdown-clickable')) {
      return this.findParent(el, 'js-dropdown-clickable');
    } else {
      return el;
    }
  }

  findParent(el, className): any {
    if (el.classList.contains(className)) {
      return el;
    }

    while (el.parentNode) {
      el = el.parentNode;

      if (el == document.body) {
        return null;
      }

      if (el && el.classList) {
        if (el.classList.contains(className)) {
          return el;
        }
      }
    }

    return null;
  }

  gotSelection(id, event, disabled?): void {
    if (disabled == true) {
      return;
    }

    const dropdown =
      this._elementRef.nativeElement.querySelector('.js-dropdown');
    const item = this.findParent(event.target, 'js-dropdown-element');

    for (const item of this.elements) {
      if (item.checkedState) {
        item.checkedState = false;
      }

      if (String(item.id) == id) {
        item.checkedState = true;
      }
    }

    this.checkedValue = this.getCheckedValue(true);
    this.checkedText = this.getCheckedValue();
    this.valueChanges.emit(this.checkedValue);

    setTimeout(() => this._changeDetectorRef.markForCheck(), 0);

    if (
      dropdown.getElementsByClassName('js-dropdown-element selected').length > 0
    ) {
      dropdown
        .querySelector('.js-dropdown-element.selected')
        .classList.remove('selected');
    }

    item.classList.add('selected');

    this.closeDropdown();
  }

  getCheckedValue(modelMode?): any {
    if (!this.elements) return;

    for (const item of this.elements) {
      if (item.checkedState) {
        if (modelMode) {
          if (item['graphic']) {
            this.defaultGraphic = {
              graphic: item['graphic'],
              graphic_module: item['graphic_module'],
            };
          }

          if (item['image']) {
            this.defaultImage = {
              image: item['image'],
              image_module: item['image_module'],
            };
          }
          return item.id;
        } else {
          this.fontStyle = item['font'] ? item['font'] : '';

          return item.text;
        }
      }
    }

    if (modelMode) {
      return null;
    } else {
      return this.placeholder;
    }
  }

  checkScrollPosition(dropdown): void {
    const dropdownParent = dropdown.parentElement.querySelector(
      '.js-dropdown-container'
    );

    if (dropdownParent.querySelector('.selected') !== null) {
      let scrollTo = 0;
      const dropdownElements = dropdownParent.querySelectorAll(
        '.js-dropdown-element'
      );

      for (let i = 0; i < dropdownElements.length; i++) {
        if (dropdownElements[i].classList.contains('selected')) {
          break;
        }

        scrollTo = scrollTo + dropdownElements[i].offsetHeight;
      }

      try {
        dropdownParent.scrollTo({
          top: scrollTo,
          behavior: 'smooth',
        });
      } catch (e) {
        dropdownParent.scrollTop = scrollTo;
      }
    }
  }

  checkKeyPressDropdown(event): void {
    if (this.dropdownState == 'open') {
      const activeElement = this._elementRef.nativeElement.querySelector(
        '.js-dropdown-element:focus'
      );

      if (activeElement == null) {
        return;
      }

      if (event.which == 40) {
        const nextActiveElement = activeElement.nextSibling;

        if (nextActiveElement !== null) {
          nextActiveElement.focus();
        } else {
          activeElement.blur();
          this.closeDropdown();
        }

        event.preventDefault();
      } else if (event.which == 38) {
        const prevActiveElement = activeElement.previousSibling;

        if (prevActiveElement == null) {
          activeElement.blur();
          this.closeDropdown();
        } else if (prevActiveElement.nodeType == 8) {
          activeElement.blur();
          this.closeDropdown();
        } else {
          prevActiveElement.focus();
        }

        event.preventDefault();
      } else if (event.which == 32) {
        let selectionElement: any = {};
        selectionElement.target = document.activeElement;
        const selectionName = selectionElement.target.getAttribute('data-name');
        const selectionDisabled =
          selectionElement.target.classList.contains('disabled-element');

        if (!selectionDisabled) {
          this.gotSelection(selectionName, selectionElement, selectionDisabled);
          this.closeDropdown();
        }

        event.preventDefault();
      } else if (event.which == 27) {
        this.closeDropdown();
        event.preventDefault();
      } else if (event.which == 9) {
        this.closeDropdown();
      }
    } else {
      if (event.which == 32) {
        this._elementRef.nativeElement
          .querySelector('.js-dropdown-element:first-child')
          .focus();

        this.openDropdown();
        event.preventDefault();
      }
    }
  }

  focusout(): void {
    if (this.dropdownState == 'close') {
      this.closeDropdown();
    }
  }

  public FilterElements() {
    if (this.activeItemInList) {
      return this.elements;
    } else {
      return this.elements.filter((el) => !el.checkedState);
    }
  }
}
