<h1>{{ "field_highscore_headline" | translate }}</h1>
<ng-container [ngSwitch]="currentHighscoreState">
  <ng-container *ngSwitchCase="quizHighscoreStates.LOADING">
    <app-loading></app-loading>
  </ng-container>
  <ng-container *ngSwitchCase="quizHighscoreStates.LOADED">
    <table [ngClass]="{ 'seperated-last-rank': highscores[3]?.position > 4 }">
      <thead>
        <tr>
          <th>
            {{ "field_position_result_text" | translate }}
          </th>
          <th>
            {{ "field_name_result_text" | translate }}
          </th>
          <th>
            {{ "field_highscore_result_text" | translate }}
          </th>
        </tr>
      </thead>
      <tbody>
        }
        <tr
          *ngFor="let highscore of highscores"
          [ngClass]="{ user: highscore.id === userUUID }"
        >
          <td class="rank rank--{{ highscore.position }}">
            <span>
              {{ highscore.position }}.
              <ng-container *ngIf="highscore.id == userUUID">
                <div class="sparkle sparkle__1"></div>
                <div class="sparkle sparkle__2"></div>
                <div class="sparkle sparkle__3"></div>
                <div class="sparkle sparkle__4"></div>
                <div class="sparkle sparkle__5"></div>
                <div class="sparkle sparkle__6"></div>
              </ng-container>
            </span>
          </td>
          <td>{{ highscore.user_name }}</td>
          <td class="score">{{ highscore.score }}</td>
        </tr>
      </tbody>
    </table>
  </ng-container>
  <div (click)="Restart()" class="button button__handwriting">
    {{ "field_start_again_button_text" | translate }}
  </div>
</ng-container>
