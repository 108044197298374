import { createAction, props } from '@ngrx/store';
import { LoadStatus } from '../definitions/load-states';
import { StationTypes } from '../definitions/stationTypes';

export const enum CONFIG_ACTIONS {
  CONFIG_SET_STATION = '[CONFIG] SET_STATION',
  CONFIG_SET_STATION_LOCALLY = '[CONFIG] SET_STATION_LOCALLY',
  CONFIG_SET_STATION_SUCCESS = '[CONFIG] SET_STATION_SUCCESS',
  CONFIG_SET_STATION_CMS = '[CONFIG] SET_STATION_CMS',
  CONFIG_SET_STATION_CMS_SUCCESS = '[CONFIG] SET_STATION_CMS_SUCCESS',
  CONFIG_SET_STATION_CMS_ERROR = '[CONFIG] SET_STATION_CMS_ERROR',
  CONFIG_SET_STANDBY_TIME = '[CONFIG] SET_STANDBY_TIME',
  CONFIG_SET_CLIENT_ID = '[CONFIG] SET_CLIENT_ID',
  CONFIG_SET_STATUS = '[CONFIG] SET_STATUS',
}

export const setStation = createAction(
  CONFIG_ACTIONS.CONFIG_SET_STATION,
  props<{
    clientId: string;
    station: {
      station_type: StationTypes;
      station_id?: number;
      client_name?: string;
      timeout?: number;
    };
  }>()
);

export const setStationSuccess = createAction(
  CONFIG_ACTIONS.CONFIG_SET_STATION_SUCCESS,
  props<{ payload }>()
);

export const setStatus = createAction(
  CONFIG_ACTIONS.CONFIG_SET_STATUS,
  props<{
    status: LoadStatus;
    statusType: 'cms' | 'local' | 'store';
  }>()
);

export const setStationOnlyLocally = createAction(
  CONFIG_ACTIONS.CONFIG_SET_STATION_LOCALLY,
  props<{
    station_type: StationTypes;
    station_id?: number;
    client_name?: string;
    timeout?: number;
  }>()
);

export const setStationOnCMSSuccess = createAction(
  CONFIG_ACTIONS.CONFIG_SET_STATION_CMS_SUCCESS,
  props<{
    data: any;
  }>()
);
export const setStationOnCMSError = createAction(
  CONFIG_ACTIONS.CONFIG_SET_STATION_CMS_ERROR,
  props<{
    error: Error;
  }>()
);

export const setStandbyTime = createAction(
  CONFIG_ACTIONS.CONFIG_SET_STANDBY_TIME,
  props<{
    timeout: number;
  }>()
);

export const setClientId = createAction(
  CONFIG_ACTIONS.CONFIG_SET_CLIENT_ID,
  props<{ clientId: string }>()
);
