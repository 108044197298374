import { DreamElevenPositions } from './positions';

interface DreamElevenFormation {
  name: string;
  positions: Array<{
    position: DreamElevenPositions;
    x: number;
    y: number;
    id: number;
  }>;
  imgUrl: string;
}

export const DreamElevenFormations: Array<DreamElevenFormation> = [
  {
    name: '4-4-2',
    positions: [
      {
        position: DreamElevenPositions.keeper,
        x: 10,
        y: 50,
        id: 0,
      },
      {
        position: DreamElevenPositions.defender_left,
        x: 25,
        y: 20,
        id: 1,
      },
      {
        position: DreamElevenPositions.defender_center,
        x: 25,
        y: 40,
        id: 2,
      },
      {
        position: DreamElevenPositions.defender_center,
        x: 25,
        y: 60,
        id: 3,
      },
      {
        position: DreamElevenPositions.defender_right,
        x: 25,
        y: 80,
        id: 4,
      },
      {
        position: DreamElevenPositions.midfield_left,
        x: 50,
        y: 20,
        id: 5,
      },
      {
        position: DreamElevenPositions.midfield_center,
        x: 50,
        y: 40,
        id: 6,
      },
      {
        position: DreamElevenPositions.midfield_center,
        x: 50,
        y: 60,
        id: 7,
      },
      {
        position: DreamElevenPositions.midfield_right,
        x: 50,
        y: 80,
        id: 8,
      },
      {
        position: DreamElevenPositions.attack_center,
        x: 80,
        y: 35,
        id: 9,
      },
      {
        position: DreamElevenPositions.attack_center,
        x: 80,
        y: 65,
        id: 10,
      },
    ],
    imgUrl: '../../../assets/img/4-4-2.svg',
  },
  {
    name: '4-2-3-1',
    positions: [
      {
        position: DreamElevenPositions.keeper,
        x: 10,
        y: 50,
        id: 0,
      },
      {
        position: DreamElevenPositions.defender_left,
        x: 25,
        y: 20,
        id: 1,
      },
      {
        position: DreamElevenPositions.defender_center,
        x: 25,
        y: 40,
        id: 2,
      },
      {
        position: DreamElevenPositions.defender_center,
        x: 25,
        y: 60,
        id: 3,
      },
      {
        position: DreamElevenPositions.defender_right,
        x: 25,
        y: 80,
        id: 4,
      },
      {
        position: DreamElevenPositions.midfield_center,
        x: 45,
        y: 40,
        id: 5,
      },
      {
        position: DreamElevenPositions.midfield_center,
        x: 45,
        y: 60,
        id: 6,
      },
      {
        position: DreamElevenPositions.attack_left,
        x: 60,
        y: 20,
        id: 7,
      },
      {
        position: DreamElevenPositions.attack_right,
        x: 60,
        y: 80,
        id: 8,
      },
      {
        position: DreamElevenPositions.midfield_center,
        x: 60,
        y: 50,
        id: 9,
      },
      {
        position: DreamElevenPositions.attack_center,
        x: 80,
        y: 50,
        id: 10,
      },
    ],
    imgUrl: '../../../assets/img/4-2-3-1.svg',
  },
  {
    name: '3-2-2-3',
    positions: [
      {
        position: DreamElevenPositions.keeper,
        x: 10,
        y: 50,
        id: 0,
      },
      {
        position: DreamElevenPositions.defender_left,
        x: 25,
        y: 30,
        id: 1,
      },
      {
        position: DreamElevenPositions.defender_center,
        x: 25,
        y: 50,
        id: 2,
      },
      {
        position: DreamElevenPositions.defender_right,
        x: 25,
        y: 70,
        id: 3,
      },
      {
        position: DreamElevenPositions.midfield_center,
        x: 41,
        y: 40,
        id: 4,
      },
      {
        position: DreamElevenPositions.midfield_center,
        x: 41,
        y: 60,
        id: 5,
      },
      {
        position: DreamElevenPositions.midfield_center,
        x: 60,
        y: 40,
        id: 6,
      },
      {
        position: DreamElevenPositions.midfield_center,
        x: 60,
        y: 60,
        id: 7,
      },
      {
        position: DreamElevenPositions.attack_left,
        x: 80,
        y: 30,
        id: 8,
      },
      {
        position: DreamElevenPositions.attack_center,
        x: 80,
        y: 50,
        id: 9,
      },
      {
        position: DreamElevenPositions.attack_right,
        x: 80,
        y: 70,
        id: 10,
      },
    ],
    imgUrl: '../../../assets/img/3-2-2-3.svg',
  },
  {
    name: '3-5-2',
    positions: [
      {
        position: DreamElevenPositions.keeper,
        x: 10,
        y: 50,
        id: 0,
      },
      {
        position: DreamElevenPositions.defender_left,
        x: 25,
        y: 30,
        id: 1,
      },
      {
        position: DreamElevenPositions.defender_center,
        x: 25,
        y: 50,
        id: 2,
      },
      {
        position: DreamElevenPositions.defender_right,
        x: 25,
        y: 70,
        id: 3,
      },
      {
        position: DreamElevenPositions.midfield_center,
        x: 50,
        y: 32.5,
        id: 4,
      },
      {
        position: DreamElevenPositions.midfield_center,
        x: 50,
        y: 50,
        id: 5,
      },

      {
        position: DreamElevenPositions.midfield_center,
        x: 50,
        y: 67.5,
        id: 6,
      },
      {
        position: DreamElevenPositions.midfield_left,
        x: 50,
        y: 15,
        id: 7,
      },
      {
        position: DreamElevenPositions.midfield_right,
        x: 50,
        y: 85,
        id: 8,
      },
      {
        position: DreamElevenPositions.attack_center,
        x: 80,
        y: 40,
        id: 9,
      },
      {
        position: DreamElevenPositions.attack_center,
        x: 80,
        y: 60,
        id: 10,
      },
    ],
    imgUrl: '../../../assets/img/3-5-2.svg',
  },
  {
    name: '4-3-3',
    positions: [
      {
        position: DreamElevenPositions.keeper,
        x: 10,
        y: 50,
        id: 0,
      },
      {
        position: DreamElevenPositions.defender_left,
        x: 25,
        y: 20,
        id: 1,
      },
      {
        position: DreamElevenPositions.defender_center,
        x: 25,
        y: 40,
        id: 2,
      },
      {
        position: DreamElevenPositions.defender_center,
        x: 25,
        y: 60,
        id: 3,
      },
      {
        position: DreamElevenPositions.defender_right,
        x: 25,
        y: 80,
        id: 4,
      },
      {
        position: DreamElevenPositions.midfield_left,
        x: 50,
        y: 30,
        id: 5,
      },
      {
        position: DreamElevenPositions.midfield_center,
        x: 50,
        y: 50,
        id: 6,
      },
      {
        position: DreamElevenPositions.midfield_right,
        x: 50,
        y: 70,
        id: 7,
      },
      {
        position: DreamElevenPositions.attack_left,
        x: 80,
        y: 25,
        id: 8,
      },
      {
        position: DreamElevenPositions.attack_center,
        x: 80,
        y: 50,
        id: 9,
      },
      {
        position: DreamElevenPositions.attack_right,
        x: 80,
        y: 75,
        id: 10,
      },
    ],
    imgUrl: '../../../assets/img/4-3-3.svg',
  },
];
