<ng-container *ngIf="data">
  <div class="quiz-question state-{{ quizQuestionState }}">
    <div
      *ngIf="currentQuestion.image"
      [ngClass]="{ small: bonus2ShowAnswers }"
      class="image-area"
    >
      <img [src]="currentQuestion.image" />
    </div>

    <div *ngIf="bonus2ShowAnswers !== true" class="question-area">
      <h1 [innerHTML]="currentQuestion['text_' + language]"></h1>
    </div>

    <div
      *ngIf="bonus2ShowAnswers || bonus2ShowAnswers === undefined"
      class="answers"
    >
      <ng-container
        *ngFor="let answer of currentQuestion.answer; let i = index"
      >
        <div
          (click)="Answer(answer, i)"
          [ngClass]="{
            'answer--selected': selectedAnswerIndex.indexOf(i) > -1,
            'answer--correct': answer.correctAnswer
          }"
          class="answer"
        >
          <span [innerHTML]="answer['text_' + language]"></span>
          <ng-container *ngIf="answer.correctAnswer">
            <div class="sparkle sparkle__1"></div>
            <div class="sparkle sparkle__2"></div>
            <div class="sparkle sparkle__3"></div>
            <div class="sparkle sparkle__4"></div>
            <div class="sparkle sparkle__5"></div>
            <div class="sparkle sparkle__6"></div>
          </ng-container>
        </div>
      </ng-container>
    </div>

    <div *ngIf="currentQuestion['solution_' + language]" class="solution">
      {{ currentQuestion["solution_" + language] }}
    </div>

    <div class="timeout-bar">
      <div
        [ngClass]="{
          'timeout-bar__progress--blink': blinkTimerUnder > this.timeoutProgress
        }"
        [ngStyle]="{ width: this.timeoutProgress * 100 + '%' }"
        class="timeout-bar__progress"
      ></div>
    </div>
  </div>
  <div class="background">
    <h1>{{ "field_bonus_round_headline" | translate }}</h1>
  </div>
</ng-container>
