import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  ElementRef,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { StandbyService } from 'src/app/core/standby/standby.service';
import { AppStore } from 'src/app/core/store/appStore';

export enum KaraokeState {
  SINGING,
  POST_SINGING,
}

@Component({
  selector: 'app-karaoke-player',
  templateUrl: './karaoke-player.component.html',
  styleUrls: ['./karaoke-player.component.scss'],
})
export class KaraokePlayerComponent implements OnInit, OnDestroy {
  @Output() backToSongChooser = new EventEmitter();
  @Output() startCountdown = new EventEmitter();

  @Input() currentSong;
  private toPostSingingSubscription: Subscription;

  @Input() toPostSinging: Observable<void>;

  public status: KaraokeState = KaraokeState.SINGING;

  public KaraokeStates = KaraokeState;

  @ViewChild('lyrics') public lyrics: ElementRef;

  WIDTH = 1920;
  HEIGHT = 1080;

  error;

  @ViewChild('video')
  public video: ElementRef;

  constructor(
    protected store: Store<AppStore>,
    private _standbyService: StandbyService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this._standbyService.StopStandByTimer(true);

    this.toPostSingingSubscription = this.toPostSinging.subscribe(() => {
      this._standbyService.UnlockTimer();
      this.ProgressState(KaraokeState.POST_SINGING);
    });
  }

  async ngAfterViewInit() {
    await this.SetupDevices();
    this.lyrics.nativeElement.onended = (e) => {
      this._standbyService.UnlockTimer();
      this.ProgressState(KaraokeState.POST_SINGING);
      this._changeDetectorRef.markForCheck();
    };
  }

  public async SetupDevices() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        if (stream) {
          this.video.nativeElement.srcObject = stream;
          this.video.nativeElement.play();
          this.error = null;
        } else {
          this.error = 'You have no output video device';
        }
      } catch (e) {
        this.error = e;
      }
    }
  }

  public ProgressState(toState) {
    console.log(toState);
    this.status = toState;
  }

  public StartCountdown() {
    this.startCountdown.emit();
  }

  public SkipSinging() {
    this._standbyService.UnlockTimer();
    this.ProgressState(KaraokeState.POST_SINGING);
  }

  public BackToSongChooser() {
    this._standbyService.UnlockTimer();
    this.backToSongChooser.emit();
  }

  ngOnDestroy() {
    this.toPostSingingSubscription.unsubscribe();
  }
}
