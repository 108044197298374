<div class="modal__close" (click)="Close()"></div>
<ng-container
  *ngIf="
    !this.content ||
      !this.content.dreamEleven ||
      this.content.dreamEleven.status !== LoadStati.loaded;
    then loading;
    else playerList
  "
>
</ng-container>

<ng-template #loading>
  <app-loading></app-loading>
</ng-template>

<ng-template #playerList>
  <div class="player-selection" [ngClass]="{ 'search-active': search }">
    <h1>
      <span>
        {{ "DREAM_ELEVEN.POSITION." + position.position | translate }}:
      </span>
      <div
        class="search-wrapper"
        [ngClass]="{ 'search-wrapper--active': search }"
      >
        <form class="select-station-form" #nameForm="ngForm">
          <app-input-text
            [placeholder]=""
            [form]="nameForm"
            [required]="false"
            name="name"
            [initValue]=""
            [showKeyboard]="true"
            [alwaysShowKeyboard]="true"
            [keyboardType]="'name'"
            [autofocus]="false"
            maxlength="35"
            (valueChange)="searchValue = $event; Filter()"
            [focus]="search"
          ></app-input-text>
        </form>

        <div class="search-icon" (click)="ToggleSearch()"></div>
      </div>
    </h1>

    <div class="player-list-wrapper">
      <div class="player-list">
        <ng-container *ngFor="let person of filteredPersonList">
          <p
            (click)="SelectPlayer(person)"
            [ngClass]="{
              selected: selectedPlayerList[position.position]?.id === person.id
            }"
            [innerHTML]="person.first_name + ' ' + person.last_name"
          >
            <!-- {{ person.first_name }} {{ person.last_name }} -->
          </p>
        </ng-container>
        <p *ngIf="!filteredPersonList.length" class="empty">
          {{ "DREAM_ELEVEN.EMPTY_PLAYER_LIST" | translate }}
        </p>
      </div>
    </div>
  </div>
</ng-template>
