import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as languageDE from '../assets/i18n/de.json';
import * as languageEN from '../assets/i18n/en.json';
import { CoreConfig } from './core/config/core.config';
import { ConfigService } from './core/config/config.service';
import { ApiConnectorStationsService } from './core/io/stations/api-connector-stations.service';
import { Store } from '@ngrx/store';
import { AlertService } from './core/alerts/alert.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'borrusseum-app';
  private _config: CoreConfig;
  private version = '0.3.3-20210704';

  constructor(
    private router: Router,
    translate: TranslateService,
    private _configService: ConfigService,
    private _apiConnectorStations: ApiConnectorStationsService,
    private _store: Store,
    private _alertService: AlertService
  ) {
    translate.setTranslation('de', languageDE['default']);
    translate.setTranslation('en', languageEN['default']);
    translate.setDefaultLang('de');
    translate.use('de');

    //this.router.navigateByUrl('/kitchensink');

    console.log(this.version);
  }
}
