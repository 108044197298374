import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { StationBaseComponent } from '../base/station-base/station-base.component';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { GetContent } from 'src/app/core/store/content/content.selector';
import { Content } from 'src/app/core/store/content/content.reducer';
import {
  setHeaderStatus,
  setStationStatus,
} from 'src/app/core/store/content/content.actions';
import { HeaderStatus } from 'src/app/core/store/definitions/header-states';
import { StationStatus } from 'src/app/core/store/definitions/station-states';

export enum ChronikState {
  INTRODUCTION,
  DETAILPAGE,
}

@Component({
  selector: 'app-chronik',
  templateUrl: './chronik.component.html',
  styleUrls: ['./chronik.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ChronikComponent extends StationBaseComponent implements OnInit {
  constructor(
    protected _store: Store,
    protected _translateService: TranslateService,
    private changeDetector: ChangeDetectorRef
  ) {
    super(_store, _translateService);
  }
  status: ChronikState = ChronikState.INTRODUCTION;

  public boxes;
  public selectedBox = { paragraphs: [], videos: [], pictures: [] };
  public currentId;

  @ViewChild('contentContainer') contentContainer;

  chronikStates = ChronikState;

  ngOnInit(): void {
    this.AddSubscription(
      this._store.select(GetContent).subscribe((content: Content) => {
        if (content.language === 'de') {
          this.boxes = content.content[0].field_chronicle_boxes_serialized;
        } else {
          this.boxes = content.content_en[0].field_chronicle_boxes_serialized;
        }

        if (this.status === ChronikState.DETAILPAGE) {
          this.selectedBox = { paragraphs: [], videos: [], pictures: [] };
          this.LoadArticleById(this.currentId);
        }
        this.changeDetector.markForCheck();
      })
    );
  }

  public ProgressState(toState) {
    this.status = toState;
  }

  public LoadArticleById(id) {
    this.currentId = id;
    if (this.boxes[id].field_content) {
      for (let i = 0; i < this.boxes[id].field_content.length; i++) {
        if (this.boxes[id].field_content[i]._serialized.field_text) {
          this.selectedBox.paragraphs.push({
            value: this.boxes[id].field_content[i]._serialized.field_text.value,
            order: parseInt(this.boxes[id].field_content[i].target_revision_id),
          });
        } else if (
          this.boxes[id].field_content[i]._serialized.field_media._serialized
            .field_media_image
        ) {
          this.selectedBox.pictures.push({
            value:
              this.boxes[id].field_content[i]._serialized.field_media
                ._serialized.field_media_image._url.absolute,
            order: parseInt(this.boxes[id].field_content[i].target_revision_id),
          });
        } else if (
          this.boxes[id].field_content[i]._serialized.field_media._serialized
            .field_media_video_file
        ) {
          this.selectedBox.videos.push({
            value:
              this.boxes[id].field_content[i]._serialized.field_media
                ._serialized.field_media_video_file._url.absolute,
            order: parseInt(this.boxes[id].field_content[i].target_revision_id),
          });
        }
      }
    }
  }

  public BackgroundStyling(box) {
    if (this.currentLanguage === "en") {
        if (box.field_position.value === '5') {
          return 'url(../../assets/chronical/5_en.png) center';
        } else if (box.field_position.value === '7') {
          return 'url(../../assets/chronical/7_en.png) center';
        } else if (box.field_position.value === '9') {
          return 'url(../../assets/chronical/9_en.png) center';
        } else if (box.field_position.value === '20') {
          return 'url(../../assets/chronical/20_en.png) center';
        } else return 'url(../../assets/chronical/' + box.field_position.value + '.png) center';
    } else return 'url(../../assets/chronical/' + box.field_position.value + '.png) center';
  }

  public PrevArticle() {
    this.currentId--;
    this.selectedBox = { paragraphs: [], videos: [], pictures: [] };
    this.LoadArticleById(this.currentId);
    this.contentContainer.nativeElement.scrollTop = 0;
  }

  public Back() {
    this.ProgressState(ChronikState.INTRODUCTION);
    this.store.dispatch(setStationStatus({ status: StationStatus.STANDBY }));
    this.store.dispatch(
      setHeaderStatus({ headerStatus: HeaderStatus.STANDBY })
    );
    this.selectedBox = { paragraphs: [], videos: [], pictures: [] };
  }

  public NextArticle() {
    this.currentId++;
    this.selectedBox = { paragraphs: [], videos: [], pictures: [] };
    this.LoadArticleById(this.currentId);
    this.contentContainer.nativeElement.scrollTop = 0;
  }

  public ChooseArticleFromMenu(id) {
    this.store.dispatch(setStationStatus({ status: StationStatus.MAIN }));
    this.store.dispatch(setHeaderStatus({ headerStatus: HeaderStatus.IN_USE }));
    this.LoadArticleById(id);
    this.ProgressState(ChronikState.DETAILPAGE);
  }

  public SetOrder(id) {
    const styles = {
      order: id,
    };
    return styles;
  }
}
