import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { StationBaseComponent } from '../../base/station-base/station-base.component';
import { Store } from '@ngrx/store';
import { AppStore } from '../../../core/store/appStore';
import { TranslateService } from '@ngx-translate/core';
import { QuizQuestionType } from '../quiz-question/quiz-question-type';

@Component({
  selector: 'app-quiz-progress',
  templateUrl: './quiz-progress.component.html',
  styleUrls: ['./quiz-progress.component.scss'],
})
export class QuizProgressComponent
  extends StationBaseComponent
  implements OnInit
{
  public questions;
  public currentQuestion;
  public QuestionTypes = QuizQuestionType;

  constructor(
    protected _store: Store<AppStore>,
    protected _translateService: TranslateService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {
    super(_store, _translateService);
  }

  ngOnInit(): void {}

  protected OnContentChange() {
    if (this.content && this.content.quiz && this.content.quiz.questions) {
      this.questions = this.content.quiz.questions;
    }

    if (this.content['dynamicStationContent']) {
      this.currentQuestion = this.content['dynamicStationContent']['question'];
    }
  }
}
