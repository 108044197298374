import {
  Component,
  HostBinding,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { setLanguage } from '../../store/content/content.actions';
import { StationBaseComponent } from '../../../stations/base/station-base/station-base.component';

@Component({
  selector: 'app-translation-switch',
  templateUrl: './translation-switch.component.html',
  styleUrls: ['./translation-switch.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TranslationSwitchComponent
  extends StationBaseComponent
  implements OnInit
{
  public languages = [
    {
      id: 'de',
      active: true,
    },
    {
      id: 'en',
      active: false,
    },
  ];

  @HostBinding('class.inverted') inverted = false;

  constructor(
    protected _translateService: TranslateService,
    protected _store: Store
  ) {
    super(_store, _translateService);
  }

  ngOnInit(): void {
    super.AddSubscription(
      this._translateService.onLangChange.subscribe((lang) => {
        this.SetCurrentLang(lang.lang);
      })
    );

    this.SetCurrentLang(this._translateService.currentLang);
  }

  /**
   * set the language-items to reflect the currently selected language
   * updates the store correspondingly
   * @param language: de | en
   */
  public SetCurrentLang(language): void {
    this.languages.map((lang) => (lang.active = lang.id === language));
    this._store.dispatch(setLanguage({ language }));
  }

  public SetLang(lang: string): void {
    this._translateService.use(lang);
  }

  public OnContentChange() {
    if (this.content && this.content.stationStatus == 'standby') {
      this.inverted = true;
    } else {
      this.inverted = false;
    }
  }
}
