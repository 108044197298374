<form #nameForm="ngForm" class="select-station-form">
  <app-input-text
    (change)="Change($event)"
    [alwaysShowKeyboard]="true"
    [autofocus]="true"
    [form]="nameForm"
    [initValue]=""
    [keyboardType]="'name'"
    [label]="'field_enter_your_name_text' | translate"
    [placeholder]=""
    [required]="false"
    [showKeyboard]="true"
    maxlength="30"
    name="highscoreName"
  ></app-input-text>

  <div
    (click)="Save()"
    *ngIf="formValid"
    @nextButtonInOut
    class="button button__handwriting"
  >
    {{ "field_submit_button_text" | translate }}
  </div>
</form>
