import {
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { StationBaseComponent } from '../base/station-base/station-base.component';
import { AppStore } from '../../core/store/appStore';
import { Store } from '@ngrx/store';
import { VideoEvents } from '../../core/ui/video-player/video-player.component';
import { StationStatus } from '../../core/store/definitions/station-states';
import { StandbyService } from '../../core/standby/standby.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-moving-images',
  templateUrl: './moving-images.component.html',
  styleUrls: ['./moving-images.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MovingImagesComponent
  extends StationBaseComponent
  implements OnInit
{
  public activeVideo = -1;
  public videoAction;
  public parsedContent = {};
  @HostBinding('class.single-video') singleVideo;

  constructor(
    protected _store: Store<AppStore>,
    protected _translateService: TranslateService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _standbyService: StandbyService
  ) {
    super(_store, _translateService);
  }

  ngOnInit(): void {}

  public SetVideo(video, index) {
    this.videoAction = VideoEvents.PLAY;
    this.activeVideo = index;
  }

  public DiscardVideo() {
    this.activeVideo = -1;
    this.videoAction = VideoEvents.STOP;
  }

  public VideoEvent($event) {
    switch ($event) {
      case VideoEvents.END:
        this.DiscardVideo();
        if (this.singleVideo) {
          this._standbyService.TriggerStandby();
        }
        break;
    }
  }

  protected ParseContent() {
    this.singleVideo = this.content.parsedContent.videos.length === 1;

    setTimeout(() => {
      if (
        this.content.stationStatus === StationStatus.MAIN &&
        this.singleVideo
      ) {
        this.SetVideo(this.content.parsedContent.videos[0], 0);
        this._changeDetectorRef.markForCheck();
      }
    }, 100);
  }
}
