import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Content } from './content.reducer';

export const GetContent = createFeatureSelector('content');

export const GetHeaderMode = () =>
  createSelector(GetContent, (content: Content) => {
    return content.standbyHeader.status;
  });

export const GetStationStatus = () =>
  createSelector(GetContent, (content: Content) => {
    return content.stationStatus;
  });

export const GetContentLoadStatus = () =>
  createSelector(GetContent, (content: Content) => {
    return content.loadStatus;
  });

export const GetGuestbook = () =>
  createSelector(GetContent, (content: Content) => {
    return content.guestbook;
  });
