
<div class="row">
  <div class="col-xsmall-8 col-xsmall-offset-2">
    <h2>{{ "PAGES.LOGGED_OUT.TITLE" | translate }}</h2>
    <p>{{ "PAGES.LOGGED_OUT.TEXT" | translate }}</p>
    <div class="button button-text" (click)="reload()">{{ "PAGES.LOGGED_OUT.BUTTON_RELOAD" | translate }}</div>
  </div>
</div>

<div class="button login" (click)="keyCloakLogin()">{{ "PAGES.LOGGED_OUT.BUTTON" | translate }}</div>

<app-alert-view></app-alert-view>
