import { createAction, props } from '@ngrx/store';

export const enum AlertsActions {
  ALERT_ERROR = 'ALERT_E',
  ALERT_INFO = 'ALERT_I',
  ALERT_SUCCESS = 'ALERT_S',
  ALERT_UPDATE = 'ALERT_U',
  ALERT_CLEAR = 'CLEAR',
}

export enum ALERT_TYPES {
  ALERT = 'Alert',
  INFO = 'Info',
  UPDATE = 'Update',
  SUCCESS = 'Success',
}

export const alertClear = createAction(AlertsActions.ALERT_CLEAR);

export const alertError = createAction(
  AlertsActions.ALERT_ERROR,
  props<{
    title: string;
    message: string;
    duration: number;
  }>()
);

export const alertInfo = createAction(
  AlertsActions.ALERT_INFO,
  props<{
    title: string;
    message: string;
    duration: number;
  }>()
);

export const alertSuccess = createAction(
  AlertsActions.ALERT_SUCCESS,
  props<{
    title: string;
    message: string;
    duration: number;
  }>()
);

export const alertUpdate = createAction(
  AlertsActions.ALERT_UPDATE,
  props<{
    versionOld: string;
    versionNew: string;
  }>()
);
