import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MODAL_ACTIONS, ModalAction } from './modal-action';
import { SubscriptionsHelperComponent } from '../../../helper/subscriptions-helper/subscriptions-helper.component';

@Component({
  selector: 'app-base-modal',
  template: '',
  styleUrls: [],
})
export class BaseModalComponent
  extends SubscriptionsHelperComponent
  implements OnInit
{
  @HostBinding('class.modal') modal = true;
  @Input() @HostBinding('class.modal--open') open = false;
  @Output() openChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  public MODAL_ACTIONS = MODAL_ACTIONS;

  @Output() action: EventEmitter<ModalAction> = new EventEmitter<ModalAction>();

  constructor() {
    super();
  }

  ngOnInit(): void {}

  public Close() {
    this.open = false;
    this.openChange.emit(this.open);
  }

  public Action(action: ModalAction) {
    this.action.emit(action);
  }
}
