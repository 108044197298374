export function SplitAtBR(string): string[] {
  const brExp = /<br\s*\/?>/i;
  return string ? string.split(brExp) : [];
}

export function enumKeys<O extends object, K extends keyof O = keyof O>(
  obj: O
): K[] {
  return Object.keys(obj).filter((k) => Number.isNaN(+k)) as K[];
}
