<app-standby-header
  [mode]="headerMode"
  [smallTitles]="{
    de: content.parsedContent?.shortTitle,
    en: content.parsedContent_en?.shortTitle
  }"
  [standbyImage]="{
    de: content.parsedContent?.standbyImage,
    en: content.parsedContent_en?.standbyImage
  }"
  [titles]="{
    de: content.parsedContent?.standbyTitle,
    en: content.parsedContent_en?.standbyTitle
  }"
>
</app-standby-header>

<!--suppress AngularUndefinedBinding -->
<div
  (config-click-event)="GotoConfig($event)"
  (config-click-event-too-many)="DoNothing($event)"
  class="config-activation-area"
></div>

<!--<app-loading></app-loading>-->

<router-outlet></router-outlet>

<app-config-pin-modal [(open)]="openConfigPinModal"></app-config-pin-modal>

<div
  (click)="StopStandby()"
  *ngIf="
    (content.stationStatus === StationStati.STANDBY ||
      content.stationStatus === StationStati.INIT) &&
    config.station_type !== 'chronicle'
  "
  class="standby-overlay"
></div>

<app-alert-view></app-alert-view>
