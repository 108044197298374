import { Injectable } from '@angular/core';
import {
  CoreConfig,
  coreConfigDevelopment,
  coreConfigProduction,
  coreConfigTesting,
} from './core.config';
import { environment } from '../../../environments/environment';
import { GetUuid } from '../helper/uuid';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  public clientUUID;

  constructor() {
    this._config = this.ReturnRuntimeConfig(
      coreConfigDevelopment,
      coreConfigTesting,
      coreConfigProduction
    );

    this.clientUUID = localStorage.getItem(
      this._config.storage.localStoragePrefix + 'config-uuid'
    );
    if (!this.clientUUID) {
      this.clientUUID = GetUuid();
      localStorage.setItem(
        this._config.storage.localStoragePrefix + 'config-uuid',
        this.clientUUID
      );
    }
  }

  private _config: CoreConfig;

  get config(): CoreConfig {
    return this._config;
  }

  set config(value: CoreConfig) {
    this._config = value;
  }

  public ReturnRuntimeConfig(
    developmentConfig: any,
    testingConfig: any,
    productionConfig: any
  ) {
    switch (environment.deployment) {
      case 'production':
        return productionConfig;
      case 'testing':
        return testingConfig;
      default:
        return developmentConfig;
    }
  }
}
