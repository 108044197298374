import { Component, OnInit } from '@angular/core';
import { ApiConnectorStationsService } from '../../core/io/stations/api-connector-stations.service';
import { take, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { setStation } from '../../core/store/config/config.actions';
import { ConfigService } from '../../core/config/config.service';
import { CoreConfig } from '../../core/config/core.config';
import { Router } from '@angular/router';
import {
  GetClientConfig,
  GetConfigStatus,
} from '../../core/store/config/config.selector';
import { LoadStatus } from '../../core/store/definitions/load-states';
import { Subject } from 'rxjs';
import { StationTypes } from '../../core/store/definitions/stationTypes';

interface Station {
  id: number;
  title: string;
}

interface SubItem {
  id: number;
  title: string;
}

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss'],
})
export class ConfigComponent implements OnInit {
  content;
  public stations: Station[] = [];
  public subItems: SubItem[] = [];
  public client_name: string;
  public standbyTime: number = 30;
  public initialConfig: any;
  public clientId: string;
  public tmpStationId: StationTypes;
  public tmpSubItemId: number;
  public loading = false;
  public subItemsStatus = 'init';
  public saveable = false;
  public saveStatus: LoadStatus = LoadStatus.init;
  public firstLoad = false;
  private _killConfigStatusListener$: Subject<any> = new Subject();
  private _config: CoreConfig;

  constructor(
    private _apiConntectorStations: ApiConnectorStationsService,
    private _store: Store,
    private _configService: ConfigService,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this._config = this._configService.config;

    this._store.select(GetClientConfig()).subscribe((conf) => {
      this.clientId = conf.clientId
        ? conf.clientId
        : this._configService.clientUUID;
      if (conf.station_type) {
        this.initialConfig = conf;
      } else {
        console.warn('configuration not found');
        this.firstLoad = true;
      }
    });

    this._apiConntectorStations
      .GetAvailableStations()
      .subscribe((stationsObj) => {
        const stations = Object.keys(stationsObj).map((key) => {
          return stationsObj[key];
        });

        if (stations.length > 0) {
          stations.map(
            (station) => (
              (station.checkedState = false),
              (station.text = station.label),
              (station.type = station.id)
            )
          );

          // load current config if available
          this._store
            .select(GetClientConfig())
            .pipe(take(1))
            .subscribe((config) => {
              this.initialConfig = config;

              if (config.client_name) {
                this.client_name = config.client_name;
              }
              if (config.timeout) {
                this.standbyTime = config.timeout;
              }

              // set local
              if (config.station_type) {
                this.tmpStationId = config.station_type;
                stations.map(
                  (station) => (
                    (station.checkedState = station.id === config.station_type),
                    (station.text = station.label)
                  )
                );
              }

              this.stations = stations.length > 0 ? stations : [];

              if (config.station_type && config.station_id) {
                this.tmpSubItemId = config.station_id;

                this._apiConntectorStations
                  .GetAvailableSubItems(config.station_type)
                  .subscribe((subItemRequest) => {
                    const subItems = subItemRequest.rows
                      ? subItemRequest.rows
                      : [];

                    subItems.map(
                      (subItem) => (
                        (subItem.checkedState =
                          subItem.id == config.station_id),
                        (subItem.text = subItem.title)
                      )
                    );
                    this.subItems = subItems;
                    this.subItemsStatus = 'loaded';

                    this.loading = false;
                  });
              } else {
                this.loading = false;
              }
            });
        }
      });
  }

  public Reload() {
    window.location.href = '/';
  }

  public TemporarilySaveStation(station) {
    this.tmpStationId = station;
    this.tmpSubItemId = undefined;
    this.subItems = [];
    this.subItemsStatus = 'loading';
    this.loading = true;

    this._apiConntectorStations
      .GetAvailableSubItems(station)
      .subscribe((subItems) => {
        if (subItems.rows.length > 0) {
          subItems.rows.map(
            (subItem) => (
              (subItem.checkedState = false), (subItem.text = subItem.title)
            )
          );
        }
        this.subItems = subItems.rows.length > 0 ? subItems.rows : [];
        this.subItemsStatus = 'loaded';
        this.loading = false;
      });
  }

  public TemporarilySaveSubItem(subItem) {
    this.tmpSubItemId = subItem;
  }

  public SetStation(form): void {
    let client_name;
    let standbyTime;
    try {
      client_name = form.form.value.data.clientName
        ? form.form.value.data.clientName
        : undefined;
      standbyTime = form.form.value.data.standbyTime
        ? form.form.value.data.standbyTime
        : undefined;
    } catch (e) {}
    if (client_name === undefined) {
      client_name = this.client_name;
    }
    if (standbyTime === undefined) {
      standbyTime = this.standbyTime;
    }

    this._store.dispatch(
      setStation({
        clientId: this.clientId,
        station: {
          station_type: this.tmpStationId,
          station_id: this.tmpSubItemId ? this.tmpSubItemId : undefined,
          client_name: client_name,
          timeout: standbyTime,
        },
      })
    );

    this._store
      .select(GetConfigStatus('store'))
      .pipe(takeUntil(this._killConfigStatusListener$))
      .subscribe((configStatus) => {
        if (
          configStatus === LoadStatus.loaded ||
          configStatus === LoadStatus.error
        ) {
          this._killConfigStatusListener$.next();
        }
        this.saveStatus = configStatus;

        if (configStatus === LoadStatus.loaded) {
          setTimeout(() => (window.location.href = '/'), 500);
        }
      });

    try {
      localStorage.setItem(
        this._config.storage.localStoragePrefix + 'config.station',
        JSON.stringify({
          station: this.tmpStationId,
          subType: this.tmpSubItemId ? this.tmpSubItemId : undefined,
          client_name,
          standbyTime,
        })
      );
    } catch (e) {
      throw Error();
    }
  }

  public isSaveable(): boolean {
    return this.tmpStationId !== undefined && this.subItems.length > 0;
  }

  public BackToStation(): void {
    this._router.navigateByUrl('/check-config');
  }
}
