<app-translation-switch></app-translation-switch>

<div *ngIf="content.stationStatus === StationStati.STANDBY">
  <div class="cta-multiple">
    <div class="cta-multiple__play"></div>

    <div class="cta-multiple__text cta-multiple__text--bottom">
      {{ "STANDBY.TOUCH_TO_START_DE" | translate }}<br />
      {{ "STANDBY.TOUCH_TO_START_EN" | translate }}<br />
    </div>
  </div>
  <div class="info-text">
    {{ "field_helper_text_on_stand_by" | translate }}
  </div>
</div>

<!-- helper info text -->
<div
  class="info-text info-text__after-standby"
  *ngIf="status === dreamElevenStates.CHOOSE_PLAYERS"
>
  {{ "field_helper_text_after_stand_by" | translate }}
</div>

<ng-container>
  <div class="row field-wrapper">
    <div class="col-xsmall-12">
      <div class="field">
        <div
          class="bg"
          *ngIf="
            currentPositionEdit ||
            status === dreamElevenStates.SET_NAME ||
            status === dreamElevenStates.SET_EMAIL ||
            status === dreamElevenStates.EMAIL_ERROR ||
            status === dreamElevenStates.EMAIL_LOADING ||
            status === dreamElevenStates.EMAIL_SUCCESS ||
            (status === dreamElevenStates.SELECT_FORMATION &&
              content.stationStatus === 'main')
          "
        ></div>

        <app-dream-eleven-choose-formation
          *ngIf="status == dreamElevenStates.SELECT_FORMATION"
          class="formation-chooser"
          [open]="content.stationStatus === 'main'"
        >
          <h1 class="formation-chooser__header">
            {{ "field_field_formation_text" | translate }}
          </h1>
          <div class="formation-chooser__container">
            <div
              (click)="SetFormation(formation)"
              *ngFor="let formation of formations"
              class="formation-chooser__item"
            >
              <div class="formation-chooser__text">{{ formation.name }}</div>
              <img
                [src]="formation.imgUrl"
                [alt]="formation.name"
                height="150"
              />
            </div>
          </div>
        </app-dream-eleven-choose-formation>

        <!-- Choose Players -->
        <app-dream-eleven-position-marker
          (click)="ChooseForPosition(position)"
          *ngFor="let position of formation.positions"
          [position]="position"
          [style]="{
            left: position.x + '%',
            top: position.y + '%'
          }"
          [name]="selectedPlayers[position.id]?.last_name"
          [ngClass]="{
            active: selectedPlayers[position.id]?.last_name
          }"
        ></app-dream-eleven-position-marker>

        <!--Choose Player overlay -->
        <app-dream-eleven-choose-player
          *ngIf="currentPositionEdit"
          [open]="currentPositionEdit"
          [position]="currentPositionEdit"
          (openChange)="Close($event)"
          (playerSelect)="PlayerSelect($event)"
          [selectedPlayerList]="selectedPlayers"
        ></app-dream-eleven-choose-player>

        <div
          class="email-success"
          *ngIf="status === dreamElevenStates.EMAIL_SUCCESS"
        >
          <div class="email-success__icon"></div>
          <h1 class="email-success__header">
            {{ "field_email_helper_success_text" | translate }}
          </h1>
          <div
            class="button button__handwriting back-button"
            (click)="BackToFormationChooser()"
          >
            {{ "DREAM_ELEVEN.set_players_again" | translate }}
          </div>
        </div>

        <div
          class="email-success"
          *ngIf="status === dreamElevenStates.EMAIL_LOADING"
        >
          <app-loading></app-loading>
        </div>

        <div
          class="email-success"
          *ngIf="status === dreamElevenStates.EMAIL_ERROR"
        >
          <h1 class="email-success__header">{{ errorMsg }}</h1>
        </div>

        <!-- button container -->
        <div class="side-button-container">
          <div
            *ngIf="status === dreamElevenStates.CHOOSE_PLAYERS"
            class="button button__handwriting set-players"
            (click)="SetPlayers()"
            [ngClass]="{
              disabled: setPositions !== 12 || currentPositionEdit
            }"
          >
            {{ "field_submit_dream_eleven_button" | translate }}
          </div>
          <div
            *ngIf="status === dreamElevenStates.FINISH"
            class="button button__handwriting email-button"
            (click)="TypeEmail()"
          >
            {{ "field_send_button_text" | translate }}
          </div>
          <div
            *ngIf="status === dreamElevenStates.FINISH"
            class="button button__handwriting swap-button"
            (click)="ToPositionChooser()"
          >
            {{ "field_edit_dream_eleven_button_t" | translate }}
          </div>
          <div
            *ngIf="
              status === dreamElevenStates.CHOOSE_PLAYERS ||
              status === dreamElevenStates.FINISH
            "
            class="button button__handwriting back-button"
            (click)="BackToFormationChooser()"
            [innerHTML]="'field_start_again_button_text' | translate"
          ></div>
        </div>

        <app-input-name
          *ngIf="status === dreamElevenStates.SET_NAME"
          [name]="userName"
          (formData)="SetName($event)"
          (closeModal)="OnCloseModalName()"
          class="on-black"
        >
        </app-input-name>

        <app-input-email
          *ngIf="status === dreamElevenStates.SET_EMAIL"
          (formData)="SetEmail($event)"
          (closeModal)="OnCloseModalEmail()"
          class="on-black"
        >
        </app-input-email>

        <div
          *ngIf="userName && status === dreamElevenStates.FINISH"
          class="small-titles"
          id="headerBar"
        >
          <div class="small-title-container">
            <span class="small-title"
              >{{ userName + "'s " }} {{ "title" | translate }}</span
            >
          </div>
        </div>

        <img
          [src]="canvasImage"
          *ngIf="
            (canvasImage && status === dreamElevenStates.FINISH) ||
            status === dreamElevenStates.SET_EMAIL ||
            status === dreamElevenStates.EMAIL_ERROR ||
            status === dreamElevenStates.EMAIL_LOADING ||
            status === dreamElevenStates.EMAIL_SUCCESS
          "
          class="canvas-image"
        />

        <div class="field__img aspect-content"></div>
        <div class="field__background"></div>
      </div>
    </div>
  </div>
</ng-container>

<div style="display: none">
  <canvas id="canvas"></canvas>
  <img src="../../../assets/img/field_print_new.jpg" id="field_print" />
  <img
    src="../../../assets/img/field_player_print.png"
    id="field_player_print"
  />
  <img src="../../../assets/img/field_coach_print.png" id="field_coach_print" />
  <img src="../../../assets/ui/name-header-canvas.svg" id="header_name" />
  <img
    src="../../../assets/ui/borusseum-header-canvas.svg"
    id="header_borusseum"
  />
</div>
