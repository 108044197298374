import {
  Component,
  HostBinding,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { BaseModalComponent } from '../base-modal/base-modal.component';
import { NavigationService } from '../../../navigation/navigation.service';
import { Router } from '@angular/router';
import { KeyboardComponent } from '../../keyboard/keyboard.component';
import { pinModalAnimation } from './config-pin-modal.animations';

@Component({
  selector: 'app-config-pin-modal',
  templateUrl: './config-pin-modal.component.html',
  styleUrls: ['./config-pin-modal.component.scss'],
  animations: [pinModalAnimation],
})
export class ConfigPinModalComponent
  extends BaseModalComponent
  implements OnInit
{
  public pin = '557419';
  public inputPin = '';
  @HostBinding('class.modal--center') modal = true;
  public declined = '';
  public locked = false;
  public numOfEnteredPins = 0;
  public initPin = '';
  @ViewChild(KeyboardComponent) keyboard: KeyboardComponent;

  constructor(
    private _navigationService: NavigationService,
    private _router: Router
  ) {
    super();
  }

  @HostListener('document:keydown', ['$event.key']) onKeydown(char): void {
    this.CheckKeyboardPin(char);
  }

  ngOnInit(): void {}

  public CheckKeyboardPin(char) {
    if (
      char === '1' ||
      char === '2' ||
      char === '3' ||
      char === '4' ||
      char === '5' ||
      char === '6' ||
      char === '7' ||
      char === '8' ||
      char === '9' ||
      char === '0'
    ) {
      this.inputPin = this.inputPin + char;
      this.initPin = this.inputPin;
      this.CheckPin(this.inputPin);
    }
    if (char === 'Escape') {
      this.keyboard.handleClear();
      setTimeout(() => this.Close(), 10);
    }
  }

  public CheckPin(pin) {
    this.inputPin = pin;
    this.numOfEnteredPins = pin.length;

    if (pin.length >= this.pin.length) {
      if (pin === this.pin) {
        setTimeout(() => {
          this._router.navigateByUrl('/client/config');
        }, 200);
      } else {
        this.locked = true;
        this.declined = 'declined';
        setTimeout(() => {
          this.keyboard.handleClear();
          this.numOfEnteredPins = 0;
        }, 200);
      }
    }
  }

  public ResetDeclinedAnimation() {
    this.locked = false;
    this.declined = '';
  }
}
