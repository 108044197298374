import { ApiConnectorBackendSaveService } from './../../core/io/content/api-connector-backend-save.service';
import { transition } from '@angular/animations';
import { Content } from './../../core/store/content/content.reducer';
import {
  addGuestbookPage,
  fetchGuestbookPages,
  setGuestbookPage,
} from './../../core/store/content/content.actions';
import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { StationBaseComponent } from '../base/station-base/station-base.component';
import {
  GetContent,
  GetGuestbook,
} from 'src/app/core/store/content/content.selector';
import { Subject } from 'rxjs/internal/Subject';
import { AlertService } from 'src/app/core/alerts/alert.service';

export interface Page {
  url: string;
  pageNr: number;
}

export interface DoublePage {
  pageOne: Page;
  pageTwo: Page;
  index: number;
}

export enum GuestbookState {
  INTRODUCTION,
  BOOK_SCROLLING,
  PRE_PICTURE,
  TAKE_PICTURE,
  POST_PICTURE,
  DRAWING,
  POST_DRAWING,
}

@Component({
  selector: 'app-guestbook',
  templateUrl: './guestbook.component.html',
  styleUrls: ['./guestbook.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GuestbookComponent extends StationBaseComponent implements OnInit {
  constructor(
    protected _store: Store,
    protected _translateService: TranslateService,
    private changeDetector: ChangeDetectorRef,
    private _apiBackendSaveService: ApiConnectorBackendSaveService,
    private _alertService: AlertService
  ) {
    super(_store, _translateService);
  }

  status: GuestbookState = GuestbookState.INTRODUCTION;

  @ViewChild('canvasWithTape') public canvasWithTape: ElementRef;
  canvasEl: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D;

  guestbookStates = GuestbookState;

  changingPage: boolean = false;

  onSave: Subject<void> = new Subject<void>();
  onError: Subject<void> = new Subject<void>();

  currentPage: DoublePage;
  nextPage: DoublePage;
  prevPage: DoublePage;

  public imageLink: string;

  public imageLinkWithTape: string;

  public savedCanvasImg: string;
  public canvasText: string;

  imageRotationRandom = Math.random() > 0.5 ? 'left' : 'right';

  loadedPages: DoublePage[];

  ngOnInit(): void {
    this._store.dispatch(fetchGuestbookPages());

    this.AddSubscription(
      this._store.select(GetGuestbook()).subscribe((guestbook: any) => {
        let waitingForNextPages: boolean = false;

        if (guestbook.status === 'loading') {
          waitingForNextPages = true;
        }
        if (guestbook.status === 'loaded') {
          waitingForNextPages = false;
        }

        if (
          (guestbook.pages &&
            this.loadedPages?.length !== guestbook.pages.length) ||
          (guestbook.pages &&
            guestbook.pageCount !== guestbook.realPageCount) ||
          (guestbook.pages && guestbook.pageCount === 0) ||
          (guestbook.pages &&
            guestbook.pages[guestbook.realPageCount].pageTwo.url?.includes(
              'base64'
            ))
        ) {
          this.loadedPages = guestbook.pages;
        }

        if (guestbook.pages) {
          this.currentPage = this.loadedPages[guestbook.currPage];

          if (this.loadedPages[guestbook.currPage - 1]) {
            this.prevPage = this.loadedPages[guestbook.currPage - 1];
          }

          if (this.loadedPages[guestbook.currPage + 1]) {
            this.nextPage = this.loadedPages[guestbook.currPage + 1];
          }

          if (
            !this.loadedPages[guestbook.currPage - 1] &&
            this.loadedPages[guestbook.currPage].index > 0
          ) {
            this.prevPage = {
              pageOne: {
                url: undefined,
                pageNr: this.loadedPages[guestbook.currPage].pageOne.pageNr - 2,
              },
              pageTwo: {
                url: undefined,
                pageNr: this.loadedPages[guestbook.currPage].pageOne.pageNr - 1,
              },
              index: this.loadedPages[guestbook.currPage].index - 1,
            };
          }

          if (
            !this.loadedPages[guestbook.currPage + 1] &&
            this.loadedPages[guestbook.currPage].index <
              this.loadedPages.length - 1
          ) {
            this.nextPage = {
              pageOne: {
                url: undefined,
                pageNr: this.loadedPages[guestbook.currPage].pageOne.pageNr + 2,
              },
              pageTwo: {
                url: undefined,
                pageNr: this.loadedPages[guestbook.currPage].pageOne.pageNr + 1,
              },
              index: this.loadedPages[guestbook.currPage].index + 1,
            };
          }
        }

        // placed new images as background images
        this.changingPage = false;
        this.changeDetector.markForCheck();
      })
    );
  }

  onSwipe(event) {
    if (
      event.additionalEvent === 'panleft' &&
      this.nextPage?.index !== this.currentPage.index &&
      this.nextPage
    ) {
      this.ChangePage(1);
    } else if (
      event.additionalEvent === 'panright' &&
      this.prevPage?.index !== this.currentPage.index &&
      this.prevPage
    ) {
      this.ChangePage(-1);
    }
  }

  public ProgressState(toState) {
    this.status = toState;
  }

  public OnShowPicture(imageLink) {
    this.imageRotationRandom = Math.random() > 0.5 ? 'left' : 'right';
    this.imageLink = imageLink;
    this.AddTape();

    this.ProgressState(GuestbookState.POST_PICTURE);
  }

  public OnStopCountdown() {
    this.ProgressState(GuestbookState.PRE_PICTURE);
  }

  ToPrePictureState() {
    this.ProgressState(GuestbookState.PRE_PICTURE);
  }

  ToScrollState() {
    this.ProgressState(GuestbookState.BOOK_SCROLLING);
  }

  ToTakePictureState() {
    this.ProgressState(GuestbookState.TAKE_PICTURE);
  }

  ToDrawState() {
    this.ProgressState(GuestbookState.DRAWING);
  }

  SaveToCanvas() {
    this.onSave.next();
    this._apiBackendSaveService
      .SaveDigitalGuestbookPage({
        image: this.savedCanvasImg,
        text: this.canvasText,
      })
      .subscribe(
        (result) => {
          this.ProgressState(GuestbookState.POST_DRAWING);
          this._store.dispatch(
            addGuestbookPage({ payload: this.savedCanvasImg })
          );
          this.imageLink = undefined;
          setTimeout(() => {
            this.ProgressState(GuestbookState.BOOK_SCROLLING);
            this.changeDetector.markForCheck();
          }, 2000);
        },
        (error) => {
          this.onError.next();
          this._alertService.DispatchAlert(
            error.error.search('blacklist') !== -1
              ? 'ERROR.BLACKLIST'
              : 'ERROR.ERROR_TITLE_SAVE'
          );
          console.log(error.statusText);
        }
      );
  }

  OnCanvasSaved(params) {
    this.savedCanvasImg = params.canvasImg;
    this.canvasText = params.text;
  }

  AddTape() {
    this.canvasEl = this.canvasWithTape.nativeElement;
    this.ctx = this.canvasEl.getContext('2d');

    setTimeout(() => {
      const image = document.getElementById('image') as HTMLImageElement;
      const tapeImage = document.getElementById(
        'tapeImage'
      ) as HTMLImageElement;

      const aspectRatio = image.height / image.width;
      this.ctx.canvas.width = 300 + 70;
      this.ctx.canvas.height = 300 * aspectRatio + 70;

      this.ctx.fillStyle = 'transparent';
      this.ctx.fillRect(0, 0, 300 + 70, 300 * aspectRatio + 70);

      this.ctx.drawImage(image, 35, 35, 300, 300 * aspectRatio);

      this.ctx.drawImage(tapeImage, -30, -30, 140, 140);
      this.ctx.drawImage(tapeImage, 300 - 40, 300 * aspectRatio - 40, 140, 140);

      this.imageLink = this.canvasWithTape.nativeElement.toDataURL('image/png');

      this.changeDetector.markForCheck();
    }, 0);
  }

  //Page Changing CSS animation
  ChangePage(direction: number) {
    if (this.changingPage) {
      return;
    }
    this.changingPage = true;

    if (document.getElementById('b'))
      document.getElementById('b').style.transition = 'all 0.5s';
    document.getElementById('c').style.transition = 'all 0.5s';
    document.getElementById('d').style.transition = 'all 0.5s';
    document.getElementById('e').style.transition = 'all 0.5s';

    if (direction > 0) {
      document.getElementById('d').style.transform = 'rotateY(-180deg)';
      document.getElementById('d').style.zIndex = '2';

      document.getElementById('e').style.transform = 'rotateY(0deg)';
      document.getElementById('e').style.zIndex = '200';

      document.getElementById('c').style.zIndex = '2';
    }
    if (direction < 0) {
      document.getElementById('b').style.transform = 'rotateY(0deg)';
      document.getElementById('b').style.zIndex = '200';

      document.getElementById('c').style.transform = 'rotateY(180deg)';
      document.getElementById('c').style.zIndex = '2';

      document.getElementById('d').style.zIndex = '2';
    }

    setTimeout(() => {
      this.ResetPages(direction);
    }, 500);
  }
  ResetPages(direction) {
    if (document.getElementById('b')) {
      document.getElementById('b').style.transition = 'none';
      document.getElementById('b').style.transform = 'rotateY(-180deg)';
      document.getElementById('b').style.zIndex = '2';
    }
    document.getElementById('c').style.transition = 'none';
    document.getElementById('d').style.transition = 'none';
    document.getElementById('e').style.transition = 'none';

    document.getElementById('c').style.transform = 'rotateY(0deg)';
    document.getElementById('c').style.zIndex = '200';

    document.getElementById('d').style.transform = 'rotateY(0deg)';
    document.getElementById('d').style.zIndex = '200';

    document.getElementById('e').style.transform = 'rotateY(180deg)';
    document.getElementById('e').style.zIndex = '2';

    this._store.dispatch(setGuestbookPage({ payload: direction }));
  }
}
