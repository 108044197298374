import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ClientConfig } from '../store/config/config.reducer';
import { GetClientConfig } from '../store/config/config.selector';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoadStatus } from '../store/definitions/load-states';
import { Router } from '@angular/router';
import { GetContent } from '../store/content/content.selector';
import { Content } from '../store/content/content.reducer';
import { StationStatus } from '../store/definitions/station-states';

@Injectable({
  providedIn: 'root',
})
export class StandbyService {
  private _standbyTimer: number;
  private _config: ClientConfig;
  private _standbyTime: number = 5;
  // private document = document;
  private _lock = false;
  private _stationStatus;

  private _killConfigSelector$ = new Subject();

  constructor(private _store: Store, private _router: Router) {
    this._store
      .select(GetClientConfig())
      .pipe(takeUntil(this._killConfigSelector$))
      .subscribe((config) => {
        if (config.statuses.store === LoadStatus.loaded) {
          this._config = config;
          this._standbyTime = config.timeout;
          this.AddListeners();
          this._killConfigSelector$.next();
        }
      });

    this._store.select(GetContent).subscribe((content: Content) => {
      this._stationStatus = content.stationStatus;
    });
  }

  public Init() {}

  public AddListeners(): void {
    window.addEventListener(
      'touchstart',
      () => {
        this.RestartStandbyTimer(this._standbyTime);
      },
      false
    );

    window.addEventListener(
      'click',
      () => {
        this.RestartStandbyTimer(this._standbyTime);
      },
      false
    );
  }

  public RestartStandbyTimer(time = this._standbyTime): void {
    if (this._lock) return;
    if (this._standbyTimer) clearTimeout(this._standbyTimer);

    this._standbyTimer = setTimeout(() => {
      this.TriggerStandby();
    }, time * 1000);
  }

  public TriggerStandby() {
    if (this._stationStatus === StationStatus.STANDBY) return;



    this._router
      .navigateByUrl('/refresh', { skipLocationChange: true })
      .then(() => {
        this._router.navigateByUrl('/');
      });
  }

  public UnlockTimer() {
    console.log('unlock');
    this._lock = false;
  }

  public StopStandByTimer(lock = false) {
    this._lock = lock;
    clearTimeout(this._standbyTimer);
  }
}
