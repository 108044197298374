import { Component, OnInit } from '@angular/core';
import { BaseModalComponent } from '../base-modal/base-modal.component';

@Component({
  selector: 'app-dream-eleven-choose-formation',
  templateUrl: './dream-eleven-choose-formation.component.html',
  styleUrls: ['./dream-eleven-choose-formation.component.scss'],
})
export class DreamElevenChooseFormationComponent
  extends BaseModalComponent
  implements OnInit
{
  constructor() {
    super();
  }

  ngOnInit(): void {}
}
