import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-input-validation-error',
  templateUrl: './input-validation-error.component.html',
  styleUrls: ['./input-validation-error.component.scss'],
})
export class InputValidationErrorComponent implements OnInit, OnChanges {
  @Input() errorMsgs: string[] = [];
  public showError: boolean = false;

  constructor() {}

  ngOnInit() {
    this.showError = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['errorMsgs']) {
      this.CheckForErrors();
    }
  }

  CheckForErrors() {
    this.showError = this.errorMsgs.length > 0;
  }

  CloseErrorMsg() {
    if (this.showError) {
      this.showError = false;
    }
  }
}
