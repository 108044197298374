import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { GetContent } from '../../store/content/content.selector';
import {
  HeaderStatus,
  HeaderVariation,
} from '../../store/definitions/header-states';
import { SubscriptionsHelperComponent } from '../../helper/subscriptions-helper/subscriptions-helper.component';
import { Content } from '../../store/content/content.reducer';
import { GetConfig } from '../../store/config/config.selector';
import { ClientConfig } from '../../store/config/config.reducer';
import { StationTypes } from '../../store/definitions/stationTypes';
import { animate, style, transition, trigger } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-standby-header',
  templateUrl: './standby-header.component.html',
  styleUrls: ['./standby-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('standbyImageInOut', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(200px)' }),
        animate(
          '250ms cubic-bezier(0.4, 0.0, 0.2, 1)',
          style({ opacity: 1, transform: 'translateY(0px)' })
        ),
      ]),
      transition(':leave', [
        animate(
          '250ms cubic-bezier(0.4, 0.0, 0.2, 1)',
          style({ opacity: 0, transform: 'translateY(200px)' })
        ),
      ]),
    ]),
  ],
})
export class StandbyHeaderComponent
  extends SubscriptionsHelperComponent
  implements OnInit, OnChanges
{
  @Input() titles: { de: Array<string>; en: Array<string> } = {
    de: [],
    en: [],
  }; // each line on entry
  public titlesArray: string[];
  @Input() smallTitles: { de: string; en: string } = { de: '', en: '' }; // each line on entry
  @Input() standbyImage: { de: string; en: string } = { de: '', en: '' };
  @Input() mode: HeaderStatus = HeaderStatus.STANDBY;
  public standbyHeaderStati = HeaderStatus;
  public currentLang;

  public content: Content;
  public variation: HeaderVariation = HeaderVariation.DEFAULT;

  public config;

  constructor(
    private _store: Store,
    private _translationService: TranslateService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {
    super();

    this.currentLang = this._translationService.currentLang;
    this._translationService.onLangChange.subscribe((lang) => {
      this.currentLang = lang.lang;
      this._changeDetectorRef.markForCheck();
    });
  }

  ngOnInit(): void {
    this.AddSubscription(
      this._store.select(GetContent).subscribe((content: Content) => {
        this.content = content;

        this.mode = content.standbyHeader.status;
      })
    );

    this.AddSubscription(
      this._store.select(GetConfig).subscribe((config: ClientConfig) => {
        this.config = config;
        switch (config.station_type) {
          case StationTypes.PROTANGONISTS_VIDEOS:
            this.variation = HeaderVariation.LARGE;
            break;
          case StationTypes.MOVING_IMAGES:
          case StationTypes.QUIZ:
            this.variation = HeaderVariation.XLARGE;
            break;
        }
      })
    );
  }

  ngOnChanges(changes: SimpleChanges) {}

  public GotoConfig(event) {}
}
