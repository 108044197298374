import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { InputValidationErrorBaseComponent } from '../input-validation-error-base/input-validation-error-base.component';

@Component({
  selector: 'app-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class InputNumberComponent
  extends InputValidationErrorBaseComponent
  implements OnInit, OnChanges, AfterViewInit
{
  @Input() id: string;
  @Input() name: string;
  @Input() label: string;
  @Input() initValue: number;
  @Input() placeholder: string;
  @Input() required: boolean;
  @Input() pattern: string;
  @Input() form: any;
  @Input() disabled: boolean = false;
  @Input() ngGroupName: string = 'data';
  @Input() patternNotValidError: string = 'UI.INPUT.ERRORS.VALID';
  @Input() numberNotInRangeError: string = 'UI.INPUT.ERRORS.NUM_NOT_IN_RANGE';

  @Input() breakpoint: string = 'small';

  @Input() min: number;
  @Input() max: number;
  @Input() toggle: boolean = false;

  constructor(private _changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.toggle && !this.initValue) {
      this.initValue = 0;
      this._changeDetectorRef.markForCheck();
    }
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.toggle && !this.initValue) {
      this.initValue = 0;
    }
  }

  ngAfterViewInit(): void {
    if (this.toggle && !this.initValue) {
      this.initValue = 0;
      this._changeDetectorRef.markForCheck();
    }
  }

  Validate(val = '', first = true) {
    this.ResetErrors();
    if (val === '') {
      val = this.form.value[this.ngGroupName][this.name];
    }
    if (this.disabled) {
      return;
    }
    if (this.required && val === '') {
      this.errorMsgs.push(this.emptyError);
    } else if (this.pattern) {
      // pattern
      let patt = new RegExp(this.pattern.toString());
      if (!patt.test(val)) {
        this.errorMsgs.push(this.patternNotValidError);
      }
    } else {
      if (this.min && this.min > parseInt(val, 10)) {
        this.errorMsgs.push(this.numberNotInRangeError);
      }
      if (this.max && this.max < parseInt(val, 10)) {
        this.errorMsgs.push(this.numberNotInRangeError);
      }
    }
  }

  Plus() {
    this.initValue++;
    if (this.max && this.initValue > this.max) {
      this.initValue = this.max;
    }

    this._changeDetectorRef.markForCheck();
  }

  Minus() {
    this.initValue--;
    if (!isNaN(this.min) && this.initValue < this.min) {
      this.initValue = this.min;
    }
    this._changeDetectorRef.markForCheck();
  }
}
