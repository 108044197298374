import { ChronikComponent } from './../../stations/chronik/chronik.component';
import { GuestbookComponent } from './../../stations/guestbook/guestbook.component';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ConfigComponent } from '../../configuration/config/config.component';
import { BaseComponent } from '../../stations/base/base.component';
import { DreamElevenComponent } from '../../stations/dream-eleven/dream-eleven.component';
import { StationTypes } from '../store/definitions/stationTypes';
import { ConfigExistsGuard } from '../../configuration/config-exists.guard';
import { CheckConfigComponent } from '../../configuration/check-config/check-config.component';
import { KitchensinkComponent } from '../kitchensink/kitchensink.component';
import { RefreshComponent } from '../components/refresh/refresh.component';
import { ProtagonistsVideoComponent } from '../../stations/protagonists-video/protagonists-video.component';
import { MovingImagesComponent } from '../../stations/moving-images/moving-images.component';
import { QuizComponent } from '../../stations/quiz/quiz.component';
import { QuizHighscoreComponent } from '../../stations/quiz/quiz-highscore/quiz-highscore.component';
import { QuizNameComponent } from '../../stations/quiz/quiz-name/quiz-name.component';
import { AuthGuard } from '../auth/auth.guard';
import { PottstarsComponent } from 'src/app/stations/pottstars/pottstars.component';
import {LoggedOutComponent} from "../components/logged-out/logged-out.component";

export const AppRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'app',
  },
  { path: 'check-config', component: CheckConfigComponent },
  { path: 'refresh', component: RefreshComponent },
  { path: 'logged-out', component: LoggedOutComponent },
  {
    path: 'kitchensink',
    component: KitchensinkComponent,
  },
  {
    path: 'app',
    component: BaseComponent,
    canActivate: [ConfigExistsGuard, AuthGuard],
    children: [
      {
        path: StationTypes.PROTANGONISTS_VIDEOS + '/:station',
        component: ProtagonistsVideoComponent,
      },
      {
        path: StationTypes.MOVING_IMAGES + '/:station',
        component: MovingImagesComponent,
      },
      {
        path: StationTypes.LUPFEN + '/:station',
        component: DreamElevenComponent,
      },
      {
        path: StationTypes.QUIZ + '/:station',
        component: QuizComponent,
        children: [
          {
            path: 'highscore/:highscore',
            component: QuizNameComponent,
          },
          {
            path: 'highscore/:highscore/:name',
            component: QuizHighscoreComponent,
          },
        ],
      },
      {
        path: StationTypes.DREAMELEVEN + '/:station',
        component: DreamElevenComponent,
      },
      {
        path: StationTypes.CHRONICLE + '/:station',
        component: ChronikComponent,
      },
      {
        path: StationTypes.POTTSTARS + '/:station',
        component: PottstarsComponent,
      },
      {
        path: StationTypes.GUESTBOOK + '/:station',
        component: GuestbookComponent,
      },
    ],
  },
  {
    path: 'client',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'config',
        component: ConfigComponent,
      },
    ],
  },
];

export const CORE_ROUTING = RouterModule.forRoot(AppRoutes, {
  preloadingStrategy: PreloadAllModules,
});
