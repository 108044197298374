import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiConnectorBackendSaveService } from '../../../core/io/content/api-connector-backend-save.service';
import { AlertService } from '../../../core/alerts/alert.service';

enum QuizHighscoreState {
  LOADING,
  LOADED,
  ERROR,
}

@Component({
  selector: 'app-quiz-highscore',
  templateUrl: './quiz-highscore.component.html',
  styleUrls: ['./quiz-highscore.component.scss'],
})
export class QuizHighscoreComponent implements OnInit {
  public currentHighscoreState = QuizHighscoreState.LOADING;
  public quizHighscoreStates = QuizHighscoreState;
  public userUUID;
  public highscores;
  public params;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _apiConnector: ApiConnectorBackendSaveService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _alertService: AlertService
  ) {
    this.params = this._activatedRoute.snapshot.params;

    this.SubmitHighscore(
      this.params.name ? this.params.name : 'Anonymous',
      this.params.highscore ? this.params.highscore : 0
    );
  }

  ngOnInit(): void {}

  public Restart() {
    this._router
      .navigateByUrl('/refresh', { skipLocationChange: true })
      .then(() => {
        this._router.navigateByUrl('/');
      });
  }

  private SubmitHighscore(name, highscore) {
    this._apiConnector.SaveQuizHighscore(name, highscore).subscribe(
      (payload) => {
        this.userUUID = payload['current_id'];
        this.highscores = payload['result']['rows'];
        this.currentHighscoreState = QuizHighscoreState.LOADED;

        this._changeDetectorRef.markForCheck();
      },
      (error) => {
        const urlParameters = this._router.url.split('/');

        const blacklist = error.error.indexOf('blacklisted') > -1;

        this._alertService.DispatchAlert(
          blacklist ? 'ERROR.BLACKLIST' : 'ERROR.HIGHSCORE_SAVE',
          'ERROR.ERROR_TITLE_SAVE'
        );
        this._router.navigateByUrl(
          'app/quiz/' +
            urlParameters[3] +
            '/' +
            urlParameters[4] +
            '/' +
            urlParameters[5]
        );
      }
    );
  }
}
