import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CONFIG_ACTIONS, setStationSuccess, setStatus } from './config.actions';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppStore } from '../appStore';
import { ApiConnectorStationsService } from '../../io/stations/api-connector-stations.service';
import { of } from 'rxjs';
import { LoadStatus } from '../definitions/load-states';
import { Router } from '@angular/router';

@Injectable()
export class ConfigEffects {
  setStationsCMS$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CONFIG_ACTIONS.CONFIG_SET_STATION),
      mergeMap((payload: { clientId: string; station: any }) => {
        return this._apiConnectorStationService
          .SetCurrentStationForClient(payload.clientId, payload.station)
          .pipe(
            map((data: any) => {
              try {
                return setStationSuccess({ payload: data });
              } catch (e) {
                throw e;
              }
            }),
            catchError((error) =>
              of(
                setStatus({
                  status: LoadStatus.error,
                  statusType: 'cms',
                })
              )
            )
          );
      })
    )
  );

  setStatusError$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(CONFIG_ACTIONS.CONFIG_SET_STATUS),
        tap((payload: { status: LoadStatus; statusType: string }) => {
          if (
            payload.status === LoadStatus.error &&
            payload.statusType === 'cms'
          ) {
            this._router.navigateByUrl('/client/config');
          }
        })
      ),
    { dispatch: false }
  );

  constructor(
    private _actions$: Actions,
    private _store: Store<AppStore>,
    private _apiConnectorStationService: ApiConnectorStationsService,
    private _router: Router
  ) {}
}
