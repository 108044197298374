<div
  *ngIf="content.stationStatus === StationStati.STANDBY && !singleVideo"
  class="cta-multiple"
>
  <div class="cta-multiple__play"></div>

  <div class="cta-multiple__text cta-multiple__text--bottom">
    {{ "STANDBY.TOUCH_TO_START_DE" | translate }}<br />
    {{ "STANDBY.TOUCH_TO_START_EN" | translate }}<br />
  </div>
</div>

<div
  *ngIf="content.stationStatus === StationStati.STANDBY && singleVideo"
  class="cta"
>
  <div class="cta__text cta__text--top">
    {{ "STANDBY.PLAY_DE" | translate }}
  </div>
  <div class="cta__play"></div>

  <div class="cta__text cta__text--bottom">
    {{ "STANDBY.PLAY_EN" | translate }}
  </div>
</div>

<div class="spacer spacer-4"></div>
<div class="grid-wrapper padding">
  <div class="row">
    <ng-container
      *ngFor="let video of content.parsedContent.videos; let i = index"
    >
      <div (click)="SetVideo(video, i)" class="col-xsmall-12 col-medium-4">
        <div class="videos">
          <div [innerHTML]="video.title" class="videos__title"></div>
          <div
            [ngStyle]="{ 'background-image': 'url(' + video.image + ')' }"
            class="img"
          ></div>
        </div>
      </div>
      <app-video-player
        (event)="VideoEvent($event)"
        [action]="activeVideo === i ? videoAction : undefined"
        [closeable]="true"
        [ngClass]="{ 'fullscreen-video--active': activeVideo === i }"
        [options]="{
          muted: false,
          autoplay: false,
          controls: true,
          sources: [{ src: video.src, type: 'video/mp4' }]
        }"
        class="fullscreen-video"
        subtitles="{{ video.subtitle }}"
      ></app-video-player>
    </ng-container>
  </div>
</div>
