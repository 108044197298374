import { Injectable } from '@angular/core';
import { ApiConnectorService } from '../api-connector.service';
import { ConfigService } from '../../config/config.service';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { AppStore } from '../../store/appStore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiConnectorStationsService extends ApiConnectorService {
  constructor(
    _configService: ConfigService,
    _http: HttpClient,
    _store: Store<AppStore>
  ) {
    super(_configService, _http, _store);
  }

  public Init() {
    //console.log('init-call of app');
  }

  public GetAvailableStations(): Observable<any> {
    return this._http.get(
      this._config.api.baseUrl + 'rest/export/borusseum/station-types'
    );
  }

  public GetAvailableSubItems(stationId): Observable<any> {
    return this._http.get(
      this._config.api.baseUrl + 'rest/export/borusseum/station/' + stationId
    );
  }

  public GetCurrentStationForClient(clientId): Observable<any> {
    return this._http.get(this._config.api.baseUrl + 'init/' + clientId);
  }

  public SetCurrentStationForClient(clientId, stationObj) {
    return this._http.post(this._config.api.baseUrl + 'init/add', {
      client_id: clientId,
      station_id: parseInt(stationObj.station_id),
      timeout: parseInt(stationObj.timeout),
      client_name: stationObj.client_name ? stationObj.client_name : clientId,
    });
  }
}
