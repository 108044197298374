import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { AppStore } from '../../store/appStore';
import { Store } from '@ngrx/store';
import { SelectLastAlert } from '../store/alerts.reducer';
import { showAlert } from './alert-view.animation';
import { ALERT_TYPES } from '../store/alerts.actions';
import { Actions } from '@ngrx/effects';

@Component({
  selector: 'app-alert-view',
  templateUrl: './alert-view.component.html',
  styleUrls: ['./alert-view.component.scss'],
  animations: [showAlert],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertViewComponent implements OnInit {
  public activeAlerts = [];
  public alertTypes: typeof ALERT_TYPES = ALERT_TYPES;
  private _timeouts = {};

  constructor(
    private _store: Store<AppStore>,
    private _changeDetectorRef: ChangeDetectorRef,
    private _actions$: Actions
  ) {
    _actions$.subscribe((action) => {
      try {
        const type = action.type.toLowerCase();
        if (type === 'clear') {
          this.activeAlerts.forEach((alert) => {
            this.RemoveAlert(alert.id);
          });
        }
      } catch (e) {}
    });
  }

  ngOnInit() {
    this._store.select(SelectLastAlert()).subscribe((alert) => {
      if (alert) {
        this.AddAlert(alert);
      }
    });
  }

  public AddAlert(alert) {
    console.log(alert);
    if (
      alert.type === ALERT_TYPES.UPDATE &&
      this.activeAlerts.filter((storedAlert) => {
        return storedAlert.type === ALERT_TYPES.UPDATE;
      }).length > 0
    )
      return;

    this.activeAlerts.push(alert);
    this._changeDetectorRef.markForCheck();
    if (alert['duration'] && alert['duration'] > 0) {
      this.StartTimeout(alert['id'], alert['duration']);
    }
  }

  public RemoveAlert(id) {
    this.StopTimeout(id);
    if (id !== '') {
      let index = this.activeAlerts
        .map(function (e) {
          return e['id'];
        })
        .indexOf(id);
      this.activeAlerts.splice(index, 1);
    }
    this._changeDetectorRef.markForCheck();
  }

  public StopTimeout(id: string) {
    clearTimeout(this._timeouts[id]);
    delete this._timeouts[id];
  }

  public StartTimeout(id: string, duration: number) {
    if (duration > 0) {
      this._timeouts[id] = setTimeout(() => {
        this.RemoveAlert(id);
      }, duration * 1000);
    }
  }

  public Reload() {
    window.location.reload();
  }
}
