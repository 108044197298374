<div class="alert-container">
  <ng-container *ngFor="let alert of activeAlerts; let i = index">
    <div
      (mouseover)="StopTimeout(alert.id)"
      [@reveal]
      [ngClass]="{
        error: alert.type === alertTypes.ALERT,
        success: alert.type === alertTypes.SUCCESS
      }"
      class="alert"
    >
      <ng-container *ngIf="alert.type !== alertTypes.UPDATE">
        <div
          (click)="RemoveAlert(alert.id)"
          *ngIf="alert.duration > 0"
          [innerHTML]="'ALERTS.CLOSE' | translate"
          class="close"
        ></div>
        <h3 class="alert__title" innerHTML="{{ alert.title | translate }}"></h3>

        <div
          class="alert__message"
          innerHTML="{{ alert.message | translate }}"
        ></div>
      </ng-container>
      <ng-container *ngIf="alert.type === alertTypes.UPDATE">
        <h3 class="alert__title" innerHTML="{{ alert.title | translate }}"></h3>
        <div
          class="alert__message"
          innerHTML="{{ alert.message | translate: alert.data }}"
        ></div>
        <div class="spacer"></div>
        <button (click)="Reload()" class="button">
          {{ "ALERTS.UPDATE.YES" | translate }}
        </button>
        <button (click)="RemoveAlert(alert.id)" class="button button-secondary">
          {{ "ALERTS.UPDATE.NO" | translate }}
        </button>
      </ng-container>
    </div>
  </ng-container>
</div>
