import { Injectable } from '@angular/core';
import { CoreConfig } from '../config/core.config';
import { ConfigService } from '../config/config.service';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { AppStore } from '../store/appStore';

export enum LOAD_STATUS {
  INIT = 'INIT',
  PROGRESS = 'PROGRESS',
  LOAD_MORE = 'LOAD_MORE',
  PARTIAL = 'PARTIAL',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export interface RequestStatus {
  status: LOAD_STATUS;
  error?: any;
}

@Injectable({
  providedIn: 'root',
})
export class ApiConnectorService {
  protected _config: CoreConfig;

  constructor(
    protected _configService: ConfigService,
    protected _http: HttpClient,
    protected _store: Store<AppStore>
  ) {
    this._config = this._configService.config;
  }

  public RequestTest(url, payload) {
    return this._http.post(url, payload);
  }
}
