import { fetchGuestbookPages } from './../../store/content/content.actions';
import { Injectable } from '@angular/core';
import { ApiConnectorService } from '../api-connector.service';
import { ConfigService } from '../../config/config.service';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { AppStore } from '../../store/appStore';
import { forkJoin, Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { ClientConfig } from '../../store/config/config.reducer';

@Injectable({
  providedIn: 'root',
})
export class ApiConnectorContentService extends ApiConnectorService {
  constructor(
    _configService: ConfigService,
    _http: HttpClient,
    _store: Store<AppStore>
  ) {
    super(_configService, _http, _store);
  }

  public FetchContent(clientConfig: ClientConfig): Observable<any> {
    /*return of({
      status: 'success',
      code: 200,
      message: '',
      payload: {
        standbyTitle: ['Gelbe Wand'],
        standbyImage: 'https://picsum.photos/id/965/2160/3840',
        //standbyImage: 'http://localhost:4900/assets/img/prot_test.jpg',
        videos: [
          {
            title: 'Highlights Champions<br/>League 2012-2013',
            poster: 'https://picsum.photos/id/965/2160/3840',
            video: '/assets/placeholder/video_landscape.mp4',
          },
        ],
        questions: [
          {
            text: 'Wann holte der BVB das erste Double der Vereinsgeschichte?',
            questionType: QuizQuestionType.STANDARD,
            answer: [
              {
                text: 'Saison 2001/2002',
                correctAnswer: false,
              },
              {
                text: 'Saison 1995/1996',
                correctAnswer: true,
              },
              {
                text: 'Saison 2011/2012',
                correctAnswer: false,
              },
            ],
          },
          {
            text: 'Wie heißt das BVB Maskottchen?',
            questionType: QuizQuestionType.STANDARD,
            answer: [
              {
                text: 'Willi',
                correctAnswer: false,
              },
              {
                text: 'Emma',
                correctAnswer: true,
              },
              {
                text: 'Manni',
                correctAnswer: false,
              },
            ],
          },
          {
            text: 'Diese Frisur gehört …',
            questionType: QuizQuestionType.BONUS_1,
            image: 'https://picsum.photos/id/965/1400/880',
            answer: [
              {
                text: 'Pierre-Emerick Aubameyang',
                correctAnswer: true,
              },
              {
                text: 'Axel Witsel',
                correctAnswer: false,
              },
              {
                text: 'Andreas Möller',
                correctAnswer: false,
              },
            ],
          },
          {
            text:
              'In diesem Gesicht wurden 4 Spieler miteinander vermischt.\nWer ist dabei?',
            questionType: QuizQuestionType.BONUS_2,
            image: 'https://picsum.photos/id/965/1400/880',
            answer: [
              {
                text: 'Roman Bürki',
                correctAnswer: true,
              },
              {
                text: 'Lothar Huber',
                correctAnswer: false,
              },
              {
                text: 'Theo Bücker',
                correctAnswer: false,
              },
              {
                text: 'Timo Konietzka',
                correctAnswer: false,
              },
              {
                text: 'Marco Reus',
                correctAnswer: false,
              },
              {
                text: 'Roman Bürki',
                correctAnswer: true,
              },
              {
                text: 'Lothar Huber',
                correctAnswer: false,
              },
              {
                text: 'Theo Bücker',
                correctAnswer: false,
              },
              {
                text: 'Timo Konietzka',
                correctAnswer: false,
              },
              {
                text: 'Marco Reus',
                correctAnswer: false,
              },
              {
                text: 'Roman Bürki',
                correctAnswer: true,
              },
              {
                text: 'Lothar Huber',
                correctAnswer: false,
              },
              {
                text: 'Theo Bücker',
                correctAnswer: false,
              },
              {
                text: 'Timo Konietzka',
                correctAnswer: false,
              },
              {
                text: 'Marco Reus',
                correctAnswer: false,
              },
              {
                text: 'Roman Bürki',
                correctAnswer: true,
              },
              {
                text: 'Lothar Huber',
                correctAnswer: false,
              },
              {
                text: 'Theo Bücker',
                correctAnswer: false,
              },
              {
                text: 'Timo Konietzka',
                correctAnswer: false,
              },
              {
                text: 'Marco Reus',
                correctAnswer: false,
              },
            ],
          },
          {
            text:
              'Welche BVB Spieler waren im Herbst 1966 an der „Fotoaffaire“ beteiligt?',
            questionType: QuizQuestionType.BONUS_3,
            solution:
              'Bei der Weihnachtsfeier 1966 wollte ein Fotograf ohne vorher zu fragen Fotos von Sigi Held und Lothar Emmerich machen. Die beiden wurden gegenüber dem Fotografen laut und sogar handgreiflich, entschuldigten sich aber später für ihr Verhalten.',
            answer: [
              {
                text: 'Aki Schmidt und Stan Libuda',
                correctAnswer: false,
              },
              {
                text: 'Hans Tilkowski und Hoppy Kurrat',
                correctAnswer: false,
              },
              {
                text: 'Siggi Held und Lothar Emmerich',
                correctAnswer: true,
              },
            ],
          },
          {
            text:
              'Wer war der 1. Trainer von Borussia Dortmund, der in der Bundesliga vorzeitig den Platz auf der Trainerbank räumen musste?',
            questionType: QuizQuestionType.BONUS_3,
            answer: [
              {
                text: 'Max Merkel',
                correctAnswer: false,
              },
              {
                text: 'Heinz Murach',
                correctAnswer: false,
              },
              {
                text: 'Willy Multhaup',
                correctAnswer: true,
              },
            ],
          },
        ],
      },
    }).pipe(delay(1000));
    // @todo with real api
    */

    return forkJoin([
      this._http.get(
        this._config.api.baseUrl +
          '/rest/export/borusseum/station/' +
          clientConfig.station_type +
          '/' +
          clientConfig.station_id
      ),
      this._http.get(
        this._config.api.baseUrl +
          '/en/rest/export/borusseum/station/' +
          clientConfig.station_type +
          '/' +
          clientConfig.station_id
      ),
    ]);
  }

  public FetchQuizQuestions(): Observable<any> {
    return this._http.get(
      this._config.api.baseUrl + '/rest/export/borusseum/station/quiz/questions'
    );
  }

  public FetchDreamElevenPlayers(): Observable<any> {
    return forkJoin([
      this._http.get(
        this._config.api.baseUrl + '/rest/export/borusseum/dream_eleven/player'
      ),
      this._http.get(
        this._config.api.baseUrl + 'rest/export/borusseum/dream_eleven/trainer'
      ),
    ]);
  }

  public fetchGuestbookPages(): Observable<any> {
    return this._http.get(
      this._config.api.baseUrl +
        'rest/export/borusseum/station/digital_guestbook/entries'
    );
  }
}
