import {
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { QuizService } from '../quiz.service';
import { cloneDeep } from 'lodash';
import { QuizQuestionType } from './quiz-question-type';

enum QuizQuestionState {
  INIT = 'INIT',
  ANSWERED = 'ANSWERED',
  TIMEOUT = 'TIMEOUT',
  HIDE = 'HIDE',
  SHOW = 'SHOW',
  NEXT = 'NEXT',
}

@Component({
  selector: 'app-quiz-question',
  templateUrl: './quiz-question.component.html',
  styleUrls: ['./quiz-question.component.scss'],
})
export class QuizQuestionComponent implements OnInit, OnChanges {
  public quizQuestionState;
  public timeoutProgress = 1;
  public blinkTimerUnder = 0.2;
  public currentQuestion;
  public selectedAnswerIndex = [];
  public bonus2ShowAnswers;
  @HostBinding('class') questionTypeClass = 'default';
  @Input() data: {
    text: string;
    questionType: QuizQuestionType;
    answer: Array<{
      text: string;
      correctAnswer: boolean;
    }>;
  };
  @Input() language: string;
  private ANIMATION_IN_OUT = 300;
  private AFTER_ANSWER_TIMEOUT = 2000;
  private _afterAnswerTimeout;
  private START_TIMER_AFTER = 1000;
  private _startTimerAfter;
  private SECONDS_ANSWER_TIME = 10;
  private SECONDS_ANSWER_TIME_BONUS_2 = 20;
  private _secondsAnswerTime = 10;
  private _questionEndTime;
  private _questionStartTime;

  constructor(
    private _quizService: QuizService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {}

  public Answer(answer, answerIndex) {
    if (
      this.quizQuestionState !== QuizQuestionState.INIT &&
      this.quizQuestionState !== QuizQuestionState.TIMEOUT
    )
      return;

    if (
      this.currentQuestion.questionType === QuizQuestionType.BONUS_2 &&
      answerIndex !== -2
    ) {
      //toggle
      const indexInArray = this.selectedAnswerIndex.indexOf(answerIndex);
      if (indexInArray > -1) {
        this.selectedAnswerIndex.splice(indexInArray, 1);
      } else {
        this.selectedAnswerIndex.push(answerIndex);
      }

      if (this.selectedAnswerIndex.length === 3) {
        let allCorrect = true;
        this.selectedAnswerIndex.forEach((answerIndex) => {
          allCorrect = this.currentQuestion.answer[answerIndex].correctAnswer
            ? allCorrect
            : false;
        });

        if (allCorrect) {
          this._quizService.AddPoints(
            this._secondsAnswerTime,
            this.timeoutProgress,
            this.currentQuestion.questionType
          );
        }

        this.ProgressState(QuizQuestionState.ANSWERED);
      }
    } else {
      this.selectedAnswerIndex = [answerIndex];

      if (answer.correctAnswer) {
        this._quizService.AddPoints(
          this._secondsAnswerTime,
          this.timeoutProgress,
          this.currentQuestion.questionType
        );
      }

      this.ProgressState(QuizQuestionState.ANSWERED);
    }
  }

  public Next() {
    this._quizService.NextQuestion();
  }

  public StartTimer() {
    this._questionStartTime = new Date().getTime();
    this._questionEndTime =
      this._questionStartTime + this._secondsAnswerTime * 1000;
    this.UpdateProgressBar();
  }

  public UpdateProgressBar() {
    if (this.quizQuestionState === QuizQuestionState.ANSWERED) {
      return;
    }

    const currentTime = new Date().getTime();
    if (currentTime > this._questionEndTime) {
      this.ProgressState(QuizQuestionState.TIMEOUT);
      return;
    }

    this.timeoutProgress =
      1 -
      (currentTime - this._questionStartTime) /
        (this._questionEndTime - this._questionStartTime);

    this.timeoutProgress = this.timeoutProgress > 1 ? 1 : this.timeoutProgress;
    this.timeoutProgress = this.timeoutProgress < 0 ? 0 : this.timeoutProgress;

    this._changeDetectorRef.markForCheck();

    window.requestAnimationFrame(() => this.UpdateProgressBar());
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.data &&
      changes.data.currentValue !== changes.data.previousValue
    ) {
      this.ProcessNewQuestion();
    }
  }

  private ProgressState(newState: QuizQuestionState) {
    this.quizQuestionState = newState;

    switch (newState) {
      case QuizQuestionState.SHOW:
        setTimeout(() => {
          this.ProgressState(QuizQuestionState.INIT);
        }, 1);
        break;
      case QuizQuestionState.INIT:
        setTimeout(() => {
          this.StartTimer();
        }, this._startTimerAfter);
        break;
      case QuizQuestionState.ANSWERED:
        setTimeout(() => {
          this.ProgressState(QuizQuestionState.HIDE);
        }, this._afterAnswerTimeout);
        break;
      case QuizQuestionState.HIDE:
        setTimeout(() => {
          this.ProgressState(QuizQuestionState.NEXT);
        }, this.ANIMATION_IN_OUT);
        break;
      case QuizQuestionState.NEXT:
        this.Next();
        break;
      case QuizQuestionState.TIMEOUT:
        this.Answer({ correctAnswer: false }, -2);
        break;
    }
    this._changeDetectorRef.markForCheck();
  }

  private ProcessNewQuestion() {
    this.selectedAnswerIndex = [];
    this.ProgressState(QuizQuestionState.SHOW);
    this.currentQuestion = cloneDeep(this.data);

    this.timeoutProgress = 1;

    //shuffle answers
    this.currentQuestion.answer = this.currentQuestion.answer
      .map((a) => ({ sort: Math.random(), value: a }))
      .sort((a, b) => a.sort - b.sort)
      .map((a) => a.value);

    this.questionTypeClass =
      'question-type-' + this.currentQuestion.questionType;

    switch (this.currentQuestion.questionType) {
      case QuizQuestionType.BONUS_2:
        this._startTimerAfter = 5000;
        this._afterAnswerTimeout = 4000;
        this.bonus2ShowAnswers = false;
        this._secondsAnswerTime = this.SECONDS_ANSWER_TIME_BONUS_2;

        setTimeout(() => {
          this.bonus2ShowAnswers = true;
          this._changeDetectorRef.markForCheck();
        }, 4000);

        break;
      default:
        this.bonus2ShowAnswers = undefined;
        this._secondsAnswerTime = this.SECONDS_ANSWER_TIME;
        this._startTimerAfter = this.START_TIMER_AFTER;
        this._afterAnswerTimeout = this.AFTER_ANSWER_TIMEOUT;
        break;
    }

    if (this.currentQuestion.solution_de) {
      this._afterAnswerTimeout = 7000;
    }
  }
}
