<div
  [ngClass]=""
  class="standby-header mode-{{ mode }} variation-{{ variation }} titles-{{
    titles[currentLang]?.length
  }} small-titles-{{ smallTitles[currentLang]?.length }}"
>
  <div class="background"></div>
  <div class="titles">
    <div *ngFor="let title of titles[currentLang]" class="title-container">
      <span class="title" [ngClass]="{'raw-data': title.toLowerCase().includes('thomas treß')}">{{ title }}</span>
    </div>
  </div>
  <div *ngIf="smallTitles[currentLang]" class="small-titles">
    <div class="small-title-container">
      <span [innerHTML]="smallTitles[currentLang]" class="small-title"></span>
    </div>
  </div>
</div>
<div
  *ngIf="
    standbyImage[currentLang] &&
    mode === standbyHeaderStati.STANDBY &&
    config.station_type !== 'chronicle'
  "
  @standbyImageInOut
  [ngStyle]="{
    'background-image': 'url(' + standbyImage[currentLang] + ')'
  }"
  class="standby-image"
></div>
<img
  src="{{ standbyImage['de'] }}"
  style="width: 0; height: 0; position: absolute; left: 0; top: 0"
/>
<img
  src="{{ standbyImage['en'] }}"
  style="width: 0; height: 0; position: absolute; left: 0; top: 0"
/>
