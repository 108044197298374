<div class="guestbook">
  <div class="guestbook__controls">
    <form #selectForm="ngForm" class="select-station-form">
      <app-input-textfield
        [placeholder]=""
        [form]="selectForm"
        [required]="false"
        name="name"
        [initValue]=""
        [showKeyboard]="true"
        [alwaysShowKeyboard]="true"
        [keyboardType]="'textfield'"
        [autofocus]="true"
        maxlength="280"
        [showTextAlign]="true"
        [textAlign]="'center'"
        (textAndStyle)="UpdateText($event)"
      >
      </app-input-textfield>
      <div class="spacer spacer-2"></div>

      <div class="pen-color-wrapper">
        <div class="pen-color-wrapper__pens">
          <div
            class="pen pen--s"
            (click)="SetDrawSize('s')"
            [ngClass]="{
              'pen--active':
                this.drawSettings.size === 's' && !this.drawSettings.erase
            }"
          ></div>
          <div
            class="pen pen--m"
            (click)="SetDrawSize('m')"
            [ngClass]="{
              'pen--active':
                this.drawSettings.size === 'm' && !this.drawSettings.erase
            }"
          ></div>
          <div
            class="pen pen--l"
            (click)="SetDrawSize('l')"
            [ngClass]="{
              'pen--active':
                this.drawSettings.size === 'l' && !this.drawSettings.erase
            }"
          ></div>
        </div>
        <div class="colors">
          <div
            class="color"
            *ngFor="let color of colors"
            [ngStyle]="{ 'background-color': color }"
            [ngClass]="{ 'color--active': color === this.drawSettings.color }"
            (click)="SetColor(color)"
          ></div>
        </div>
        <div
          class="eraser"
          [ngClass]="{ 'eraser--active': this.drawSettings.erase }"
          (click)="ToggleEraser()"
        ></div>
      </div>
      <div class="spacer spacer-2"></div>
      <div class="info-text">
        {{ "GUESTBOOK.PICTURE_AND_TEXT_CAN_BE_MOVED" | translate }}
      </div>
    </form>
  </div>
  <div class="guestbook__canvas">
    <div class="image" #imageContainer *ngIf="image" appMoveAround>
      <img
        [src]="image"
        id="image"
        class="image-rotation-{{ imageRotationRandom }}"
      />
    </div>

    <ng-container *ngIf="text && text.text.length">
      <div
        style="font-family: {{ text.fontFamily }}; text-align: {{
          text.textAlign
        }}"
        appMoveAround
        #textRef
        class="text"
        [innerHTML]="text.text"
      ></div>
    </ng-container>

    <div
      class="draw-wrapper"
      #drawWrapper
      [ngClass]="{ drawing: drawing }"
    ></div>

    <canvas class="canvas" #canvasBackground style="opacity: 0"></canvas>
    <canvas class="canvas" #canvasImage style="opacity: 0"></canvas>
    <canvas class="canvas" #canvas></canvas>
  </div>
</div>
