import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { SubscriptionsHelperComponent } from '../../../core/helper/subscriptions-helper/subscriptions-helper.component';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppStore } from '../../../core/store/appStore';
import { fetchQuizQuestions } from '../../../core/store/content/content.actions';

@Component({
  selector: 'app-quiz-name',
  templateUrl: './quiz-name.component.html',
  styleUrls: ['./quiz-name.component.scss'],
  animations: [
    trigger('nextButtonInOut', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(200px)' }),
        animate(
          '250ms cubic-bezier(0.4, 0.0, 0.2, 1)',
          style({ opacity: 1, transform: 'translateY(0px)' })
        ),
      ]),
      transition(':leave', [
        animate(
          '250ms cubic-bezier(0.4, 0.0, 0.2, 1)',
          style({ opacity: 0, transform: 'translateY(25px)' })
        ),
      ]),
    ]),
  ],
})
export class QuizNameComponent
  extends SubscriptionsHelperComponent
  implements OnInit, AfterViewInit
{
  @ViewChild('nameForm', { static: true }) ngForm: NgForm;
  @Input() label = 'field_enter_your_name_text';
  @Input() submit_text = 'field_submit_button_text';

  public formValid = false;
  public name = '';

  constructor(private _router: Router, private _store: Store<AppStore>) {
    super();
    this._store.dispatch(fetchQuizQuestions());
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.AddSubscription(
      this.ngForm.form.valueChanges.subscribe((value) => {
        if (value.data.highscoreName && value.data.highscoreName.length >= 2) {
          this.formValid = true;
          this.name = value.data.highscoreName;
        } else {
          this.formValid = false;
          this.name = '';
        }
      })
    );
  }

  public Save() {
    if (!this.name.length) return;
    this._router.navigateByUrl(this._router.url + '/' + this.name);
  }

  public Change($event) {
    //this.Save();
  }
}
