import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-input-validation-error-base',
  templateUrl: './input-validation-error-base.component.html',
  styleUrls: ['./input-validation-error-base.component.scss'],
})
export class InputValidationErrorBaseComponent implements OnInit, OnDestroy {
  public errorMsgs: string[] = [];
  public form: NgForm;
  public emptyError = 'UI.INPUT.ERRORS.EMPTY';
  private submitSubscription: Subscription;

  constructor() {}

  ngOnInit() {
    let that = this;

    if (this.form && this.form.ngSubmit) {
      this.submitSubscription = this.form.ngSubmit.subscribe(function () {
        that.Validate('', true);
      });
    }
  }

  ngOnDestroy() {
    if (this.submitSubscription) {
      this.submitSubscription.unsubscribe();
    }
  }

  public ResetErrors() {
    this.errorMsgs = [];
  }

  public Validate(value = '', first = true) {
    if (!this.form.valid) {
      this.errorMsgs.push('Not valid');
    }
  }
}
