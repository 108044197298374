<app-translation-switch></app-translation-switch>

<div class="bg" *ngIf="status === chronikStates.INTRODUCTION">
  <!-- helper info text -->
  <div class="info-text">
    {{ "field_helper_text" | translate }}
  </div>
  <div class="cta-multiple__play"></div>

  <div class="grid-container">
    <div
      class="grid-item"
      *ngFor="let box of boxes"
      [style.background]="BackgroundStyling(box)"
      class="item-{{ box.field_position.value }}"
      (click)="ChooseArticleFromMenu(box.field_position.value - 1)"
    ></div>
  </div>
</div>

<div class="container" *ngIf="status === chronikStates.DETAILPAGE">
  <div class="wrapper">
    <div
      #contentContainer
      class="content"
      *ngIf="
        selectedBox.paragraphs || selectedBox.videos || selectedBox.pictures
      "
    >
      <div
        class="content__element"
        *ngFor="let paragraph of selectedBox.paragraphs"
        [ngStyle]="SetOrder(paragraph.order)"
      >
        <div [innerHTML]="paragraph.value" class="content__element--text"></div>
      </div>

      <div
        class="content__element"
        *ngFor="let video of selectedBox.videos"
        [ngStyle]="SetOrder(video.order)"
      >
        <app-video-player
          (event)="VideoEvent($event)"
          [action]="1"
          [options]="{
            muted: false,
            autoplay: false,
            controls: true,
            sources: [{ src: video.value, type: 'video/mp4' }]
          }"
        ></app-video-player>
      </div>

      <div
        class="content__element"
        *ngFor="let picture of selectedBox.pictures"
        [ngStyle]="SetOrder(picture.order)"
      >
        <img class="image" [src]="picture.value" [alt]="picture.value" />
      </div>
    </div>
    <div class="navigation">
      <div
        class="button button__handwriting button__prev"
        [ngClass]="{ disabled: currentId - 1 < 0 }"
        (click)="PrevArticle()"
      >
        {{ "field_back_button_text" | translate }}
      </div>
      <div class="button button__handwriting button__back" (click)="Back()">
        {{ "field_start_again_button_text" | translate }}
      </div>
      <div
        class="button button__handwriting button__next"
        [ngClass]="{ disabled: currentId + 1 >= boxes.length }"
        (click)="NextArticle()"
      >
        {{ "field_next_button_text" | translate }}
      </div>
    </div>
  </div>
</div>
