import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {from, fromEvent, merge, Observable, Subscription} from 'rxjs';
import { map, pairwise, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-guestbook-entry-creator',
  templateUrl: './guestbook-entry-creator.component.html',
  styleUrls: ['./guestbook-entry-creator.component.scss'],
})
export class GuestbookEntryCreatorComponent implements OnInit, AfterViewInit {
  @Input() image: string;

  private onSaveSubscription$: Subscription;
  private onErrorSubscription$: Subscription;

  @Input() onSave: Observable<void>;
  @Input() onError: Observable<void>;

  @Output() canvasSaved = new EventEmitter();

  @Input() imageRotationRandom;

  public text;
  public drawing = false;
  public drawSettings: {
    size: string;
    color: string;
    erase: boolean;
  } = {
    size: 's',
    color: '#000000',
    erase: false,
  };

  public colors = [
    '#000000',
    '#fee605',
    '#989898',
    '#f3931b',
    '#3fa535',
    '#cd1719',
  ];

  @ViewChild('imageContainer') public imageContainer: ElementRef;

  @ViewChild('canvas') public canvas: ElementRef;
  @ViewChild('canvasImage') public canvasImage: ElementRef;
  @ViewChild('canvasBackground') public canvasBackground: ElementRef;

  @ViewChild('drawWrapper') public drawWrapper: ElementRef;

  @ViewChild('textRef') public textRef: ElementRef;
  private _ctx: CanvasRenderingContext2D;
  private _ctxBackground: CanvasRenderingContext2D;

  constructor(private _changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.onSaveSubscription$ = this.onSave.subscribe(() => {
      this.SaveToCanvas();
    });

    this.onErrorSubscription$ = this.onError.subscribe(() => {
      this.ResetBackgroundCanvas();
    });
  }

  ngOnDestroy() {
    this.onSaveSubscription$.unsubscribe();
    this.onErrorSubscription$.unsubscribe();
  }

  ngAfterViewInit() {
    this.SetupCanvas();
  }

  private UpdateCanvasDrawSettings() {
    switch (this.drawSettings.size) {
      case 's':
        this._ctx.lineWidth = 2;
        break;
      case 'm':
        this._ctx.lineWidth = 6;
        break;
      case 'l':
        this._ctx.lineWidth = 12;
        break;
    }

    this._ctx.strokeStyle = this.drawSettings.color;
  }

  private SetupCanvas() {
    // get the context
    const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
    const canvasElBackground: HTMLCanvasElement =
      this.canvasBackground.nativeElement;

    this._ctx = canvasEl.getContext('2d');
    this._ctxBackground = canvasElBackground.getContext('2d');

    // set the width and height
    canvasEl.width = this.drawWrapper.nativeElement.offsetWidth * 2;
    canvasEl.height = this.drawWrapper.nativeElement.offsetHeight * 2;

    canvasElBackground.width = this.drawWrapper.nativeElement.offsetWidth * 2;
    canvasElBackground.height = this.drawWrapper.nativeElement.offsetHeight * 2;

    this._ctxBackground.fillStyle = 'white';
    this._ctxBackground.fillRect(
      0,
      0,
      canvasElBackground.width,
      canvasElBackground.height
    );

    this._ctx.fillStyle = 'transparent';
    this._ctx.fillRect(0, 0, canvasEl.width, canvasEl.height);

    // set some default properties about the line
    this.UpdateCanvasDrawSettings();
    this._ctx.lineCap = 'round';

    // we'll implement this method to start capturing mouse events
    this.CaptureEvents(this.drawWrapper.nativeElement);
  }

  public ResetBackgroundCanvas() {
    const canvasElBackground: HTMLCanvasElement =
      this.canvasBackground.nativeElement;

    this._ctxBackground.fillStyle = 'white';
    this._ctxBackground.fillRect(
      0,
      0,
      canvasElBackground.width,
      canvasElBackground.height
    );
  }

  private CaptureEvents(element: HTMLElement) {

    const mousedownEvent = fromEvent(element, 'mousedown');
    const touchstartEvent = fromEvent(element, 'touchstart');

    const mousemoveEvent = fromEvent(element, 'mousemove');
    const touchmoveEvent = fromEvent(element, 'touchmove');

    const mouseupEvent = fromEvent(element, 'mouseup');
    const touchendEvent = fromEvent(element, 'touchend');
    const mouseleaveEvent = fromEvent(element, 'mouseleave');

    // this will capture all mousedown events from the canvas element
    merge(mousedownEvent, touchstartEvent)
      .pipe(
        switchMap((e) => {
          // after a mouse down, we'll record all mouse moves
          return merge(mousemoveEvent, touchmoveEvent).pipe(takeUntil(merge(mouseupEvent, touchendEvent, mouseleaveEvent).pipe(map(() => {
            this.drawing = false;
          }))), pairwise())

        })
      )
      .subscribe((res: [MouseEvent | TouchEvent, MouseEvent | TouchEvent]) => {

        this.drawing = true;

        let prev = [];
        let cur = [];

        if(res[0].type == "touchmove" && res[1].type == "touchmove"){
          prev = [res[0]["touches"]["0"]["clientX"],res[0]["touches"]["0"]["clientY"]];
          cur = [res[1]["touches"]["0"].clientX,res[1]["touches"]["0"].clientY];
        } else {
          prev = [res[0]["clientX"], res[0]["clientY"]];
          cur = [res[1]["clientX"], res[1]["clientY"]];
        }

        const rect = element.getBoundingClientRect();


        // previous and current position with the offset
        const prevPos = {
          x: prev[0] - rect.left,
          y: prev[1] - rect.top,
        };

        const currentPos = {
          x: cur[0] - rect.left,
          y: cur[1] - rect.top,
        };

        // this method we'll implement soon to do the actual drawing
        this.DrawOnCanvas(prevPos, currentPos);
      });
  }

  private DrawOnCanvas(
    prevPos: { x: number; y: number },
    currentPos: { x: number; y: number }
  ) {

    // incase the context is not set
    if (!this._ctx) {
      return;
    }

    if (this.drawSettings.erase) {
      Erase(currentPos.x * 2, currentPos.y * 2, 20, this._ctx);

      function Erase(x, y, radius, ctx) {
        ctx.save();
        ctx.globalCompositeOperation = 'destination-out';
        ctx.beginPath();
        ctx.arc(x, y, radius, 0, 2 * Math.PI, false);
        ctx.fillStyle = 'white';
        ctx.fill();
        ctx.restore();
      }
    } else {
      // start our drawing path
      this._ctx.beginPath();

      // we're drawing lines so we need a previous position
      if (prevPos) {
        // sets the start point
        this._ctx.moveTo(prevPos.x * 2, prevPos.y * 2); // from

        // draws a line from the start pos until the current position
        this._ctx.lineTo(currentPos.x * 2, currentPos.y * 2);

        // strokes the current path with the styles we set earlier
        this._ctx.stroke();
      }
    }
  }

  public UpdateText(textAndStyle: {
    text: string;
    textAlign: string;
    fontFamily: string;
  }) {
    this.text = textAndStyle;

    this._changeDetectorRef.markForCheck();
  }

  public SetDrawSize(size) {
    this.drawSettings.size = size;
    this.drawSettings.erase = false;

    this.UpdateCanvasDrawSettings();
  }

  public SetColor(color) {
    this.drawSettings.color = color;
    this.drawSettings.erase = false;

    this.UpdateCanvasDrawSettings();
  }

  public ToggleEraser() {
    this.drawSettings.erase = !this.drawSettings.erase;
  }

  public SaveToCanvas() {
    if (this.image) {

      const image = document.getElementById('image') as HTMLImageElement;

      const translateImageString =
        this.imageContainer.nativeElement.style.transform;
      const translateImageValues: string[] =
        translateImageString.match(/-?\d+/g);
      let translateImageX;
      let translateImageY;

      if (!translateImageValues) {
        translateImageX = 80;
        translateImageY = 100;
      } else {
        translateImageX = 80 + parseInt(translateImageValues[0]);
        translateImageY = 100 + parseInt(translateImageValues[1]);
      }


      if (this.imageRotationRandom === 'left') {
        this._ctxBackground.rotate(-(Math.PI * 2) / 90);

        this._ctxBackground.drawImage(
          image,
          translateImageX * 2,
          translateImageY * 2,
          600,
          (600 * image.height) / image.width
        );
        this._ctxBackground.rotate((Math.PI * 2) / 90);
      } else {
        this._ctxBackground.rotate((Math.PI * 2) / 90);
        this._ctxBackground.drawImage(
          image,
          translateImageX * 2,
          translateImageY * 2,
          600,
          (600 * image.height) / image.width
        );
        this._ctxBackground.rotate(-(Math.PI * 2) / 90);
      }
    }

    if (this.text) {
      if (this.text.text) {

        const translateTextString = this.textRef.nativeElement.style.transform;
        const translateTextValues: string[] =
          translateTextString.match(/-?\d+/g);
        let translateTextX;
        let translateTextY;


        if (!translateTextValues) {
          translateTextX = 230;
          translateTextY = 563;
        } else {
          translateTextX = 230 + parseInt(translateTextValues[0]);
          translateTextY = 563 + parseInt(translateTextValues[1]);
        }


        this._ctxBackground.fillStyle = '#000';
        this._ctxBackground.font = '48px DIN';
        this._ctxBackground.textAlign = 'center';

        if(this.text.textAlign && ["left", "center", "right"].includes(this.text.textAlign)){
          this._ctxBackground.textAlign = this.text.textAlign;
        }

        let x;
        const textContainerWidth = 360 * 2;

        const y = translateTextY * 2;

        // set x-value correspondingly to text-alignment
        switch (this._ctxBackground.textAlign) {
          case 'left':
            x = (translateTextX * 2)  - (textContainerWidth / 2);
            break;
          case 'right':
            x = (translateTextX * 2)  + (textContainerWidth / 2);
            break;
          case 'center':
          default:
            x = translateTextX * 2;
        }
        this.PrintAtWordWrap(this._ctxBackground, this.text.text, x, y, 50, textContainerWidth );
      }
    }



    this._ctxBackground.drawImage(
      this._ctx.canvas,
      0,
      0,
      this.drawWrapper.nativeElement.offsetWidth * 2,
      this.drawWrapper.nativeElement.offsetHeight * 2
    );

    this.canvasSaved.emit({
      canvasImg: this.canvasBackground.nativeElement.toDataURL('image/jpeg', 0.9),
      text: this.text ? this.text.text : '',
    });
  }

  private PrintAtWordWrap( context , text, x, y, lineHeight, fitWidth)
  {
    fitWidth = fitWidth || 0;

    if (fitWidth <= 0)
    {
      context.fillText( text, x, y );
      return;
    }

    const wordblocks = text.split('\n');

    let currentLine = 0;
    wordblocks.forEach((wordblock, index) => {
      let words = wordblock.split(' ');
      let idx = 1;
      while (words.length > 0 && idx <= words.length)
      {
        let str = words.slice(0,idx).join(' ');
        let w = context.measureText(str).width;
        if ( w > fitWidth )
        {
          if (idx==1)
          {
            idx=2;
          }
          context.fillText( words.slice(0,idx-1).join(' '), x, y + (lineHeight*currentLine) );
          currentLine++;
          words = words.splice(idx-1);
          idx = 1;
        }
        else
        {idx++;}

      }


      if  (idx > 0)
        context.fillText( words.join(' '), x, y + (lineHeight*currentLine) );
      currentLine++;

    });

  }
}
