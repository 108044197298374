import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { SubscriptionsHelperComponent } from '../../helper/subscriptions-helper/subscriptions-helper.component';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-input-name',
  templateUrl: './input-name.component.html',
  styleUrls: ['./input-name.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('nextButtonInOut', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(200px)' }),
        animate(
          '250ms cubic-bezier(0.4, 0.0, 0.2, 1)',
          style({ opacity: 1, transform: 'translateY(0px)' })
        ),
      ]),
      transition(':leave', [
        animate(
          '250ms cubic-bezier(0.4, 0.0, 0.2, 1)',
          style({ opacity: 0, transform: 'translateY(25px)' })
        ),
      ]),
    ]),
  ],
})
export class InputNameComponent
  extends SubscriptionsHelperComponent
  implements OnInit
{
  @ViewChild('nameForm', { static: true }) ngForm: NgForm;
  @Output() formData: EventEmitter<any> = new EventEmitter<any>();
  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();
  @Input() name = '';

  public formValid = false;

  constructor() {
    super();
  }

  ngOnInit(): void {
    if (this.name.length >= 2) {
      this.formValid = true;
    }
    this.AddSubscription(
      this.ngForm.form.valueChanges.subscribe((value) => {
        if (value.data.name && value.data.name.length >= 2) {
          this.formValid = true;
          this.name = value.data.name;
        } else {
          this.formValid = false;
          this.name = '';
        }
      })
    );
  }

  public Save() {
    if (!this.name.length) return;
    this.formData.emit(this.name);
  }

  public Close() {
    this.closeModal.emit();
  }
}
