import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import Keyboard from 'simple-keyboard';
import { keyboardOptions } from './keyboard.options';

@Component({
  selector: 'app-keyboard',
  templateUrl: './keyboard.component.html',
  styleUrls: [
    '../../../../../node_modules/simple-keyboard/build/css/index.css',
    './keyboard.component.scss',
  ],
  encapsulation: ViewEncapsulation.None,
})
export class KeyboardComponent implements OnInit, OnChanges, AfterViewInit {
  value = '';
  keyboard: Keyboard;
  @Input() initialValue;
  @Input() overrideValue;
  @Input() type = 'default';
  @Input() noInputAllowed = false;
  @Output() valueChanged = new EventEmitter();
  @Output() finish = new EventEmitter();
  @Output() clear = new EventEmitter();
  @Input() maxlength;
  public layout;
  public display;
  private _tmpValue; // used to save input when keyboard is locked

  public rand = "keyboard-" + Math.round(Math.random()*100000000000);

  constructor() {
    this.value = this.initialValue ? this.initialValue : '';
    this._tmpValue = this.value;
  }

  ngOnInit(): void {
    this.display = keyboardOptions.display;
    switch (this.type) {
      case 'email':
        this.layout = keyboardOptions.emailLayout;
        break;
      case 'name':
        this.layout = keyboardOptions.nameLayout;
        break;
      case 'pin':
        this.layout = keyboardOptions.numberLayout;
        break;
      case 'textfield':
        this.layout = keyboardOptions.textfield;
        break;
      default:
        this.layout = keyboardOptions.defaultLayout;
        break;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // allows
    if (changes.overrideValue) {
      if (this.keyboard) {
        this.keyboard.setInput(changes.overrideValue.currentValue);
      }
    }
  }

  ngAfterViewInit() {
    this.keyboard = new Keyboard(this.rand,{
      onChange: (input) => this.onChange(input),
      onKeyPress: (button) => this.onKeyPress(button),
      layout: this.layout,
      display: this.display,
      disableCaretPositioning: true, // not the nicest feature, but simplifies the behaviour i guess
      layoutName: this.type === 'name' ? 'shift' : 'default',
      newLineOnEnter: this.type === 'textfield',
    });
    this.keyboard.setInput(this.initialValue);
  }

  onChange = (input: string) => {
    const text =
      input && input.length > this.maxlength
        ? input.substr(0, this.maxlength)
        : input;
    if (this.noInputAllowed) {
      this.keyboard.setInput('');
      return;
    }

    if (text !== input) {
      this.keyboard.setInput(text);
    }

    this.value = text;
    this._tmpValue = text;
    this.valueChanged.emit(text);
  };

  onKeyPress = (button: string) => {
    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === '{shift}' || button === '{lock}') this.handleShift();

    if (button === '{123}' || button === '{abc}') this.handleNumbers();

    if (button === '{clear}') this.handleClear();

    if (button === '{submit}') {
      this.finish.emit('submit');
    }
  };

  onInputChange = (event: any) => {
    this.keyboard.setInput(event.target.value);
  };

  handleNumbers = () => {
    let currentLayout = this.keyboard.options.layoutName;
    let numberToggle = currentLayout === 'default' ? 'numbers' : 'default';
    this.keyboard.setOptions({
      layoutName: numberToggle,
    });
  };

  handleClear = () => {
    this.keyboard.setInput('');
    this.valueChanged.emit('');
  };

  handleShift = () => {
    let currentLayout = this.keyboard.options.layoutName;
    let shiftToggle = currentLayout === 'default' ? 'shift' : 'default';

    this.keyboard.setOptions({
      layoutName: shiftToggle,
    });
  };
}
