import { Injectable } from '@angular/core';
import { AppStore } from '../store/appStore';
import { Store } from '@ngrx/store';
import { alertClear, alertError, alertInfo } from './store/alerts.actions';
import { Actions } from '@ngrx/effects';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private _store: Store<AppStore>, private _actions$: Actions) {
    _actions$.subscribe((action) => {
      try {
        const type = action.type.toLowerCase();
        if (type.includes('error')) {
          const error: any = action['error'];
          if (error.showAlert) {
            this.DispatchAlert(error.message, error.name);
          }
        }
      } catch (e) {}
    });

    window.addEventListener('offline', (e) => {
      this.DispatchAlert('ERROR.OFFLINE', 'ERROR.OFFLINE_TITLE', 0);
    });

    window.addEventListener('online', (e) => {
      this._store.dispatch(alertClear());
    });
  }

  public DispatchAlert(
    message: string,
    title: string = '',
    duration: number = 10
  ) {
    this._store.dispatch(
      alertError({
        title: title,
        duration: duration,
        message: message,
      })
    );
  }

  public DispatchInfo(
    message: string,
    title: string = '',
    duration: number = 10
  ) {
    this._store.dispatch(
      alertInfo({
        title: title,
        duration: duration,
        message: message,
      })
    );
  }
}
