import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import videojs from 'video.js';
import { StandbyService } from '../../standby/standby.service';

export enum VideoEvents {
  'PLAY',
  'PAUSE',
  'STOP',
  'END',
}

@Component({
  selector: 'app-video-player',
  template: `
    <div
      [className]="'video-wrapper variation--' + theme + ' status--' + action"
      [ngClass]="{ hide: !active && !this.player.paused() }"
    >
      <video
        #target
        class="video-js"
        controls
        muted
        playsinline
        preload="auto"
      ></video>
      <div
        class="subtitles"
        (click)="ToggleSubtitles()"
        *ngIf="subtitles"
        [ngClass]="{ hide: !active }"
      >
        <div
          class="subtitles__box"
          [ngClass]="{ 'subtitles__box--checked': showSubtitles }"
        ></div>
        {{ 'VIDEOPLAYER.SUBTITLES' | translate }}
      </div>
      <div class="close" *ngIf="closeable" (click)="End()"></div>
    </div>
  `,
  styleUrls: ['./video-player.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VideoPlayerComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('target', { static: true }) target: ElementRef;
  // see options: https://github.com/videojs/video.js/blob/maintutorial-options.html
  @Input() options: {
    fluid: boolean;
    aspectRatio: string;
    autoplay: boolean;
    sources: {
      src: string;
      type: string;
    }[];
  };
  player: videojs.Player;

  @Input() action;
  @Input() theme = 'LARGE';
  @Output() event: EventEmitter<VideoEvents> = new EventEmitter<VideoEvents>();
  @Input() subtitles: string;
  @Input() closeable: boolean = false;
  public showSubtitles = false;
  public active = true;
  public forcedPause = false;

  constructor(
    private elementRef: ElementRef,
    private _standbyService: StandbyService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    const that = this;
    // instantiate Video.js
    this.player = videojs(
      this.target.nativeElement,
      this.options,
      function onPlayerReady() {
        this.on('ended', function () {
          that.End();
        });

        this.on('pause', function () {
          that.event.emit(VideoEvents.PAUSE);
          that.action = VideoEvents.PAUSE;
          that.OnChange();
        });

        this.on('play', function () {
          that._standbyService.StopStandByTimer(true);
        });

        this.on('userinactive', function () {
          that.active = false;
          that._changeDetectorRef.markForCheck();
        });

        this.on('useractive', function () {
          that.active = true;
          that._changeDetectorRef.markForCheck();
        });

        if (that.subtitles) {
          this.addRemoteTextTrack({ src: that.subtitles }, false);
        }
      }
    );
  }

  End() {
    this.event.emit(VideoEvents.END);
    this.action = VideoEvents.END;
    this.OnChange();
  }

  ngOnDestroy() {
    // destroy player
    if (this.player) {
      this.player.dispose();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.action &&
      changes.action.currentValue !== changes.action.previousValue
    ) {
      this.OnChange();
    }
  }

  public OnChange() {
    switch (this.action) {
      case VideoEvents.PLAY:
        this.player.currentTime(0);
        this.player.play();
        this._standbyService.StopStandByTimer(true);
        break;
      case VideoEvents.END:
        this.player.currentTime(0);
        this._standbyService.UnlockTimer();
        this.ToggleSubtitles(true);
        this.forcedPause = true;
        this.player.pause();

        break;
      case VideoEvents.PAUSE:
        if (!this.forcedPause) {
          this._standbyService.UnlockTimer();
          this._standbyService.RestartStandbyTimer();
        }
        this.forcedPause = false;
        break;
    }
  }

  public ToggleSubtitles(forceOff = false) {
    this.showSubtitles = forceOff ? false : !this.showSubtitles;
    const tracks = this.player.textTracks();

    for (let i = 0; i < tracks.length; i++) {
      const track = tracks[i];

      track.mode = this.showSubtitles ? 'showing' : 'disabled';
    }
  }
}
