<app-video-player
  (event)="VideoEvent($event)"
  [action]="videoAction"
  [options]="{
    muted: false,
    autoplay: false,
    controls: true,
    sources: [{ src: content.parsedContent.src, type: 'video/mp4' }]
  }"
  [subtitles]="content.parsedContent.subtitle"
></app-video-player>

<div *ngIf="content.stationStatus === StationStati.STANDBY" class="cta">
  <div class="cta__text cta__text--top">
    {{ "STANDBY.PLAY_DE" | translate }}
  </div>
  <div class="cta__play"></div>

  <div class="cta__text cta__text--bottom">
    {{ "STANDBY.PLAY_EN" | translate }}
  </div>
</div>
