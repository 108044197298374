import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-subscriptions-helper',
  template: '',
  styleUrls: [],
})
export class SubscriptionsHelperComponent implements OnInit, OnDestroy {
  private _subscriptions;

  constructor() {
    this._subscriptions = [];
  }

  ngOnInit() {}

  public AddSubscription(subscription: any) {
    this._subscriptions.push(subscription);
  }

  ngOnDestroy() {
    this._subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
