<div
  (click)="CloseErrorMsg()"
  *ngIf="showError"
  class="validation-error-container"
>
  <div class="error-msg">
    <ul class="error-list">
      <li *ngFor="let err of errorMsgs" class="error-list-item">
        {{ err | translate }}
      </li>
    </ul>
  </div>
</div>
