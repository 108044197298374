import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, timeout } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-take-picture',
  templateUrl: './take-picture.component.html',
  styleUrls: ['./take-picture.component.scss'],
})
export class TakePictureComponent implements AfterViewInit {
  @Input() WIDTH = 1920;
  @Input() HEIGHT = 1440;

  @ViewChild('video')
  public video: ElementRef;

  @ViewChild('canvas')
  public canvas: ElementRef;

  @Input() station: string;

  @Output() stopCountdown = new EventEmitter();
  @Output() showPicture = new EventEmitter();

  public countdownNumber: number = 3;
  public imgLink: string = '';
  public error: any;
  public isCaptured: boolean;

  constructor(
    private http: HttpClient,
    private _changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  async ngAfterViewInit() {
    await this.SetupDevices();
    this.StartCountdown();
  }

  public async SetupDevices() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        if (stream) {
          this.video.nativeElement.srcObject = stream;
          this.video.nativeElement.play();
          this.error = null;
        } else {
          this.error = 'You have no output video device';
        }
      } catch (e) {
        this.error = e;
      }
    }
  }

  public StartCountdown() {
    let interval = setInterval(() => {
      if (this.countdownNumber > -1) {
        this.countdownNumber--;
      }
      if (this.countdownNumber == 0) {
      }
      if (this.countdownNumber == -1) {
        this.Capture();
        clearInterval(interval);
      }
      this._changeDetectorRef.markForCheck();
    }, 1000);
  }

  public Capture() {
    if (this.station === 'POTTSTARS') {
      this.DrawImageToCanvas(this.video.nativeElement);
      const fallbackImg = this.canvas.nativeElement.toDataURL('image/png');
      this.isCaptured = true;
      // Taking Picture using DSLR
      this.http
        .get<any>('192.168.27.171/api/camera/take')
        .pipe(
          timeout(3000),
          catchError((err) => {
            this.imgLink = fallbackImg;
            this.showPicture.emit(this.imgLink);
            return of(null);
          })
        )
        .subscribe(
          (data) => {
            if (data) {
              this.imgLink = data.image;
              this.showPicture.emit(this.imgLink);
            }
          },
          (err) => {
            console.log(err);
          }
        );
    } else {
      this.DrawImageToCanvas(this.video.nativeElement);
      const fallbackImg = this.canvas.nativeElement.toDataURL('image/png');
      this.isCaptured = true;
      this.imgLink = fallbackImg;
      this.showPicture.emit(this.imgLink);
      return of(null);
    }
  }

  public StopCountdown() {
    this.video.nativeElement.pause();
    this.stopCountdown.emit();
  }

  public DrawImageToCanvas(image: any) {
    this.canvas.nativeElement
      .getContext('2d')
      .drawImage(image, 0, 0, this.WIDTH, this.HEIGHT);
  }
}
