import { DoublePage } from './../../../stations/guestbook/guestbook.component';
import { Action, createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { LoadStatus } from '../definitions/load-states';
import {
  addGuestbookPage,
  fetchContent,
  fetchContentSuccess,
  fetchDreamElevenPersons,
  fetchDreamElevenPersonsSuccess,
  fetchGuestbookPages,
  fetchGuestbookPagesSuccess,
  fetchQuizQuestions,
  fetchQuizQuestionsSuccess,
  setDynamicStationContent,
  setGuestbookPage,
  setHeaderStatus,
  setLanguage,
  setStationStatus,
  toggleHeaderStatus,
} from './content.actions';
import { HeaderStatus } from '../definitions/header-states';
import { StationStatus } from '../definitions/station-states';
import { LanguageCode } from '../definitions/languages';
import { SplitAtBR } from '../../helper/string-helper';
import { StationTypes } from '../definitions/stationTypes';
import { QuizQuestionType } from '../../../stations/quiz/quiz-question/quiz-question-type';
import { Shuffle } from '../../helper/shuffle';
import { DreamElevenPositions } from '../../../stations/dream-eleven/positions';
import { GetUuid } from '../../helper/uuid';

export interface Content {
  loadStatus: LoadStatus;
  language: LanguageCode;
  content: any;
  content_en: any;
  parsedContent?: {
    standbyImage: string;
    shortTitle: string;
    standbyTitle: string[];
  };
  parsedContent_en?: {
    standbyImage: string;
    shortTitle: string;
    standbyTitle: string[];
  };
  quiz?: {
    valid?: boolean;
    questions?: [];
    status: LoadStatus;
  };
  dreamEleven?: {
    player?: any;
    trainer?: Array<any>;
    status: LoadStatus;
  };
  guestbook?: {
    realPageCount?: number;
    pageCount?: number;
    pages?: Array<DoublePage>;
    currPage?: number;
    status: LoadStatus;
  };
  chronik?: {
    content?: any;
    status: LoadStatus;
  };
  stationStatus: StationStatus;
  standbyHeader: {
    status: HeaderStatus;
  };
  dynamicStationContent?: any;
}

export const initialState: Content = {
  loadStatus: LoadStatus.init,
  language: 'de',
  content: {},
  content_en: {},
  parsedContent: {
    standbyImage: '',
    shortTitle: '',
    standbyTitle: [],
  },
  parsedContent_en: {
    standbyImage: '',
    shortTitle: '',
    standbyTitle: [],
  },
  stationStatus: StationStatus.INIT,
  standbyHeader: {
    status: HeaderStatus.STANDBY,
  },
};

const contentReducer = createReducer(
  initialState,
  on(setLanguage, (state, action) => {
    state = cloneDeep(state);
    state.language = action.language;

    return state;
  }),
  on(fetchContent, (state, action) => {
    state = cloneDeep(state);
    state.loadStatus = LoadStatus.loading;

    return state;
  }),

  on(fetchContentSuccess, (state, action) => {
    state = cloneDeep(state);
    state.loadStatus = LoadStatus.loaded;

    state.content = action.payload[0]['rows'];
    state.content_en = action.payload[1]['rows'];

    //get image and title from first
    try {
      state.parsedContent.standbyImage =
        state.content[0].standby_image_serialized.field_media_image._url.absolute;

      state.parsedContent_en.standbyImage =
        state.content_en[0].standby_image_serialized.field_media_image._url.absolute;
    } catch (e) {}

    try {
      state.parsedContent.standbyTitle = SplitAtBR(
        state.content[0].standby_title
      );
      state.parsedContent_en.standbyTitle = SplitAtBR(
        state.content_en[0].standby_title
      );
    } catch (e) {}

    try {
      state.parsedContent.shortTitle = state.content[0].field_short_title;
      state.parsedContent_en.shortTitle = state.content_en[0].field_short_title;
    } catch (e) {}

    const stationType = action.payload[0].endpoint.path.split('/')[4];
    switch (stationType) {
      case StationTypes.PROTANGONISTS_VIDEOS:
        let subtitle;
        try {
          subtitle =
            state.content[0].field_video_subtitles_serialized.field_media_file
              ._url.absolute;
        } catch (e) {}

        state.parsedContent = {
          ...state.parsedContent,
          ...{
            src: state.content[0].field_video_file_serialized
              .field_media_video_file._url.absolute,
            subtitle: subtitle,
          },
        };
        break;
      case StationTypes.MOVING_IMAGES:
        state.parsedContent = {
          ...state.parsedContent,
          ...{
            videos: state.content.map((entry) => {
              let subtitle;
              try {
                subtitle =
                  entry.field_video_subtitles_serialized.field_media_file._url
                    .absolute;
              } catch (e) {}

              let thumbnail;
              try {
                thumbnail =
                  entry.field_video_thumbnail_serialized.field_media_image._url
                    .absolute;
              } catch (e) {}

              return {
                src: entry.field_video_file_serialized.field_media_video_file
                  ._url.absolute,
                subtitle: subtitle,
                image: thumbnail,
                title: entry.field_video_title_serialized,
              };
            }),
          },
        };
        break;
    }

    return state;
  }),

  on(setStationStatus, (state, action) => {
    state = cloneDeep(state);
    state.stationStatus = action.status;

    return state;
  }),

  on(setHeaderStatus, (state, action) => {
    state = cloneDeep(state);
    state.standbyHeader.status = action.headerStatus;

    return state;
  }),

  on(toggleHeaderStatus, (state, action) => {
    state = cloneDeep(state);
    state.standbyHeader.status =
      state.standbyHeader.status === HeaderStatus.STANDBY
        ? HeaderStatus.IN_USE
        : HeaderStatus.STANDBY;

    return state;
  }),

  on(setDynamicStationContent, (state, action) => {
    state = cloneDeep(state);
    state.dynamicStationContent = action.dynamicStationContent;
    return state;
  }),
  on(fetchQuizQuestions, (state, action) => {
    state = cloneDeep(state);
    state.quiz = {
      status: LoadStatus.loading,
    };
    return state;
  }),
  on(fetchDreamElevenPersons, (state, action) => {
    state = cloneDeep(state);
    state.dreamEleven = {
      status: LoadStatus.loading,
    };
    return state;
  }),
  on(fetchDreamElevenPersonsSuccess, (state, action) => {
    state = cloneDeep(state);

    let positions = {};
    Object.keys(DreamElevenPositions).forEach((key) => {
      let player = cloneDeep(action.player);
      const entry = player
        .sort((player1, player2) => {
          const p1Counter = player1[DreamElevenPositions[key]]
            ? player1[DreamElevenPositions[key]]
            : 0;
          const p2Counter = player2[DreamElevenPositions[key]]
            ? player2[DreamElevenPositions[key]]
            : 0;

          return p2Counter - p1Counter;
        })
        .map((player) => {
          player.id = player.id ? player.id : GetUuid();
          return player;
        });
      positions[DreamElevenPositions[key]] = entry;
    });

    state.dreamEleven = {
      status: LoadStatus.loaded,
      player: positions,
      trainer: action.trainer,
    };
    return state;
  }),
  on(fetchQuizQuestionsSuccess, (state, action) => {
    state = cloneDeep(state);

    const questionsDE = action.payload.filter(
      (question) => question.langcode === 'German'
    );
    const questionsEN = action.payload.filter(
      (question) => question.langcode === 'English'
    );

    //shuffle default questions
    let questions = Shuffle(
      questionsDE.filter(
        (question) => question.type === QuizQuestionType.STANDARD
      )
    );

    //add bonus question 1
    questions.splice(
      2,
      0,
      questionsDE.find((question) => question.type === QuizQuestionType.BONUS_1)
    );

    //add bonus question 2
    questions.splice(
      5,
      0,
      questionsDE.find((question) => question.type === QuizQuestionType.BONUS_2)
    );

    //add bonus question 3
    questions.splice(
      8,
      0,
      questionsDE.find((question) => question.type === QuizQuestionType.BONUS_3)
    );

    //error handling: filter undefined questions
    questions = questions.filter((question) => question);

    //add english translation & clean up
    questions = questions.map((question) => {
      let questionEN = questionsEN.find((questionEN) => {
        return questionEN.id === question.id;
      });

      questionEN = questionEN ? questionEN : question;

      //field_multiple_correct_answers_serialized;
      let answerArray = question.field_single_correct_answer_serialized.length
        ? question.field_single_correct_answer_serialized
        : question.field_multiple_correct_answers_serialized.length
        ? question.field_multiple_correct_answers_serialized
        : [];

      let answers = answerArray.map((answer, index) => {
        return {
          correctAnswer: answer.is_correct.value === '1',
          text_de: answer.text.value,
          text_en: questionEN.field_single_correct_answer_serialized[index]
            ? questionEN.field_single_correct_answer_serialized[index].text
                .value
            : questionEN.field_multiple_correct_answers_serialized[index].text
                .value,
        };
      });

      return {
        text_de: question.text,
        text_en: questionEN.text,
        questionType: question.type,
        solution_de: question.field_answer_explanation,
        solution_en: questionEN.field_answer_explanation,
        image:
          question.field_image_serialized &&
          question.field_image_serialized.field_media_image
            ? question.field_image_serialized.field_media_image._url.absolute
            : undefined,
        answer: answers,
      };
    });

    state.quiz = {
      valid: true,
      questions: questions,
      status: LoadStatus.loaded,
    };

    return state;
  }),
  on(fetchGuestbookPages, (state, action) => {
    state = cloneDeep(state);
    state.guestbook = {
      status: LoadStatus.loading,
    };
    return state;
  }),
  on(fetchGuestbookPagesSuccess, (state, action) => {
    state = cloneDeep(state);

    state.guestbook = {
      realPageCount: action.pageCount === -1 ? 0 : action.pageCount,
      pageCount: action.pageCount === -1 ? 0 : action.pageCount,
      status: LoadStatus.loaded,
      pages: action.pages,
      currPage: action.pageCount === -1 ? 0 : action.pageCount,
    };

    return state;
  }),

  on(addGuestbookPage, (state, action) => {
    state = cloneDeep(state);
    let newCurrPage: number;
    const newPages = state.guestbook.pages;
    let newPageCount;


    if (
      !state.guestbook.pages[0].pageOne.url ||
      state.guestbook.pages[0].pageOne.url.includes('base64')
    ) {
      newPages[state.guestbook.realPageCount] = {
        pageOne: {
          url: action.payload,
          pageNr: 0,
        },
        pageTwo: {
          url: undefined,
          pageNr: 1,
        },
        index: 0,
      };
      newPageCount = 0;
    } else if (
      (state.guestbook.pages[0].pageOne.url &&
        !state.guestbook.pages[0].pageOne.url.includes('base64') &&
        !state.guestbook.pages[0].pageTwo.url) ||
      state.guestbook.pages[0].pageTwo.url.includes('base64')
    ) {
      newPages[state.guestbook.realPageCount].pageTwo.url = action.payload;
      newPageCount = 0;
    } else if (
      newPages[state.guestbook.realPageCount]?.pageTwo.url === undefined ||
      newPages[state.guestbook.realPageCount]?.pageTwo.url.includes('base64')
    ) {
      newPages[state.guestbook.realPageCount].pageTwo.url = action.payload;
      newPageCount =
        state.guestbook.realPageCount === -1
          ? 0
          : state.guestbook.realPageCount;
    } else {
      newPages[state.guestbook.realPageCount + 1] = {
        pageOne: {
          url: action.payload,
          pageNr: newPages[state.guestbook.realPageCount].pageTwo.pageNr + 1,
        },
        pageTwo: {
          url: undefined,
          pageNr: newPages[state.guestbook.realPageCount].pageTwo.pageNr + 2,
        },
        index: newPages[state.guestbook.realPageCount].index + 1,
      };
      newPageCount =
        state.guestbook.realPageCount + 1 === -1
          ? 1
          : state.guestbook.realPageCount + 1;
    }

    newCurrPage = newPageCount;

    state.guestbook = {
      realPageCount: state.guestbook.realPageCount,
      pageCount: newPageCount,
      pages: newPages,
      currPage: newCurrPage,
      status: LoadStatus.loaded,
    };

    return state;
  }),
  on(setGuestbookPage, (state, action) => {
    state = cloneDeep(state);
    let newCurrPage: number;

    if (action.payload === 1) {
      // if there is a next Page in the state
      if (state.guestbook.pages[state.guestbook.currPage + 1]) {
        newCurrPage = state.guestbook.currPage + 1;
      }
    } else {
      // if there is a next Page in the state
      if (state.guestbook.pages[state.guestbook.currPage - 1]) {
        newCurrPage = state.guestbook.currPage - 1;
      } else {
        return state;
      }
    }

    let guestbook;

    if (action.payload === 1) {
      guestbook = {
        realPageCount: state.guestbook.realPageCount,
        pageCount: state.guestbook.pageCount,
        pages: state.guestbook.pages,
        currPage: newCurrPage,
        status: LoadStatus.loaded,
      };
    } else {
      guestbook = {
        realPageCount: state.guestbook.realPageCount,
        pageCount: state.guestbook.pageCount,
        pages: state.guestbook.pages,
        currPage: newCurrPage,
        status: LoadStatus.loaded,
      };
    }

    state.guestbook = guestbook;

    return state;
  })
);

export function reducer(state: Content | undefined, action: Action) {
  return contentReducer(state, action);
}
