import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot,} from '@angular/router';
import {KeycloakService} from 'keycloak-angular';
import {Observable} from 'rxjs';
import {ApiConnectorStationsService} from "../io/stations/api-connector-stations.service";
import {AlertService} from "../alerts/alert.service";

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    protected readonly router: Router,
    protected readonly keycloak: KeycloakService,
    private _apiStations: ApiConnectorStationsService,
    private _alertService: AlertService
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return new Observable((obs) => {

      console.log("HERE");


//Check if the backend is available nevertheless
      this._apiStations.GetAvailableStations().subscribe(res => {
        obs.next(true);
        obs.complete();
      }, error => {

        //go to login page if it's a 403, show an alert if its another error
        if (error.status === 403) {
          this.keycloak.login({
            redirectUri: window.location.origin + state.url,
          });
          obs.next(false);
          obs.complete();
        } else {
          this._alertService.DispatchAlert("Die Verbindung zum Server wurde unterbrochen. Bitte versuchen Sie es später erneut.", "Verbindungsprobleme");
          obs.next(false);
          obs.complete();
        }
      });
    });
  }
}
