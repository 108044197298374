export const keyboardOptions = {
  defaultLayout: {
    default: [
      '~ ! @ # $ % ^ & * ( ) _ + {backspace}',
      'Q W E R T Z U I O P Ü { } |',
      'A S D F G H J K L Ö Ä : " {enter}',
      '{shift} Y X C V B N M < > ?',
      '{space}',
    ],
    shift: [
      '1 2 3 4 5 6 7 8 9 0 - = {backspace}',
      'q w e r t z u i o p ü [ ] \\',
      'a s d f g h j k l ö ä ; {enter}',
      '{shift} y x c v b n m , . /',
      '{space}',
    ],
  },
  textfield: {
    default: [
      '~ ! @ # $ % ^ & * ( ) _ + {backspace}',
      'Q W E R T Z U I O P Ü { } |',
      'A S D F G H J K L Ö Ä : " {enter}',
      '{shift} Y X C V B N M < > ?',
      '{space}',
    ],
    shift: [
      '1 2 3 4 5 6 7 8 9 0 - = {backspace}',
      'q w e r t z u i o p ü [ ] \\',
      'a s d f g h j k l ö ä ; {enter}',
      '{shift} y x c v b n m , . /',
      '{space}',
    ],
  },
  emailLayout: {
    default: [
      'q w e r t z u i o p ü',
      'a s d f g h j k l ö ä',
      'y x c v b n m',
      '{123} {shift} . @ {backspace}',
    ],
    shift: [
      'Q W E R T Z U I O P Ü',
      'A S D F G H J K L Ö Ä',
      'Y X C V B N M',
      '{123} {shift} . @ {backspace}',
    ],
    numbers: [
      '1 2 3 4 5 6 7 8 9 0',
      '_ - * ! " \' § % / ( )',
      'ß ~ € ¥ ≈ √ / | [ ]',
      '{abc} {shift} @ {backspace}',
    ],
  },
  nameLayout: {
    default: [
      'Q W E R T Z U I O P Ü',
      'A S D F G H J K L Ö Ä',
      '{shift} Y X C V B N M',
      '{space} {backspace}',
    ],
    shift: [
      'q w e r t z u i o p ü',
      'a s d f g h j k l ö ä',
      '{shift} y x c v b n m',
      '{space} {backspace}',
    ],

    numbers: [
      '1 2 3 4 5 6 7 8 9 0',
      '_ - * ! " \' § % / ( )',
      'ß ~ € ¥ ≈ √ / | [ ]',
      '{abc} {space} {backspace}',
    ],
  },
  numberLayout: {
    default: ['1 2 3', '4 5 6', '7 8 9', ' 0 {clear}'],
  },

  display: {
    '{123}': '123',
    '{abc}': 'ABC',
    '{submit}': '✓',
    '{tab}': '⇥',
    '{backspace}': '⌫',
    '{enter}': '↵',
    '{lock}': '⇪',
    '{shiftleft}': '⇧',
    '{shift}': '⇧',
    '{altleft}': '⌥',
    '{altright}': '⌥',
    '{metaleft}': '⌘',
    '{metaright}': '⌘',
    '{clear}': 'C',
  },
};
