import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { InputValidationErrorBaseComponent } from '../input-validation-error-base/input-validation-error-base.component';
import { ControlContainer, NgForm } from '@angular/forms';
import { $e } from 'codelyzer/angular/styles/chars';

@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  encapsulation: ViewEncapsulation.None,
})
export class InputTextComponent
  extends InputValidationErrorBaseComponent
  implements OnInit, OnChanges, AfterViewInit
{
  @Input() id: string;
  @Input() name: string;
  @Input() label: string;
  @Input() initValue: string;
  @Input() placeholder: string;
  @Input() required: boolean;
  @Input() pattern: string;
  @Input() type = 'text';
  @Input() form: any;
  @Input() disabled = false;
  @Input() camouflage = false;
  @Input() camouflageSize = 'p';
  @Input() ngGroupName: string = 'data';
  @Input() requiredError = 'UI.INPUT.ERRORS.EMPTY';
  @Input() patternNotValidError = 'UI.INPUT.ERRORS.PATTERN';
  @Input() appendString: string;
  @Input() noForm = false;
  @Input() showKeyboard = true;
  @Input() keyboardType = 'default';
  @Input() alwaysShowKeyboard = false;
  @Input() autofocus = false;
  @Input() maxlength = 40;
  @Input() focus = false;
  public keyboardOpen = false;
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('inputValue', { static: false }) inputEl: ElementRef;

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngAfterViewInit() {
    if (this.autofocus) {
      this.inputEl.nativeElement.focus();
    }
  }

  Validate(val = '', first = true) {
    this.ResetErrors();
    if (val === '') {
      val = this.form.value[this.ngGroupName][this.name];
    }
    if (this.disabled) {
      return;
    }
    if (this.required && val === '') {
      this.errorMsgs.push(this.requiredError);
    } else if (this.pattern) {
      let patt = new RegExp(this.pattern.toString());
      // pattern
      if (!patt.test(val)) {
        this.errorMsgs.push(this.patternNotValidError);
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['appendString'] &&
      !changes['appendString'].isFirstChange() &&
      changes['appendString'].currentValue !==
        changes['appendString'].previousValue
    ) {
      this.initValue = !this.initValue
        ? this.appendString
        : this.initValue + this.appendString;
    }

    if (this.focus) {
      this.inputEl.nativeElement.focus();
    }
  }

  public ShowKeyboard() {
    if (this.showKeyboard) {
      this.keyboardOpen = true;
    }
  }

  public HideKeyboard() {
    if (this.showKeyboard) {
      this.keyboardOpen = false;
    }
  }

  public ValueChanged(event, input) {
    this.initValue = event ? event.substr(0, this.maxlength) : event;
    this.inputEl.nativeElement.focus();

    // focus again, so the caret is showing
    setTimeout(() => {
      input.focus();
      this.inputEl.nativeElement.focus();
    }, 1);

    // focus again, so the caret is showing
    setTimeout(() => {
      input.focus();
      this.inputEl.nativeElement.focus();
    }, 10);

    // focus again, so the caret is showing
    setTimeout(() => {
      this.inputEl.nativeElement.focus();
    }, 100);

    setTimeout(() => {
      this.inputEl.nativeElement.focus();
    }, 500);

    this.Change(event);
  }

  Change($event) {
    this.valueChange.emit($event);
  }
}
