<div
  class="circle"
  *ngIf="position.position !== dreamElevenPositions.coach"
></div>
<div class="position" *ngIf="!name">
  {{ "DREAM_ELEVEN.POSITION." + position.position | translate }}
</div>
<div class="position" *ngIf="name" [innerHTML]="name">
  <!-- {{ name }} -->
</div>
