<app-translation-switch></app-translation-switch>
<app-config-pin-modal [open]="true"></app-config-pin-modal>
<!--
<div class="background-image">
  <div class="song-chooser" *ngIf="status == PottstarStates.SONG_CHOOSER">
    <div
      (click)="ChooseSong(song)"
      *ngFor="let song of songs"
      class="song-chooser__song"
    >
      <img [src]="song.imgUrl" [alt]="song.name" height="300" />
    </div>
    <div class="song-chooser__info transparent-background">
      {{ "POTTSTARS.CHOOSE_A_SONG_DE" | translate }} <br />
      {{ "POTTSTARS.CHOOSE_A_SONG_EN" | translate }}
    </div>
    <div class="song-chooser__pointer"></div>
  </div>

  <app-karaoke-player
    *ngIf="status == PottstarStates.SINGING"
    [currentSong]="currentSong"
    (songEnded)="OnSongEnd()"
    (skipSinging)="OnSongSkip()"
  ></app-karaoke-player>

  <div class="post-singing" *ngIf="status == PottstarStates.POST_SINGING">
    <span class="post-singing__message transparent-background"
      >{{ "POTTSTARS.THANKS_FOR_PARTICIPATING" | translate }}
    </span>
    <div class="button button__handwriting" (click)="StartCountdown()">
      Erinnerungsfoto
    </div>
    <div class="button btn-white button__handwriting" (click)="SingAgain()">
      {{ "POTTSTARS.SING_AGAIN" | translate }}
    </div>
  </div>

  <app-webcam-photo
    *ngIf="status == PottstarStates.TAKE_PICTURE"
    (stopCountdown)="OnStopCountdown()"
  ></app-webcam-photo>
</div>
-->

<app-input-name></app-input-name>
<!--
<div class="foreground"></div>
<app-guestbook-entry-creator [image]="image" [onSave]="onSave.asObservable()" (canvasSaved)="OnCanvasSaved($event)" [imageRotationRandom]="imageRotationRandom"> </app-guestbook-entry-creator>
<div class="abc123" style="z-index: 1001; position:absolute; top: 0; left: 0" (click)="Save()" >Save</div>

<img src="{{img}}" style="z-index: 1000; position:absolute; left: 0; top: 0; width: 250px; height: auto" *ngIf="image">
-->
