import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { ApiConnectorStationsService } from '../../core/io/stations/api-connector-stations.service';
import { ConfigService } from '../../core/config/config.service';
import { CoreConfig } from '../../core/config/core.config';
import {
  setClientId,
  setStation,
  setStationOnlyLocally,
  setStatus,
} from '../../core/store/config/config.actions';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoadStatus } from '../../core/store/definitions/load-states';
import { IsClientConfigSet } from '../../core/store/config/config.selector';
import { SubscriptionsHelperComponent } from '../../core/helper/subscriptions-helper/subscriptions-helper.component';

@Component({
  selector: 'app-check-config',
  templateUrl: './check-config.component.html',
  styleUrls: ['./check-config.component.scss'],
})
export class CheckConfigComponent
  extends SubscriptionsHelperComponent
  implements OnInit
{
  private _config: CoreConfig;
  private _clientId: string;

  constructor(
    private _store: Store,
    private _router: Router,
    private _apiConnectorStations: ApiConnectorStationsService,
    private _configService: ConfigService
  ) {
    super();

    this._config = this._configService.config;
  }

  ngOnInit(): void {
    this._clientId = localStorage.getItem(
      this._config.storage.localStoragePrefix + 'config-uuid'
    );

    if (this._clientId !== null) {
      this._store.dispatch(setClientId({ clientId: this._clientId }));
    }

    //retrieve config locally
    const localStorageConfig = this.GetLocalStorageConfig();
    //retrieve config from server
    const serverFetch = this.GetServerConfig();

    //determine which config to select
    forkJoin({ cms: serverFetch, localStorage: localStorageConfig }).subscribe(
      (result) => {
        let station;

        //if station is set in the cms
        if (result.cms !== null) {
          this._store.dispatch(
            setStatus({ status: LoadStatus.loaded, statusType: 'cms' })
          );
          station = result.cms;
        }

        //if the cms-station is set, it overrides the local station
        if (result.cms !== null) {
          if (
            result.localStorage !== null &&
            JSON.stringify(result.cms) !== JSON.stringify(result.localStorage)
          ) {
            // they differ
            this.SetLocalStorage(station);
          } else {
            // it is already set
            this._store.dispatch(
              setStatus({
                status: LoadStatus.loaded,
                statusType: 'local',
              })
            );
          }

          this.SetStoreStation(station);
        } else if (result.localStorage !== null) {
          this._store.dispatch(
            setStatus({
              status: LoadStatus.loaded,
              statusType: 'local',
            })
          );

          station = result.localStorage;

          this.SetCms(station);
        } else {
          //no valid config in either localstorage nor cms
          this._router.navigateByUrl('/client/config');
        }
      },
      (error) => {
        if (error.status === 403) {
          this._router.navigateByUrl('/client/config');
        }
      }
    );

    // there is a config - goto app
    super.AddSubscription(
      this._store.select(IsClientConfigSet()).subscribe((isSet) => {
        if (isSet) {
          this._router.navigateByUrl('/app');
        }
      })
    );
  }

  private GetLocalStorageConfig(): Observable<any> {
    let result;
    try {
      result = localStorage.getItem(
        this._config.storage.localStoragePrefix + 'config.station'
      );
    } catch (e) {
      throw Error();
    }
    return of(result !== undefined ? JSON.parse(result) : null);
  }

  private GetServerConfig(): Observable<any> {
    return this._apiConnectorStations
      .GetCurrentStationForClient(this._clientId)
      .pipe(
        map((result) => {
          if (result.rows && result.rows.length) {
            return result.rows[0];
          } else {
            return null;
          }
        })
      );
  }

  private SetLocalStorage(station): void {
    try {
      localStorage.setItem(
        this._config.storage.localStoragePrefix + 'config.station',
        JSON.stringify(station)
      );
      this._store.dispatch(
        setStatus({
          status: LoadStatus.loaded,
          statusType: 'local',
        })
      );
    } catch (e) {
      throw e;
    }
  }

  private SetCms(station) {
    this._store.dispatch(
      setStation({
        clientId: this._clientId,
        station,
      })
    );
  }

  private SetStoreStation(station): void {
    this._store.dispatch(
      setStationOnlyLocally({
        station_type: station.type,
        station_id: station.station_id,
        client_name: station.client_name,
        timeout: station.timeout,
      })
    );
  }
}
