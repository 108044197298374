import { ApiConnectorBackendSaveService } from './../../core/io/content/api-connector-backend-save.service';
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ViewEncapsulation,
} from '@angular/core';
import { Subject } from 'rxjs';
import { AppStore } from 'src/app/core/store/appStore';
import { Store } from '@ngrx/store';
import { GetContent } from 'src/app/core/store/content/content.selector';
import { StationBaseComponent } from '../base/station-base/station-base.component';
import { TranslateService } from '@ngx-translate/core';
import { Content } from 'src/app/core/store/content/content.reducer';

export enum PottstarState {
  SONG_CHOOSER,
  SINGING,
  POST_SINGING,
  TAKE_PICTURE,
  POST_PICTURE,
  SET_EMAIL,
  EMAIL_SUCCESS,
  EMAIL_ERROR,
  EMAIL_LOADING,
}

@Component({
  selector: 'app-pottstars',
  templateUrl: './pottstars.component.html',
  styleUrls: ['./pottstars.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PottstarsComponent extends StationBaseComponent implements OnInit {
  public PottstarStates = PottstarState;
  public status: PottstarState = PottstarState.SONG_CHOOSER;

  public songs;
  public currentSong;

  public takePictureSubject: Subject<void> = new Subject<void>();
  public imageLink: string;

  public email;
  public errorMsg;

  public canvasImage;

  toPostSinging: Subject<void> = new Subject<void>();

  constructor(
    protected _store: Store<AppStore>,
    protected _translateService: TranslateService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _apiBackendSaveService: ApiConnectorBackendSaveService
  ) {
    super(_store, _translateService);
  }

  ngOnInit(): void {
    this.AddSubscription(
      this._store.select(GetContent).subscribe((content: Content) => {
        this.songs = content.content[0].field_pottstars_video_serialized;
      })
    );
  }

  public ProgressState(toState) {
    this.status = toState;

    switch (this.status) {
      default:
        break;
    }
  }
  // SONG CHOOSER STATE
  public ChooseSong(song) {
    this.currentSong = song;
    this.ProgressState(PottstarState.SINGING);
  }

  // SINGING STATE
  public OnBackToSongChooser() {
    this.ProgressState(PottstarState.SONG_CHOOSER);
  }

  // POST SINGING STATE
  public StartCountdown() {
    this.ProgressState(PottstarState.TAKE_PICTURE);
  }
  public SingAgain() {
    this.ProgressState(PottstarState.SONG_CHOOSER);
  }

  // TAKE PICTURE
  public OnStopCountdown() {
    this.toPostSinging.next();
    this.ProgressState(PottstarState.SINGING);
  }

  public OnShowPicture(picture) {
    this.imageLink = picture;
    this.DrawImage(picture);
    this.ProgressState(PottstarState.POST_PICTURE);
  }

  // SET EMAIL
  public SendEmail() {
    this.ProgressState(PottstarState.SET_EMAIL);
  }

  public OnCloseModal() {
    this.ProgressState(PottstarState.POST_PICTURE);
  }

  public SetEmail(email) {
    this.email = email;
    this.ProgressState(PottstarState.EMAIL_LOADING);

    this._apiBackendSaveService
      .MailPottstarsImage(
        {
          image: this.imageLink,
          email: this.email,
        },
        this.content.language
      )
      .subscribe(
        (result) => {
          this.ProgressState(PottstarState.EMAIL_SUCCESS);
          this._changeDetectorRef.markForCheck();
        },
        (error) => {
          this.errorMsg = error.statusText;
          this.ProgressState(PottstarState.EMAIL_ERROR);
          this._changeDetectorRef.markForCheck();
        }
      );
  }

  private DrawImage(image) {
    const c = document.getElementById('canvas') as HTMLCanvasElement;
    const ctx = c.getContext('2d');

    const img = document.getElementById('image') as HTMLImageElement;
    img.src = image;
    ctx.drawImage(img, 0, 0);

    const headerBorusseum = document.getElementById(
      'header_borusseum'
    ) as HTMLImageElement;

    ctx.textAlign = 'left';

    ctx.drawImage(headerBorusseum, 0, 905, 567, 133);

    ctx.fillStyle = '#FFE600';
    ctx.font = '72px DIN';
    ctx.rotate(-(Math.PI * 2) / 360);

    ctx.fillText('BORUSSEUM', 40, 1000);

    this.canvasImage = c.toDataURL('image/jpeg');
    this.imageLink = this.canvasImage;
    this._changeDetectorRef.markForCheck();
  }
}
