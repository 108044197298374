import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  constructor(private _translateService: TranslateService) {}

  public AddTranslationsFromInitCall(rawObject: any) {
    this.AddTranslations(rawObject[0]['rows'][0], rawObject[1]['rows'][0]);
  }

  public AddTranslations(de: any, en: any) {
    this._translateService.setTranslation('de', de, true);
    this._translateService.setTranslation('en', en, true);
  }
}
