<app-standby-header [titles]="['PAGES.CHECK_CONFIG.BIG_TITLE' | translate]">
</app-standby-header>

<div class="spacer spacer-20"></div>

<div class="row">
  <div class="col-xsmall-8 col-xsmall-offset-2">
    <h2>{{ "PAGES.CHECK_CONFIG.TITLE" | translate }}</h2>
    <p>{{ "PAGES.CHECK_CONFIG.TEXT" | translate }}</p>
    <app-loading></app-loading>
  </div>
</div>

<app-alert-view></app-alert-view>
