import { Component, OnInit } from '@angular/core';
import {KeycloakService} from "keycloak-angular";

@Component({
  selector: 'app-logged-out',
  templateUrl: './logged-out.component.html',
  styleUrls: ['./logged-out.component.scss']
})
export class LoggedOutComponent implements OnInit {

  constructor(
    protected readonly keycloak: KeycloakService,
  ) {

  }

  ngOnInit(): void {
  }

  reload(){
    window.location.href = window.location.origin;
  }

  keyCloakLogin(){
    this.keycloak.login({
      redirectUri: window.location.origin,
    });
  }

}
