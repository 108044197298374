import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
  HammerGestureConfig,
  HammerModule,
} from '@angular/platform-browser';
import { APP_INITIALIZER, Injectable, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { ConfigComponent } from './configuration/config/config.component';
import { CORE_ROUTING } from './core/routing/core.routing';
import { BaseComponent } from './stations/base/base.component';
import { Store, StoreModule } from '@ngrx/store';
import * as configReducer from './core/store/config/config.reducer';
import * as contentReducer from './core/store/content/content.reducer';
import * as alertReducer from './core/alerts/store/alerts.reducer';
import { environment } from '../environments/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslationSwitchComponent } from './core/i18n/translation-switch/translation-switch.component';
import { ApiConnectorStationsService } from './core/io/stations/api-connector-stations.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { DropdownComponent } from './core/ui/dropdown/dropdown.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingComponent } from './core/ui/loading/loading.component';
import { StandbyHeaderComponent } from './core/ui/standby-header/standby-header.component';
import { InputTextComponent } from './core/ui/input-text/input-text.component';
import { InputValidationErrorBaseComponent } from './core/ui/input-validation-error-base/input-validation-error-base.component';
import { InputValidationErrorComponent } from './core/ui/input-validation-error/input-validation-error.component';
import { KeyboardComponent } from './core/ui/keyboard/keyboard.component';
import { DreamElevenComponent } from './stations/dream-eleven/dream-eleven.component';
import { InputNumberComponent } from './core/ui/input-number/input-number.component';
import * as Hammer from 'hammerjs';
import { ConfigPinModalComponent } from './core/ui/modal/config-pin-modal/config-pin-modal.component';
import { BaseModalComponent } from './core/ui/modal/base-modal/base-modal.component';
import { CheckConfigComponent } from './configuration/check-config/check-config.component';
import { EffectsModule } from '@ngrx/effects';
import { ConfigEffects } from './core/store/config/config.effects';
import { SubscriptionsHelperComponent } from './core/helper/subscriptions-helper/subscriptions-helper.component';
import { PinNumberDotsComponent } from './core/ui/pin-number-dots/pin-number-dots.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ContentEffects } from './core/store/content/content.effects';
import { KitchensinkComponent } from './core/kitchensink/kitchensink.component';
import { RefreshComponent } from './core/components/refresh/refresh.component';
import { StationBaseComponent } from './stations/base/station-base/station-base.component';

import { ProtagonistsVideoComponent } from './stations/protagonists-video/protagonists-video.component';
import { VideoPlayerComponent } from './core/ui/video-player/video-player.component';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { MovingImagesComponent } from './stations/moving-images/moving-images.component';
import { DreamElevenPositionMarkerComponent } from './stations/dream-eleven/position-marker/dream-eleven-position-marker/dream-eleven-position-marker.component';
import { QuizComponent } from './stations/quiz/quiz.component';
import { QuizQuestionComponent } from './stations/quiz/quiz-question/quiz-question.component';
import { QuizHighscoreComponent } from './stations/quiz/quiz-highscore/quiz-highscore.component';
import { QuizNameComponent } from './stations/quiz/quiz-name/quiz-name.component';
import { QuizProgressComponent } from './stations/quiz/quiz-progress/quiz-progress.component';
import { DreamElevenChoosePlayerComponent } from './core/ui/modal/dream-eleven-choose-player/dream-eleven-choose-player.component';
import { AlertViewComponent } from './core/alerts/alert-view/alert-view.component';
import { DreamElevenChooseFormationComponent } from './core/ui/modal/dream-eleven-choose-formation/dream-eleven-choose-formation.component';

import { InputNameComponent } from './core/ui/input-name/input-name.component';
import { InputEmailComponent } from './core/ui/input-email/input-email.component';
import { PottstarsComponent } from './stations/pottstars/pottstars.component';
import { GuestbookEntryCreatorComponent } from './core/ui/guestbook-entry-creator/guestbook-entry-creator.component';
import { MoveAroundDirective } from './core/ui/guestbook-entry-creator/directives/move-around.directive';
import { GuestbookComponent } from './stations/guestbook/guestbook.component';
import { InputTextfieldComponent } from './core/ui/input-textfield/input-textfield.component';
import { ChronikComponent } from './stations/chronik/chronik.component';

import { KaraokePlayerComponent } from './stations/pottstars/karaoke-player/karaoke-player.component';
import { TakePictureComponent } from './stations/pottstars/webcam-photo/take-picture/take-picture.component';
import {HttpAuthInterceptor} from "./core/io/http-interceptor/http-auth.interceptor";
import { LoggedOutComponent } from './core/components/logged-out/logged-out.component';
@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  events = ['config-click-event', 'config-click-event-too-many', 'pan'];
  overrides = {
    tap: { taps: 1 },
  };

  buildHammer(element: HTMLElement) {
    const mc = new Hammer.Manager(element, { touchAction: 'auto' });
    // create mulit-tap-event for activating config
    const configTap = new Hammer.Tap({ event: 'config-click-event', taps: 5 });
    const pan = new Hammer.Pan({ event: 'pan', pointers: 1 });
    const tooManyConfigTap = new Hammer.Tap({
      event: 'config-click-event-too-many',
      taps: 6,
    });
    tooManyConfigTap.recognizeWith([configTap]);

    configTap.requireFailure(tooManyConfigTap);
    mc.add([configTap, tooManyConfigTap, pan]);
    return mc;
  }
}

function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: 'https://keycloak.liechtenecker.dev/auth',
        realm: 'Liechtenecker',
        clientId: 'borusseum-frontend',
      },
      initOptions: {
        onLoad: 'check-sso',
        checkLoginIframe: false,
      },
    });
}

@NgModule({
  declarations: [
    AppComponent,
    ConfigComponent,
    BaseComponent,
    BaseModalComponent,
    TranslationSwitchComponent,
    DropdownComponent,
    LoadingComponent,
    StandbyHeaderComponent,
    InputTextComponent,
    InputValidationErrorBaseComponent,
    InputValidationErrorComponent,
    KeyboardComponent,
    DreamElevenComponent,
    InputNumberComponent,
    ConfigPinModalComponent,
    CheckConfigComponent,
    SubscriptionsHelperComponent,
    PinNumberDotsComponent,
    KitchensinkComponent,
    RefreshComponent,
    StationBaseComponent,
    ProtagonistsVideoComponent,
    VideoPlayerComponent,
    MovingImagesComponent,
    DreamElevenPositionMarkerComponent,
    QuizComponent,
    QuizQuestionComponent,
    QuizHighscoreComponent,
    QuizNameComponent,
    QuizProgressComponent,
    DreamElevenChoosePlayerComponent,
    AlertViewComponent,
    DreamElevenChooseFormationComponent,
    KaraokePlayerComponent,
    TakePictureComponent,
    InputNameComponent,
    InputEmailComponent,
    PottstarsComponent,
    GuestbookEntryCreatorComponent,
    MoveAroundDirective,
    GuestbookComponent,
    InputTextfieldComponent,
    ChronikComponent,
    LoggedOutComponent,
  ],
  imports: [
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    CORE_ROUTING,
    HttpClientModule,
    FormsModule,
    TranslateModule.forRoot(),
    StoreModule.forRoot({
      config: configReducer.reducer,
      content: contentReducer.reducer,
      alerts: alertReducer.reducer,
    }),
    EffectsModule.forRoot([ConfigEffects, ContentEffects]),
    environment.deployment !== 'production'
      ? StoreDevtoolsModule.instrument({
          maxAge: 50,
        })
      : [],
    HammerModule,
    KeycloakAngularModule,
  ],
  providers: [
    TranslateService,
    ApiConnectorStationsService,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpAuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private _store: Store) {}
}
