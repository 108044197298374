import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppStore } from '../../core/store/appStore';
import { Store } from '@ngrx/store';
import {
  fetchQuizQuestions,
  setDynamicStationContent,
} from '../../core/store/content/content.actions';
import { Content } from '../../core/store/content/content.reducer';
import { QuizQuestionType } from './quiz-question/quiz-question-type';
import { StandbyService } from '../../core/standby/standby.service';
import { GetContent } from '../../core/store/content/content.selector';
import { LoadStatus } from '../../core/store/definitions/load-states';

enum QuizStatus {
  'INIT',
  'PROGRESS',
}

@Injectable({
  providedIn: 'root',
})
export class QuizService {
  private _currentQuestion: number = 0;
  private _quizStatus: QuizStatus;
  private _baseURL: string;
  private _points;
  private _totalQuestions;
  private _waitForQuestions;

  constructor(
    private _router: Router,
    private _store: Store<AppStore>,
    private _standbyService: StandbyService
  ) {
    this._store.dispatch(fetchQuizQuestions());
  }

  public Init() {
    this._currentQuestion = 0;
    this._quizStatus = QuizStatus.INIT;
    this._baseURL = this._router.url;

    /*
    this._router.navigateByUrl(
      this._router.url + '/highscore/' + 425 + '/Franz'
    );*/
    /*
    this._router.navigateByUrl(
      this._router.url + '/highscore/' + Math.round(Math.random() * 255)
    );*/
  }

  public Start(content: Content) {
    if (this._quizStatus === QuizStatus.PROGRESS) return;

    this._standbyService.StopStandByTimer(true);

    this._quizStatus = QuizStatus.PROGRESS;
    this._points = 0;
    this.WaitForQuestions();
  }

  public WaitForQuestions() {
    this._waitForQuestions = this._store
      .select(GetContent)
      .subscribe((content: Content) => {
        if (!content.quiz || !content.quiz.valid) {
          if (!content.quiz || content.quiz.status !== LoadStatus.loading) {
            console.warn('no valid quiz questions present, fetch new');
            this._store.dispatch(fetchQuizQuestions());
          }
        } else {
          if (this._waitForQuestions) this._waitForQuestions.unsubscribe();
          this._totalQuestions = content.quiz.questions.length;
          this.GoToQuestion();
        }
      });
  }

  public NextQuestion() {
    if (this._currentQuestion + 1 >= this._totalQuestions) {
      this._store.dispatch(
        setDynamicStationContent({
          dynamicStationContent: {},
        })
      );
      this._standbyService.UnlockTimer();
      this._standbyService.RestartStandbyTimer();
      this._router.navigateByUrl(
        this._router.url + '/highscore/' + this._points
      );
    } else {
      this._currentQuestion++;
      this.GoToQuestion();
    }
  }

  public AddPoints(timeForQuestion, progress, bonus: QuizQuestionType) {
    const basePoints = (timeForQuestion / 10) * 100;
    console.log('basePoints', basePoints);

    const subtract = Math.floor((1 - progress) * timeForQuestion * 10);
    console.log('substract', subtract);

    let points = basePoints - subtract;

    switch (bonus) {
      case QuizQuestionType.BONUS_1:
        points *= 2;
        break;
      case QuizQuestionType.BONUS_2:
        points *= 3;
        break;
      case QuizQuestionType.BONUS_3:
        points *= 5;
        break;
    }

    this._points += points;
    console.log('totalPoints', this._points);
  }

  private GoToQuestion() {
    this._store.dispatch(
      setDynamicStationContent({
        dynamicStationContent: {
          question: this._currentQuestion,
        },
      })
    );
  }
}
