<div class="modal__close" (click)="Close()"></div>
<form class="select-station-form" #emailForm="ngForm">
  <app-input-text
    [label]="'field_enter_your_email_text' | translate"
    [placeholder]=""
    [form]="emailForm"
    [required]="false"
    name="email"
    [initValue]=""
    [showKeyboard]="true"
    [alwaysShowKeyboard]="true"
    [keyboardType]="'email'"
    [autofocus]="true"
    maxlength="35"
  ></app-input-text>

  <div class="button-wrapper" @nextButtonInOut>
    <div class="button button__handwriting" *ngIf="formValid" (click)="Save()">
      {{ "UI.KEYBOARD.field_send_email_button" | translate }}
    </div>
  </div>
</form>
