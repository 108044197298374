import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { StationBaseComponent } from '../base/station-base/station-base.component';
import { AppStore } from '../../core/store/appStore';
import { Store } from '@ngrx/store';
import { QuizService } from './quiz.service';
import { GetContent } from '../../core/store/content/content.selector';
import { Content } from '../../core/store/content/content.reducer';
import { StationStatus } from '../../core/store/definitions/station-states';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss'],
})
export class QuizComponent extends StationBaseComponent implements OnInit {
  public stationStatus;
  public currentQuestion;

  constructor(
    protected _store: Store<AppStore>,
    protected _translateService: TranslateService,
    private _quizService: QuizService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {
    super(_store, _translateService);

    this._quizService.Init();

    this.AddSubscription(
      this._store.select(GetContent).subscribe((content: Content) => {
        if (content.stationStatus === StationStatus.MAIN) {
          this._quizService.Start(content);
        }

        this.UpdateStationStatus(content);
      })
    );
  }

  ngOnInit(): void {}

  private UpdateStationStatus(content) {
    if (!content.dynamicStationContent) return;

    if (
      !this.stationStatus ||
      content.dynamicStationContent.question !== this.stationStatus.question
    ) {
      this.stationStatus = content.dynamicStationContent;

      this.currentQuestion =
        content.quiz.questions[this.stationStatus.question];

      this._changeDetectorRef.markForCheck();
    }
  }
}
