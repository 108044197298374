import { devAPIUrl } from './core.config.user';

export enum ConfigTypes {
  development = 1,
  staging = 2,
  production = 3,
}

/**
 * Development config, used for local testing and development
 */
export const coreConfigDevelopment: CoreConfig = {
  type: ConfigTypes.development,
  api: {
    baseUrl: devAPIUrl,
    assetUrl: devAPIUrl + 'storage/app/public/',
    loginURL: '/login',
  },
  system: {
    baseUrl: '/app',
  },
  auth: {
    url: devAPIUrl + '/auth/token',
  },
  storage: {
    localStoragePrefix: 'bor-dev-',
    tokenName: 'token',
    idTokenName: 'id_token',
    refreshTokenName: 'refresh_token',
    expires: 'expires',
    user: 'user',
    roleId: 'role',
  },
  assets: {
    baseUrl: '',
  },
  version: '0.0.1',
};

/**
 * Config for test server
 */
export const coreConfigTesting: CoreConfig = {
  type: ConfigTypes.staging,
  api: {
    baseUrl: devAPIUrl,
    assetUrl: devAPIUrl + 'storage/app/public/',
    loginURL: '/login',
  },
  system: {
    baseUrl: '/app',
  },
  auth: {
    url: devAPIUrl + '/auth/token',
  },
  storage: {
    localStoragePrefix: 'bor-dev-',
    tokenName: 'token',
    idTokenName: 'id_token',
    refreshTokenName: 'refresh_token',
    expires: 'expires',
    user: 'user',
    roleId: 'role',
  },
  assets: {
    baseUrl: '',
  },
  version: '0.0.1',
};

/**
 * config for production usage
 */
export const coreConfigProduction: CoreConfig = {
  type: ConfigTypes.production,
  api: {
    baseUrl: devAPIUrl,
    assetUrl: devAPIUrl + 'storage/app/public/',
    loginURL: '/login',
  },
  system: {
    baseUrl: '/app',
  },
  auth: {
    url: devAPIUrl + '/auth/token',
  },
  storage: {
    localStoragePrefix: 'bor-production-',
    tokenName: 'token',
    idTokenName: 'id_token',
    refreshTokenName: 'refresh_token',
    expires: 'expires',
    user: 'user',
    roleId: 'role',
  },
  assets: {
    baseUrl: '/main',
  },
  version: '0.0.1',
};

export interface CoreConfig {
  type: ConfigTypes;
  api: {
    baseUrl: string;
    assetUrl: string;

    loginURL: string;
  };
  system: {
    baseUrl: string;
  };
  auth: {
    url: string;
  };
  storage: {
    localStoragePrefix: string;
    tokenName: string;
    refreshTokenName: string;
    idTokenName: string;
    expires: string;
    user: string;
    roleId: string;
  };
  assets: {
    baseUrl: string;
  };
  version: string;
}
