<app-translation-switch></app-translation-switch>
<ng-container *ngIf="currentQuestion" [ngSwitch]="stationStatus?.question">
  <ng-container *ngSwitchDefault>
    <app-quiz-question
      [data]="currentQuestion"
      [language]="currentLanguage"
    ></app-quiz-question>
  </ng-container>
</ng-container>
<router-outlet></router-outlet>

<app-quiz-progress *ngIf="currentQuestion"></app-quiz-progress>

<div
  *ngIf="content.stationStatus === StationStati.STANDBY"
  class="cta-multiple"
>
  <div class="cta-multiple__play"></div>

  <div class="cta-multiple__text cta-multiple__text--bottom">
    {{ "STANDBY.TOUCH_TO_START_DE" | translate }}<br />
    {{ "STANDBY.TOUCH_TO_START_EN" | translate }}<br />
  </div>
</div>
