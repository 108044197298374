import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { ClientConfig } from '../../core/store/config/config.reducer';
import { Store } from '@ngrx/store';
import { GetClientConfig } from '../../core/store/config/config.selector';
import { ApiConnectorStationsService } from '../../core/io/stations/api-connector-stations.service';
import { Router } from '@angular/router';
import { NavigationService } from '../../core/navigation/navigation.service';
import {
  GetContentLoadStatus,
  GetStationStatus,
} from '../../core/store/content/content.selector';
import { LoadStatus } from '../../core/store/definitions/load-states';
import {
  fetchContent,
  setHeaderStatus,
  setStationStatus,
} from '../../core/store/content/content.actions';
import { combineLatest } from 'rxjs';
import { StationStatus } from '../../core/store/definitions/station-states';
import { StandbyService } from '../../core/standby/standby.service';
import { HeaderStatus } from '../../core/store/definitions/header-states';
import { StationBaseComponent } from './station-base/station-base.component';
import { StationTypes } from '../../core/store/definitions/stationTypes';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseComponent extends StationBaseComponent implements OnInit {
  config: ClientConfig;
  public stationStatus: StationStatus;
  public openConfigPinModal = false;
  public headerMode: HeaderStatus = HeaderStatus.STANDBY;

  constructor(
    protected store: Store,
    private _apiConnectorStations: ApiConnectorStationsService,
    private _standbyService: StandbyService,
    private _router: Router,
    private _navigationService: NavigationService,
    private _changeDetectorRef: ChangeDetectorRef,
    protected _translateService: TranslateService
  ) {
    super(store, _translateService);
  }

  ngOnInit(): void {
    combineLatest([
      this.store.select(GetContentLoadStatus()),
      this.store.select(GetClientConfig()),
    ]).subscribe(([contentStatus, clientConfig]) => {
      if (
        contentStatus === LoadStatus.init &&
        clientConfig.statuses.cms === LoadStatus.loaded
      ) {
        this.store.dispatch(fetchContent({ config: this.config }));
      }

      if (
        contentStatus === LoadStatus.loaded &&
        clientConfig.statuses.store === LoadStatus.loaded
      ) {
        if (this._router.routerState.snapshot.url === '/app') {
          this._standbyService.Init();
          this.store.dispatch(
            setStationStatus({ status: StationStatus.STANDBY })
          );
          this.store.dispatch(
            setHeaderStatus({ headerStatus: HeaderStatus.STANDBY })
          );

          this._router.navigate([
            '/app/',
            clientConfig.station_type,
            clientConfig.station_id !== undefined ? clientConfig.station_id : 1,
          ]);
        }
      }
    });

    this.store.select(GetStationStatus()).subscribe((stationStatus) => {
      this.stationStatus = stationStatus;
    });
  }

  public GotoConfig(tapEvent) {
    this.openConfigPinModal = true;
  }

  public DoNothing(event) {}

  public StopStandby() {
    this.store.dispatch(setStationStatus({ status: StationStatus.MAIN }));

    switch (this.config.station_type) {
      case StationTypes.PROTANGONISTS_VIDEOS:
        this.store.dispatch(
          setHeaderStatus({ headerStatus: HeaderStatus.HIDDEN })
        );
        break;
      default:
        this.store.dispatch(
          setHeaderStatus({ headerStatus: HeaderStatus.IN_USE })
        );
    }
  }
}
