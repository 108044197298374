<div class="modal__close" (click)="Close()"></div>
<form class="select-station-form" #nameForm="ngForm">
  <app-input-text
    [label]="'field_enter_your_name_text' | translate"
    [placeholder]=""
    [form]="nameForm"
    [required]="false"
    name="name"
    [initValue]="name"
    [showKeyboard]="true"
    [alwaysShowKeyboard]="true"
    [keyboardType]="'name'"
    [autofocus]="true"
    maxlength="35"
  ></app-input-text>

  <div class="button-wrapper" @nextButtonInOut>
    <div class="button button__handwriting" *ngIf="formValid" (click)="Save()">
      {{ "UI.KEYBOARD.field_submit_button_text" | translate }}
    </div>
  </div>
</form>
