<app-translation-switch></app-translation-switch>

<div class="background-image">
  <!-- SONG CHOOSER STATE -->
  <div class="song-chooser" *ngIf="status == PottstarStates.SONG_CHOOSER">
    <div
      (click)="ChooseSong(song)"
      *ngFor="let song of songs"
      class="song-chooser__song"
    >
      <img
        [src]="
          song.field_video_thumbnail._serialized.field_media_image._url.absolute
        "
        [alt]="song.field_video_title"
        height="300"
        width="406"
      />
    </div>
    <div class="song-chooser__info transparent-background">
      {{ "POTTSTARS.CHOOSE_A_SONG_DE" | translate }} <br />
      {{ "POTTSTARS.CHOOSE_A_SONG_EN" | translate }}
    </div>
    <div class="song-chooser__pointer"></div>
  </div>

  <!-- SINGING STATE -->
  <app-karaoke-player
    *ngIf="status == PottstarStates.SINGING"
    [currentSong]="currentSong"
    (startCountdown)="StartCountdown()"
    (backToSongChooser)="OnBackToSongChooser()"
    [toPostSinging]="toPostSinging.asObservable()"
  ></app-karaoke-player>

  <!-- TAKE PICTURE STATE -->
  <app-take-picture
    *ngIf="status == PottstarStates.TAKE_PICTURE"
    station="POTTSTARS"
    (showPicture)="OnShowPicture($event)"
    (stopCountdown)="OnStopCountdown()"
    [HEIGHT]="1080"
    [WIDTH]="1920"
  ></app-take-picture>

  <!-- IMAGE -->
  <div
    *ngIf="
      status == PottstarStates.POST_PICTURE ||
      status == PottstarStates.SET_EMAIL ||
      status == PottstarStates.EMAIL_ERROR ||
      status == PottstarStates.EMAIL_LOADING ||
      status == PottstarStates.EMAIL_SUCCESS
    "
    class="image__container"
  >
    <img [src]="imageLink" alt="image" class="image" />

    <!-- POST PICTURE STATE -->
    <div
      *ngIf="status == PottstarStates.POST_PICTURE"
      class="bottom-button-container"
    >
      <div
        class="button button__handwriting button__back"
        (click)="StartCountdown()"
        [innerHTML]="'field_again_text' | translate"
      ></div>

      <div
        class="button button__handwriting button__email"
        (click)="SendEmail()"
        [innerHTML]="'field_send_per_e_mail_text' | translate"
      ></div>
    </div>

    <!-- EMAIL SUCCESS STATE -->
    <div
      class="bottom-button-container__col"
      *ngIf="status === PottstarStates.EMAIL_SUCCESS"
    >
      <div class="bottom-button-container__heading">
        <div class="bottom-button-container__email-icon"></div>
        <div>
          {{ "field_e_mail_success_text" | translate }}
        </div>
      </div>

      <div class="button button__handwriting" (click)="SingAgain()">
        {{ "field_sing_again_text" | translate }}
      </div>
    </div>
  </div>

  <!-- SET EMAIL STATE -->
  <app-input-email
    *ngIf="status === PottstarStates.SET_EMAIL"
    (formData)="SetEmail($event)"
    (closeModal)="OnCloseModal()"
    class="on-black"
  >
  </app-input-email>
  <div
    class="button button__handwriting button__cancel-restart"
    (click)="OnBackToSongChooser()"
    *ngIf="status === PottstarStates.SET_EMAIL"
    [innerHTML]="'POTTSTARS.CANCEL_AND_RESTART' | translate"
  ></div>

  <!-- EMAIL LOADING STATE -->
  <div class="email-success" *ngIf="status === PottstarStates.EMAIL_LOADING">
    <app-loading></app-loading>
  </div>

  <!-- EMAIL ERROR STATE -->
  <div class="email-success" *ngIf="status === PottstarStates.EMAIL_ERROR">
    <div class="error-message">{{ errorMsg }}</div>
    <div class="button button__handwriting back-button" (click)="SendEmail()">
      {{ "field_back_to_e_mail_text" | translate }}
    </div>
  </div>

  <!-- DARK BACKGROUND FOR EMAIL -->
  <div
    class="bg"
    *ngIf="
      status === PottstarStates.SET_EMAIL ||
      status === PottstarStates.EMAIL_ERROR ||
      status === PottstarStates.EMAIL_LOADING
    "
  ></div>
</div>

<div style="display: none">
  <canvas id="canvas"></canvas>
  <img [src]="imageLink" id="image" />
  <img
    src="../../../assets/ui/borusseum-header-canvas.svg"
    id="header_borusseum"
  />
</div>
