import { Action, createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { LoadStatus } from '../definitions/load-states';
import {
  setClientId,
  setStandbyTime,
  setStation,
  setStationOnCMSSuccess,
  setStationOnlyLocally,
  setStationSuccess,
  setStatus,
} from './config.actions';
import { StationStatus } from '../definitions/station-states';
import { StationTypes } from '../definitions/stationTypes';

export interface ClientConfig {
  statuses: {
    store: LoadStatus;
    local: LoadStatus;
    cms: LoadStatus;
  };
  clientId: string;
  client_name: string;
  station_type: StationTypes;
  station_id: number;
  timeout: number;
  stationStatus: string;
}

export const initialState: ClientConfig = {
  statuses: {
    store: LoadStatus.init,
    local: LoadStatus.init,
    cms: LoadStatus.init,
  },
  clientId: undefined,
  client_name: undefined,
  station_type: undefined,
  station_id: undefined,
  timeout: 30,
  stationStatus: StationStatus.STANDBY,
};

const configReducer = createReducer(
  initialState,
  on(setStationOnlyLocally, (state, action) => {
    // only locally

    state = cloneDeep(state);
    state.station_type = action.station_type;
    state.station_id = action.station_id ? action.station_id : undefined;
    state.client_name = action.client_name ? action.client_name : undefined;
    state.timeout = action.timeout ? action.timeout : initialState.timeout;
    state.statuses.store = LoadStatus.loaded;

    return state;
  }),
  on(setStation, (state, action) => {
    // everywhere, therefor the statuses behave differently
    state = cloneDeep(state);
    state.station_type = action.station.station_type;
    state.station_id = action.station.station_id
      ? action.station.station_id
      : undefined;
    state.client_name = action.station.client_name
      ? action.station.client_name
      : undefined;
    state.timeout = action.station.timeout
      ? action.station.timeout
      : initialState.timeout;
    state.statuses.store = LoadStatus.loading;
    state.statuses.cms = LoadStatus.loading;

    return state;
  }),
  on(setStationSuccess, (state, action) => {
    // everywhere, therefor the statuses behave differently

    state = cloneDeep(state);
    state.statuses.store = LoadStatus.loaded;
    state.statuses.cms = LoadStatus.loaded;

    return state;
  }),

  on(setStatus, (state, action) => {
    state = cloneDeep(state);
    state.statuses[action.statusType] = action.status;

    return state;
  }),

  on(setStationOnCMSSuccess, (state: ClientConfig, action) => {
    state = cloneDeep(state);
    state.statuses.cms = LoadStatus.loading;
    return state;
  }),

  on(setStandbyTime, (state: ClientConfig, action) => {
    state = cloneDeep(state);
    state.timeout = action.timeout;

    return state;
  }),

  on(setClientId, (state: ClientConfig, action) => {
    state = cloneDeep(state);
    state.clientId = action.clientId;

    return state;
  })
);

export function reducer(state: ClientConfig | undefined, action: Action) {
  return configReducer(state, action);
}
