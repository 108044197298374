<div class="countdown">
  <span *ngIf="countdownNumber > 0" class="countdown__number">
    {{ countdownNumber }}
  </span>
  <div class="pottstar-image" *ngIf="countdownNumber < 1">
    <div class="pottstar-image__text">
      {{ station + ".TAKE_PICTURE" | translate }}
    </div>
  </div>

  <div class="video-container" *ngIf="!error || !isCaptured">
    <video
      [ngClass]="{ visible: !isCaptured, invisible: isCaptured }"
      #video
      id="video"
      [width]="WIDTH"
      [height]="HEIGHT"
      autoplay
    ></video>
    <canvas
      [ngClass]="{ visible: isCaptured, invisible: !isCaptured }"
      #canvas
      id="canvas"
      [width]="WIDTH"
      [height]="HEIGHT"
    ></canvas>
  </div>

  <div class="button button__handwriting btn-white" (click)="StopCountdown()">
    {{ "POTTSTARS.STOP" | translate }}
  </div>
</div>

<div id="preloader"></div>
