import { DreamElevenPositions } from './../../positions';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-dream-eleven-position-marker',
  templateUrl: './dream-eleven-position-marker.component.html',
  styleUrls: ['./dream-eleven-position-marker.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DreamElevenPositionMarkerComponent implements OnInit {
  @Input() position: {
    position: string;
    x?: number;
    y?: number;
  };

  @Input() name: string;
  public dreamElevenPositions = DreamElevenPositions;

  constructor() {}

  ngOnInit(): void {}
}
