import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-pin-number-dots',
  templateUrl: './pin-number-dots.component.html',
  styleUrls: ['./pin-number-dots.component.scss'],
})
export class PinNumberDotsComponent implements OnInit, OnChanges {
  @Input() maxPins: number;
  @Input() currentPinCount: number;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentPinCount) {
      this.currentPinCount = this.currentPinCount;
    }
  }
}
