import {
  Directive,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { SubscriptionsHelperComponent } from '../../../helper/subscriptions-helper/subscriptions-helper.component';
import { DOCUMENT } from '@angular/common';
import {fromEvent, merge, Subscription} from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appMoveAround]',
})
export class MoveAroundDirective implements OnInit, OnDestroy {
  private _element: HTMLElement;
  private _subscriptions = [];

  constructor(
    private _elementRef: ElementRef,
    @Inject(DOCUMENT) private _document: any
  ) {}

  ngOnInit() {
    this._element = this._elementRef.nativeElement as HTMLElement;
    this.InitDrag();
  }

  ngOnDestroy() {
    this._subscriptions.forEach((sub) => {
      if (sub) sub.unsubscribe();
    });
  }

  private InitDrag() {
    const mousedownEvent = fromEvent(this._element, 'mousedown');
    const touchstartEvent = fromEvent(this._element, 'touchstart');

    const mousemoveEvent = fromEvent(this._document, 'mousemove');
    const touchmoveEvent = fromEvent(this._document, 'touchmove');

    const mouseupEvent = fromEvent(this._document, 'mouseup');
    const touchendEvent = fromEvent(this._document, 'touchend');




    const dragStart$ = merge(mousedownEvent, touchstartEvent);
    const dragEnd$ = merge(mouseupEvent, touchendEvent);
    const drag$ = merge(mousemoveEvent, touchmoveEvent).pipe(
      takeUntil(dragEnd$)
    );

    let initialX: number,
      initialY: number,
      currentX = 0,
      currentY = 0;

    let dragSub: Subscription;

    const dragStartSub = dragStart$.subscribe((event: MouseEvent | TouchEvent) => {

      const x = event.type === "touchstart" ? event["touches"]["0"]["clientX"] : event["clientX"];
      const y = event.type === "touchstart" ? event["touches"]["0"]["clientY"] : event["clientY"];


      initialX = x - currentX;
      initialY = y - currentY;
      this._element.classList.add('move-around--active');

      // 4
      dragSub = drag$.subscribe((event: MouseEvent | TouchEvent) => {
        event.preventDefault();

        const x = event.type === "touchmove" ? event["touches"]["0"]["clientX"] : event["clientX"];
        const y = event.type === "touchmove" ? event["touches"]["0"]["clientY"] : event["clientY"];

        currentX = x - initialX;
        currentY = y - initialY;

        this._element.style.transform =
          'translateX(' + Math.round(currentX) + 'px) translateY(' + Math.round(currentY) + 'px)';
      });
    });

    // 5
    const dragEndSub = dragEnd$.subscribe(() => {
      initialX = currentX;
      initialY = currentY;
      this._element.classList.remove('move-around--active');
      if (dragSub) {
        dragSub.unsubscribe();
      }
    });

    // 6

    this._subscriptions.push(dragStartSub, dragEndSub, dragSub);
  }
}
