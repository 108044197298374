import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { IsClientConfigSet } from '../core/store/config/config.selector';
import { mergeMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class ConfigExistsGuard implements CanActivate {
  constructor(private _store: Store, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    return this._store.select(IsClientConfigSet()).pipe(
      take(1),
      mergeMap((isConfigSet) => {
        if (isConfigSet) {
          return of(true);
        } else {
          this.router.navigateByUrl('/check-config');
        }
      })
    );
  }
}
