import { Component, OnInit } from '@angular/core';
import { StationBaseComponent } from '../base/station-base/station-base.component';
import { AppStore } from '../../core/store/appStore';
import { Store } from '@ngrx/store';
import { GetContent } from '../../core/store/content/content.selector';
import { ClientConfig } from '../../core/store/config/config.reducer';
import { StationStatus } from '../../core/store/definitions/station-states';
import { VideoEvents } from '../../core/ui/video-player/video-player.component';
import {
  setHeaderStatus,
  setStationStatus,
} from '../../core/store/content/content.actions';
import { HeaderStatus } from '../../core/store/definitions/header-states';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-protagonists-video',
  templateUrl: './protagonists-video.component.html',
  styleUrls: ['./protagonists-video.component.scss'],
})
export class ProtagonistsVideoComponent
  extends StationBaseComponent
  implements OnInit
{
  public parsedContent;
  public videoAction;

  constructor(
    protected store: Store<AppStore>,
    protected _translateService: TranslateService
  ) {
    super(store, _translateService);

    this.AddSubscription(
      this.store.select(GetContent).subscribe((content: ClientConfig) => {
        switch (content.stationStatus) {
          case StationStatus.MAIN:
            this.videoAction = VideoEvents.PLAY;
        }
      })
    );
  }

  ngOnInit(): void {}

  public VideoEvent($event: VideoEvents) {
    switch ($event) {
      case VideoEvents.END:
        this.videoAction = VideoEvents.END;

        this.store.dispatch(
          setStationStatus({ status: StationStatus.STANDBY })
        );
        this.store.dispatch(
          setHeaderStatus({ headerStatus: HeaderStatus.STANDBY })
        );
        break;
    }
  }
}
