import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import * as AlertActions from './alerts.actions';
import { ALERT_TYPES } from './alerts.actions';
import * as cloneDeep from 'lodash/cloneDeep';
import { GetUuid } from '../../helper/uuid';

export interface State {
  messages: Array<{
    title: string;
    message: string;
    type: string;
    duration: number;
    data?: any;
    id: string;
  }>;
}

export const initialState: State = {
  messages: [],
};

const alertReducer = createReducer(
  initialState,
  on(AlertActions.alertError, (state, action) => {
    state = cloneDeep(state);
    state.messages.push({
      title: action.title,
      message: action.message,
      duration: action.duration,
      type: ALERT_TYPES.ALERT,
      id: GetUuid(),
    });
    return state;
  }),
  on(AlertActions.alertSuccess, (state, action) => {
    state = cloneDeep(state);
    state.messages.push({
      title: action.title,
      message: action.message,
      duration: action.duration,
      type: ALERT_TYPES.SUCCESS,
      id: GetUuid(),
    });
    return state;
  }),
  on(AlertActions.alertInfo, (state, action) => {
    state = cloneDeep(state);
    state.messages.push({
      title: action.title,
      message: action.message,
      duration: action.duration,
      type: ALERT_TYPES.INFO,
      id: GetUuid(),
    });
    return state;
  }),
  on(AlertActions.alertUpdate, (state, action) => {
    state = cloneDeep(state);
    state.messages.push({
      title: 'ALERTS.UPDATE.TITLE',
      message: 'ALERTS.UPDATE.MESSAGE',
      duration: -1,
      type: ALERT_TYPES.UPDATE,
      id: GetUuid(),
      data: {
        versionOld: action.versionOld,
        versionNew: action.versionNew,
      },
    });
    return state;
  })
);

export function reducer(state: State | undefined, action: Action) {
  return alertReducer(state, action);
}

export const GetLastAlert = createFeatureSelector<any>('alerts');

export const SelectLastAlert = () =>
  createSelector(GetLastAlert, (alerts) => {
    if (alerts.messages.length) {
      return alerts.messages[alerts.messages.length - 1];
    } else {
      return;
    }
  });
