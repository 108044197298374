import { DoublePage } from './../../../stations/guestbook/guestbook.component';
import { createAction, props } from '@ngrx/store';
import { HeaderStatus } from '../definitions/header-states';
import { LanguageCode } from '../definitions/languages';
import { ClientConfig } from '../config/config.reducer';
import { StationStatus } from '../definitions/station-states';

export const enum CONTENT_ACTIONS {
  CONTENT_SET_LANGUAGE = '[CONTENT] SET_LANGUAGE',
  CONTENT_FETCH_CONTENT = '[CONTENT] FETCH_CONTENT',
  CONTENT_FETCH_CONTENT_SUCCESS = '[CONTENT] FETCH_CONTENT_SUCCESS',
  CONTENT_FETCH_CONTENT_ERROR = '[CONTENT] FETCH_CONTENT_ERROR',
  CONTENT_SET_STATION_STATUS = '[CONTENT] SET_STATION_STATUS',
  CONTENT_SET_HEADER_STATUS = '[CONTENT] SET_HEADER_STATUS',
  CONTENT_TOGGLE_HEADER_STATUS = '[CONTENT] TOGGLE_HEADER_STATUS',
  CONTENT_SET_DYNAMIC_STATION_CONTENT = '[CONTENT] CONTENT_SET_DYNAMIC_STATION_CONTENT',
  CONTENT_FETCH_QUIZ_QUESTIONS = '[CONTENT] FETCH_QUIZ_QUESTIONS',
  CONTENT_FETCH_QUIZ_QUESTIONS_SUCCESS = '[CONTENT] FETCH_QUIZ_QUESTIONS_SUCCESS',
  CONTENT_FETCH_QUIZ_QUESTIONS_ERROR = '[CONTENT] FETCH_QUIZ_QUESTIONS_ERROR',
  CONTENT_FETCH_DREAM_ELEVEN_PERSONS = '[CONTENT] FETCH_DREAM_ELEVEN_PERSONS',
  CONTENT_FETCH_DREAM_ELEVEN_PERSONS_SUCCESS = '[CONTENT] CONTENT_FETCH_DREAM_ELEVEN_PERSONS_SUCCESS',
  CONTENT_FETCH_DREAM_ELEVEN_PERSONS_ERROR = '[CONTENT] CONTENT_FETCH_DREAM_ELEVEN_PERSONS_ERROR',
  CONTENT_FETCH_GUESTBOOK_PAGES = '[CONTENT] FETCH_GUESTBOOK_PAGES',
  CONTENT_FETCH_GUESTBOOK_PAGES_SUCCESS = '[CONTENT] FETCH_GUESTBOOK_PAGES_SUCCESS',
  CONTENT_FETCH_GUESTBOOK_PAGES_ERROR = '[CONTENT] FETCH_GUESTBOOK_PAGES_ERROR',
  CONTENT_ADD_GUESTBOOK_PAGE = '[CONTENT] ADD_GUESTBOOK_PAGE',
  CONTENT_SET_GUESTBOOK_PAGE = '[CONTENT] SET_GUESTBOOK_PAGE',
  CONTENT_FETCH_CHRONIK_ARTICLES = '[CONTENT] FETCH_CHRONIK_ARTICLES',
  CONTENT_FETCH_CHRONIK_ARTICLES_SUCCESS = '[CONTENT] FETCH_CHRONIK_ARTICLES_SUCCESS',
  CONTENT_FETCH_CHRONIK_ARTICLES_ERROR = '[CONTENT] FETCH_CHRONIK_ARTICLES_ERROR',
}

export const setLanguage = createAction(
  CONTENT_ACTIONS.CONTENT_SET_LANGUAGE,
  props<{
    language: LanguageCode;
  }>()
);

// fetch content
export const fetchContent = createAction(
  CONTENT_ACTIONS.CONTENT_FETCH_CONTENT,
  props<{ config: ClientConfig }>()
);
export const fetchContentSuccess = createAction(
  CONTENT_ACTIONS.CONTENT_FETCH_CONTENT_SUCCESS,
  props<{ payload }>()
);
export const fetchContentError = createAction(
  CONTENT_ACTIONS.CONTENT_FETCH_CONTENT_ERROR,
  props<{ payload }>()
);

export const setStationStatus = createAction(
  CONTENT_ACTIONS.CONTENT_SET_STATION_STATUS,
  props<{
    status: StationStatus;
  }>()
);

export const fetchQuizQuestions = createAction(
  CONTENT_ACTIONS.CONTENT_FETCH_QUIZ_QUESTIONS
);

export const fetchQuizQuestionsSuccess = createAction(
  CONTENT_ACTIONS.CONTENT_FETCH_QUIZ_QUESTIONS_SUCCESS,
  props<{ payload }>()
);

export const fetchQuizQuestionsError = createAction(
  CONTENT_ACTIONS.CONTENT_FETCH_QUIZ_QUESTIONS_ERROR,
  props<{ payload }>()
);

export const fetchDreamElevenPersons = createAction(
  CONTENT_ACTIONS.CONTENT_FETCH_DREAM_ELEVEN_PERSONS
);

export const fetchDreamElevenPersonsSuccess = createAction(
  CONTENT_ACTIONS.CONTENT_FETCH_DREAM_ELEVEN_PERSONS_SUCCESS,
  props<{ player: Array<any>; trainer: Array<any> }>()
);

export const fetchDreamElevenPersonsError = createAction(
  CONTENT_ACTIONS.CONTENT_FETCH_DREAM_ELEVEN_PERSONS_ERROR,
  props<{ payload }>()
);

export const fetchGuestbookPages = createAction(
  CONTENT_ACTIONS.CONTENT_FETCH_GUESTBOOK_PAGES
);

export const fetchGuestbookPagesSuccess = createAction(
  CONTENT_ACTIONS.CONTENT_FETCH_GUESTBOOK_PAGES_SUCCESS,
  props<{ pages: Array<DoublePage>; pageCount: number }>()
);

export const fetchGuestbookPagesError = createAction(
  CONTENT_ACTIONS.CONTENT_FETCH_GUESTBOOK_PAGES_ERROR,
  props<{ payload }>()
);

export const addGuestbookPage = createAction(
  CONTENT_ACTIONS.CONTENT_ADD_GUESTBOOK_PAGE,
  props<{ payload }>()
);

export const setGuestbookPage = createAction(
  CONTENT_ACTIONS.CONTENT_SET_GUESTBOOK_PAGE,
  props<{ payload: number }>()
);

export const setDynamicStationContent = createAction(
  CONTENT_ACTIONS.CONTENT_SET_DYNAMIC_STATION_CONTENT,
  props<{
    dynamicStationContent: any;
  }>()
);

// header status
export const setHeaderStatus = createAction(
  CONTENT_ACTIONS.CONTENT_SET_HEADER_STATUS,
  props<{
    headerStatus: HeaderStatus;
  }>()
);

export const toggleHeaderStatus = createAction(
  CONTENT_ACTIONS.CONTENT_TOGGLE_HEADER_STATUS
);
