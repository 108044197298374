<app-translation-switch></app-translation-switch>

<!-- INTRODUCTION STATE  -->
<ng-container
  *ngIf="
    content.stationStatus === 'main' && status === guestbookStates.INTRODUCTION
  "
>
  <div class="foreground"></div>
  <div class="book-container">
    <div class="book-container__explaination">
      <div class="handwriting text">
        {{ "field_picture_and_write_text" | translate }}
      </div>
      <div class="handwriting text">
        {{ "field_scroll_through_posts_text" | translate }}
      </div>
    </div>
    <div class="book-container__button-container">
      <div
        class="button button__create button__handwriting"
        (click)="ToPrePictureState()"
      >
        {{ "field_create_post_text" | translate }}
      </div>
      <div
        class="button button__scroll button__handwriting"
        (click)="ToScrollState()"
      >
        {{ "field_scroll_through_text" | translate }}
      </div>
    </div>
  </div>
</ng-container>

<div id="preloader">
  <img
    *ngFor="let image of loadedPages"
    [src]="image.pageOne.url"
    width="1"
    height="1"
  />
  <img
    *ngFor="let image of loadedPages"
    [src]="image.pageTwo.url"
    width="1"
    height="1"
  />
</div>

<!-- BOOK SCROLLING STATE  -->
<ng-container
  *ngIf="
    content.stationStatus === 'main' &&
    status === guestbookStates.BOOK_SCROLLING &&
    currentPage
  "
>
  <div class="book-wrapper">
    <div class="book" id="book">
      <div
        *ngIf="prevPage"
        class="page page__a"
        id="a"
        [style.background]="'url(' + prevPage.pageOne.url + ')'"
      ></div>
      <div
        *ngIf="prevPage"
        class="page page__b"
        id="b"
        [style.background]="'url(' + prevPage.pageTwo.url + ')'"
      ></div>
      <div
        class="page page__c"
        id="c"
        *ngIf="currentPage.pageOne.url !== undefined"
        [style.background]="'url(' + currentPage.pageOne.url + ')'"
        (pan)="onSwipe($event)"
      ></div>
      <div
        class="page page__c"
        id="c"
        *ngIf="currentPage.pageOne.url === undefined"
        [style.backgroundColor]="white"
        (pan)="onSwipe($event)"
      ></div>
      <div
        class="page page__d"
        id="d"
        *ngIf="currentPage.pageTwo.url !== undefined"
        [style.background]="'url(' + currentPage.pageTwo.url + ')'"
        (pan)="onSwipe($event)"
      ></div>
      <div
        class="page page__d"
        *ngIf="currentPage.pageTwo.url === undefined"
        id="d"
        [style.backgroundColor]="white"
      ></div>
      <div
        class="page page__e"
        id="e"
        *ngIf="nextPage !== undefined && nextPage.pageOne.url"
        [style.background]="'url(' + nextPage.pageOne.url + ')'"
      ></div>
      <div
        class="page page__e"
        id="e"
        *ngIf="nextPage === undefined"
        [style.backgroundColor]="white"
      ></div>
      <div
        class="page page__f"
        id="f"
        *ngIf="nextPage !== undefined && nextPage.pageTwo.url"
        [style.background]="'url(' + nextPage.pageTwo.url + ')'"
      ></div>
      <div
        class="page page__f"
        *ngIf="nextPage === undefined"
        id="f"
        [style.backgroundColor]="white"
      ></div>
    </div>
  </div>

  <div class="btn-container">
    <div
      class="button button__prev button__handwriting"
      (click)="ChangePage(-1)"
      [ngClass]="{
        disabled: !prevPage || prevPage.index === currentPage.index
      }"
    >
      {{ "field_previous_post_text" | translate }}
    </div>

    <div
      class="button button__icon-left button__create button__handwriting"
      (click)="ToPrePictureState()"
    >
      {{ "field_create_post_text" | translate }}
    </div>

    <div
      class="button button__next button__handwriting"
      (click)="ChangePage(1)"
      [ngClass]="{
        disabled: nextPage ? nextPage.index === currentPage.index : true
      }"
    >
      {{ "field_next_post_text" | translate }}
    </div>
  </div>

  <div class="foreground"></div>
</ng-container>

<!-- PRE PICTURE STATE  -->
<ng-container
  *ngIf="
    content.stationStatus === 'main' && status === guestbookStates.PRE_PICTURE
  "
>
  <div class="foreground"></div>
  <div class="book-container">
    <div class="book-container__explaination">
      <div class="book-container__img"></div>
      <div class="handwriting text">
        {{ "field_write_in_guestbook_text" | translate }}
      </div>
    </div>
    <div class="book-container__button-container">
      <div class="button button__handwriting" (click)="ToTakePictureState()">
        {{ "field_post_with_picture_text" | translate }}
      </div>
      <div class="button button__handwriting" (click)="ToDrawState()">
        {{ "field_post_without_picture_text" | translate }}
      </div>
    </div>
  </div>
</ng-container>

<!-- TAKE PICTURE STATE  -->
<div
  class="background-image"
  *ngIf="
    content.stationStatus === 'main' && status === guestbookStates.TAKE_PICTURE
  "
>
  <app-take-picture
    (showPicture)="OnShowPicture($event)"
    (stopCountdown)="OnStopCountdown()"
    station="GUESTBOOK"
  ></app-take-picture>
</div>

<!-- POST PICTURE STATE  -->
<ng-container
  *ngIf="
    content.stationStatus === 'main' && status === guestbookStates.POST_PICTURE
  "
>
  <div class="foreground"></div>
  <div class="book-container">
    <div class="book-container__explaination">
      <div class="handwriting text">
        {{ "field_text_or_picture_text" | translate }}
      </div>
    </div>
    <div class="book-container__button-container sixty">
      <img
        width="300"
        [src]="imageLink"
        alt="img"
        class="image-rotation-{{ imageRotationRandom }}"
      />
      <div class="book-container__button-container__wrapper">
        <div
          class="button button__again button__handwriting"
          (click)="ToTakePictureState()"
        >
          {{ "field_redo_text" | translate }}
        </div>
        <div
          class="button button__create button__handwriting"
          (click)="ToDrawState()"
        >
          {{ "field_add_greeting_text" | translate }}
        </div>
      </div>
    </div>
  </div>
</ng-container>

<!-- DRAWING STATE  -->
<ng-container
  *ngIf="content.stationStatus === 'main' && status === guestbookStates.DRAWING"
>
  <div class="foreground"></div>
  <div
    class="button button__icon-left button__save button__handwriting"
    (click)="SaveToCanvas()"
  >
    Speichern
  </div>
  <app-guestbook-entry-creator
    [onError]="onError.asObservable()"
    [onSave]="onSave.asObservable()"
    [image]="imageLink"
    [imageRotationRandom]="imageRotationRandom"
    (canvasSaved)="OnCanvasSaved($event)"
  >
  </app-guestbook-entry-creator>
</ng-container>

<!-- POST DRAWING STATE  -->
<ng-container
  *ngIf="
    content.stationStatus === 'main' && status === guestbookStates.POST_DRAWING
  "
>
  <div
    class="page page__d"
    [style.background]="'url(' + savedCanvasImg + ')'"
  ></div>
  <div class="foreground"></div>
  <div class="book-container">
    <div class="book-container__explaination">
      <div class="handwriting text">
        {{ "field_thank_you_text" | translate }} <br />
        {{ "field_post_will_be_saved_text" | translate }}
      </div>
    </div>
  </div>
</ng-container>

<canvas class="hidden" #canvasWithTape></canvas>
<img class="hidden" [src]="imageLink" id="image" />
<img
  class="hidden"
  height="140"
  width="140"
  style="height: 140px; width: 140px"
  src="../../../assets/img/tape.svg"
  id="tapeImage"
/>
