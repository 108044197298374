export enum HeaderStatus {
  STANDBY = 'STANDBY',
  IN_USE = 'IN_USE',
  HIDDEN = 'HIDDEN',
}

export enum HeaderVariation {
  DEFAULT = 'DEFAULT',
  LARGE = 'LARGE',
  XLARGE = 'XLARGE',
}
