<app-input-validation-error
  [errorMsgs]="errorMsgs"
></app-input-validation-error>
<ng-container *ngIf="label">
  <label class="input-number-label"
    >{{ label | translate }}
    <span *ngIf="!required">{{ "UI.OPTIONAL" | translate }}</span></label
  >
</ng-container>

<div [class]="'breakpoint-' + breakpoint" [ngClass]="{ 'with-toggle': toggle }">
  <div (click)="Minus()" *ngIf="toggle" class="button minus">-</div>

  <!-- Default -->
  <input
    #ngModel="ngModel"
    (blur)="Validate()"
    (focus)="ResetErrors()"
    [attr.disabled]="disabled ? true : null"
    class="input-number"
    id="{{ id }}"
    name="{{ name }}"
    ngClass="{{
      (form.submitted && ngModel.invalid) ||
      (ngModel.touched && ngModel.invalid)
        ? 'has-error'
        : ''
    }}"
    ngModel="{{ initValue }}"
    ngModelGroup="{{ ngGroupName }}"
    pattern="{{ pattern }}"
    placeholder="{{
      (placeholder | translate) || ('UI.NUMBER_INPUT.STANDBY' | translate)
    }}"
    required="{{ required || false }}"
    type="number"
  />

  <div (click)="Plus()" *ngIf="toggle" class="button plus">+</div>
</div>
